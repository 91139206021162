import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Text,
  Button,
  VStack,
} from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { Step } from './step'
import { trackEvent } from '@lib/clinician-tracking'

export const RecordingReadiness = () => {
  // TODO: uncomment this once its part of the flow 
  // This needs to be commented out otherwise can't be viewed, when not part of flow
  // const history = useHistory() 
  // const { isAuthenticated } = useStoreState((state: any) => state.auth)

  // if (!isAuthenticated) {
  //   history.push('/')
  // }

  useEffect(() => {
    trackEvent('Onboarding Get Started Viewed', {
      version: 2
    })
  }, [])

  return (
    <Step mt={{ base: '80px', md: 0 }} pb={4} maxW="370px" data-testid="get-started-step">
      <VStack
        spacing={{ base: 'auto', md: 4 }}
        align="stretch"
        flex="1"
        justifyContent="center"
        data-testid="get-started-stack"
      >
        <Box textAlign="left">
          <Text fontSize="32px" fontWeight="bold" mb={{ base: 12, md: 8 }} style={{ lineHeight: '44px' }}>
            Are you ready to<br />
            record a real session<br />
            <Text as="span" color="primary" fontSize="32px" fontWeight="bold">
              in the next few<br />
              minutes?
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="normal" mb={12}>
            If <b>yes</b>, we can get you ‘record ready’ in 2 minutes.<br />
            If <b>no</b>, let's explore Blueprint with a test session!
          </Text>
          <Box pt={4} pb={8}>
            <Button isFullWidth m={0} mb={2} type="submit" size="lg">
              Yes, I have a client soon
            </Button>
            <Button isFullWidth m={0} variant="outline" size="lg">
              No, show me a demo
            </Button>
          </Box>
        </Box>
      </VStack>
    </Step>
  )
}