import { action, thunk, actionOn } from 'easy-peasy'

import { endpoints } from '../../api/index'
import Auth from '../../auth'
import { queryClient } from '../../index'
import {
  trackEvent,
  identify,
  resetIdentity
} from '../../lib/clinician-tracking'
import * as storage from '@storage'
import { getCookie } from '@utilities'

export const auth = new Auth()

export const authModel = {
  auth: {
    isAuthenticated: false,
    user: null,
    isSSOUser: false,
    accessToken: null,
    refreshToken: null,
    isRefreshingSSO: false,
    setIsRefreshingSSO: action((state, boolean) => {
      state.isRefreshingSSO = boolean
    }),
    updateUser: action((state, payload) => {
      if (!state.user) {
        state.user = {
          ...payload
        }
      } else {
        state.user = { ...state.user, ...payload }
      }
    }),
    setSession: action((state, session) => {
      state.accessToken = session.accessToken.jwtToken
      state.refreshToken = session.refreshToken.token
      state.isAuthenticated = true
    }),
    clearAuth: action(state => {
      const REACT_APP_DOMAIN = window.location.hostname.includes('blueprint.ai')
        ? process.env.REACT_APP_DOMAIN
        : process.env.REACT_APP_LEGACY_DOMAIN
      state.isAuthenticated = false
      state.user = null
      state.accessToken = null
      state.refreshToken = null
      state.isSSOUser = false
      document.cookie = `ssoAccessToken=; Max-Age=0; path=/; domain=${REACT_APP_DOMAIN}; Secure; SameSite=None`
      document.cookie = `ssoRefreshToken=; Max-Age=0; path=/; domain=${REACT_APP_DOMAIN}; Secure; SameSite=None`
    }),
    setSSOUser: action((state, payload) => {
      const REACT_APP_DOMAIN = window.location.hostname.includes('blueprint.ai')
        ? process.env.REACT_APP_DOMAIN
        : process.env.REACT_APP_LEGACY_DOMAIN
      const { accessToken, refreshToken } = payload
      state.isSSOUser = true
      state.isAuthenticated = true
      state.accessToken = accessToken
      state.refreshToken = refreshToken
      document.cookie = `ssoAccessToken=${accessToken}; path=/; domain=${REACT_APP_DOMAIN}; Secure; SameSite=None`
      document.cookie = `ssoRefreshToken=${refreshToken}; path=/; domain=${REACT_APP_DOMAIN}; Secure; SameSite=None`
    }),
    logout: thunk(async (actions, payload, helpers) => {
      await auth.logout()
      actions.clearAuth()
      // Clear session cache
      queryClient.clear()
      helpers.getStoreActions().reset()
      if (window.Intercom) {
        await window.Intercom('shutdown')
        await window.Intercom('boot', {
          app_id: process.env.REACT_APP_INTERCOM_KEY
        })
      }
      resetIdentity()
    }),
    resumeSession: thunk(async actions => {
      const ssoAccessToken = getCookie('ssoAccessToken')
      const ssoRefreshToken = getCookie('ssoRefreshToken')
      if (ssoAccessToken) {
        actions.setSSOUser({
          accessToken: ssoAccessToken,
          refreshToken: ssoRefreshToken
        })
      } else {
        const session = await auth.isAuthenticated()
        actions.setSession(session)
      }

      await endpoints.trackProviderLogin.request()
      await actions.fetchUserInfo()
      actions.trackLogin()
    }),
    login: thunk(async (actions, { email, password }) => {
      const session = await auth.login(email, password)
      actions.setSession(session)
      await endpoints.trackProviderLogin.request()
      // Pre-cache user account + validate account exists
      await actions.fetchUserInfo()
      actions.trackLogin()
    }),
    widgetLogin: thunk(async (actions, { authTokens, username }) => {
      console.log('BP_DEBUG widgetLogin')
      const session = await auth.widgetLogin(authTokens, username)
      console.log('BP_DEBUG session', session)
      actions.setSession(session)
      await endpoints.trackProviderLogin.request()
      // Pre-cache user account + validate account exists
      await actions.fetchUserInfo()
      actions.trackLogin()
    }),
    fetchUserInfo: thunk(async actions => {
      const result = await endpoints.getUserAccount.request()
      if (result) {
        actions.updateUser(result)
      }
    }),
    fetchSSOAuthURL: thunk(async (actions, { email, redirectUrl, state }) => {
      const url = await endpoints.getSSORedirectUrl.request({
        email,
        redirectUrl,
        state
      })
      return url
    }),
    validateSSOCode: thunk(async (actions, { code }) => {
      const {
        accessToken,
        refreshToken
      } = await endpoints.validateSSOCode.request({
        code
      })
      actions.setSSOUser({ accessToken, refreshToken })
      await endpoints.trackProviderLogin.request()
      await actions.fetchUserInfo()
      actions.trackLogin()
    }),
    refreshAccessToken: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState()
      const {
        accessToken,
        refreshToken
      } = await endpoints.refreshAccessToken.request({
        refreshToken: state.refreshToken
      })
      actions.setSSOUser({ accessToken, refreshToken })
    }),
    trackLogin: thunk(async (actions, payload, helpers) => {
      const state = helpers.getState()
      identify(state.user.id)
      trackEvent('Clinician Logged In')
      try {
        const hostname = window.location.hostname
        const domain = hostname.includes('.')
          ? '.' +
            hostname
              .split('.')
              .slice(-2)
              .join('.')
          : hostname
        const now = new Date()
        const futureDate = new Date(
          now.getTime() + 10 * 365 * 24 * 60 * 60 * 1000
        )
        const expires = futureDate.toUTCString()
        document.cookie = `hasLoggedIn=true; expires=${expires}; path=/; domain=${domain}`
      } catch (error) {
        console.warn('Error setting hasLoggedIn cookie', error)
      }
    })
  }
}
