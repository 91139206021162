export const SYMPTOM_ANSWERS = [
  {
    key: 'not_at_all',
    value: 'Not at all',
    count: 0
  },
  {
    key: 'rarely',
    value: 'Rarely',
    count: 1
  },
  {
    key: 'sometimes',
    value: 'Sometimes',
    count: 2
  },
  {
    key: 'often',
    value: 'Often',
    count: 3
  },
  {
    key: 'very_much',
    value: 'Very much',
    count: 4
  }
]

export const SYMPTOMS = [
  {
    key: 'weighed_down',
    value: 'Weighed down'
  },
  {
    key: 'increased_appetite',
    value: 'Increased appetite'
  },
  {
    key: 'decreased_appetite',
    value: 'Decreased appetite'
  },
  {
    key: 'interest',
    value: 'Loss of interest'
  },
  {
    key: 'concentrating',
    value: 'Difficulty concentrating'
  },
  {
    key: 'sleeping',
    value: 'Trouble sleeping'
  },
  {
    key: 'worthless',
    value: 'Worthless'
  },
  {
    key: 'sleeping_more',
    value: 'Sleeping more'
  },
  {
    key: 'restless',
    value: 'Restless'
  },
  {
    key: 'nervous',
    value: 'Nervous'
  },
  {
    key: 'productive',
    value: 'Productive'
  },
  {
    key: 'suicidal_ideation',
    value: 'Suicidal ideation'
  },
  {
    key: 'distressing_thoughts',
    value: 'Distressing thoughts'
  },
  {
    key: 'lack_of_purpose',
    value: 'Lack of purpose'
  },
  {
    key: 'avoidance',
    value: 'Avoidance of uncomfortable situations'
  },
  {
    key: 'low_mindfulness',
    value: 'Rushing through activities'
  },
  {
    key: 'low_self_esteem',
    value: 'Low self-esteem'
  },
  {
    key: 'inability_goals',
    value: 'Unable to achieve goals'
  },
  {
    key: 'relationship_distress',
    value: 'Problems with relationships'
  },
  {
    key: 'occupational_distress',
    value: 'Problems with work'
  },
  {
    key: 'lack_of_pleasurable_activities',
    value: 'Lack of pleasurable activities'
  }
]
// export const HEX_CODES = ['#2d54e8', '#54BFFF', '#56C59F', '#789920', '#EDB522', '#ED5C18', '#D57EE6', '#583652', '#6A6A6A', '#951800', '#E39D74', '#814A2A']
export const HEX_CODES = [
  '#2d54e8',
  '#2994ff',
  '#9017ba',
  '#03a574',
  '#e07800',
  '#e061d5',
  '#1f9b9e',
  '#dc185d',
  '#a4942d',
  '#666666',
  '#2d54e8',
  '#2994ff',
  '#9017ba',
  '#03a574',
  '#e07800',
  '#e061d5',
  '#1f9b9e',
  '#dc185d',
  '#a4942d',
  '#666666',
  '#2d54e8',
  '#2994ff',
  '#9017ba',
  '#03a574',
  '#e07800',
  '#e061d5',
  '#1f9b9e',
  '#dc185d',
  '#a4942d',
  '#666666'
]

export const PARTICIPANT_TYPE = Object.freeze({
  PARENT_GUARDIAN: {
    id: 'parent_guardian',
    displayName: 'Parent/Guardian'
  },
  TEACHER: {
    id: 'teacher',
    displayName: 'Teacher'
  },
  SPOUSE_PARTNER: {
    id: 'spouse_partner',
    displayName: 'Spouse/Partner'
  },
  OTHER: {
    id: 'other',
    displayName: 'Other'
  }
})

export const CLIENT_TYPE = Object.freeze({
  CHILD: 'child',
  ADULT: 'adult'
})

export const TREATMENT_APPROACHES = [
  {
    label: 'All treatment approaches',
    value: ''
  },
  {
    label: 'ACT',
    value: 'ACT'
  },
  {
    label: 'CBT',
    value: 'CBT'
  },
  {
    label: 'CPT',
    value: 'CPT'
  },
  {
    label: 'DBT',
    value: 'DBT'
  },
  {
    label: 'EFT',
    value: 'EFT'
  },
  {
    label: 'EMDR',
    value: 'EMDR'
  },
  {
    label: 'ERP',
    value: 'ERP'
  },
  {
    label: 'General',
    value: 'General'
  },
  {
    label: 'IFS',
    value: 'IFS'
  },
  {
    label: 'MATCH-ADTC',
    value: 'MATCH-ADTC'
  },
  {
    label: 'MBCT',
    value: 'MBCT'
  },
  {
    label: 'MI',
    value: 'MI'
  },
]
