import React from 'react'

const Consent = ({ width = '16', height = '16' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 36 36"
  >
    <g fill="none" fillRule="evenodd">
      <path d="m0 0h36v36h-36z" />
      <g fillRule="nonzero" transform="translate(6 1.5)">
        <path d="m4.875 16.875h14.25v2.625h-14.25z" fill="#fff" />
        <path d="m4.875 21.375h10.5v2.625h-10.5z" fill="#fff" />
        <path
          d="m15.75 8.25 4.524375.855 3.725625-.855-8.25-8.25-1.138125 3.91125z"
          fill="#1967d2"
        />
        <path d="m4.875 12.375h14.25v2.625h-14.25z" fill="#fff" />
        <path
          d="m15.75 8.25v-8.25h-13.5c-1.243125 0-2.25 1.006875-2.25 2.25v28.5c0 1.243125 1.006875 2.25 2.25 2.25h19.5c1.243125 0 2.25-1.006875 2.25-2.25v-22.5zm-.75 15.375h-9.75v-1.875h9.75zm3.75-4.5h-13.5v-1.875h13.5zm0-4.5h-13.5v-1.875h13.5z"
          fill="#4285f4"
        />
      </g>
    </g>
  </svg>
)

export default Consent
