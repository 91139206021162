import React, { useEffect, useState } from 'react'
import { PanelBody, OverlayPanelHeader } from '@handlers/sessions/components'
import Panel from '../panel'
import AssessmentLibrary from '../../shared/assist/assessments/library'
import WorksheetLibrary from '../../shared/assist/worksheets/library'
import { InterventionOverview } from '../../shared/assist/interventions/overview'
import { InterventionLibrary } from '../../shared/assist/interventions/library'
import { InterventionActivity } from '../../shared/assist/interventions/activity'
import EnrollClient from '../assessments/enroll'
import AssignAssessments from '../assessments/assign'
import { AssignWorksheets } from '../worksheets/assign'
import AssessmentResults from '../assessments/results'
import { useAssistPanelContext } from '../context'
import { WorksheetResult } from '../worksheets/worksheet-result'
import { CheckInDtoV2, CheckInScoreDtoV1 } from '~/clinician-api/models'
import { AllWorksheetResults } from '../worksheets/all-worksheet-results'

interface Props {
  client: any
  data?: {
    assessmentId?: string
    assessmentScoreId?: string
    checkIn?: CheckInDtoV2
    score?: CheckInScoreDtoV1
    interventionId?: string
    interventionName?: string
    patientInterventionId?: string
    isReadOnly?: boolean
    scoresWithCheckIn?: (CheckInScoreDtoV1 & {
      checkIn?: CheckInDtoV2
    })[]
    contentSuggestionId?: string | null
    onAssign: (assessment: any) => void
  }
}

const panelConfig: any = {
  LIBRARY: {
    title: (props: Props) => 'Assign Assessments',
    component: (props: Props) => (
      <AssessmentLibrary
        client={props.client}
        onAssign={props.data?.onAssign!}
      />
    )
  },
  ENROLL_CLIENT: {
    title: (props: Props) => {
      return props.data?.checkIn ? 'Assign Worksheet' : 'Assign Assessment'
    },
    component: () => <EnrollClient />
  },
  ASSIGN_ASSESSMENTS: {
    title: (props: Props) => 'Assign Assessment',
    component: () => <AssignAssessments />
  },
  ASSIGN_WORKSHEETS: {
    title: (props: Props) => 'Assign Worksheet',
    component: () => <AssignWorksheets />
  },
  ASSESSMENT_RESULTS: {
    title: (props: Props) => 'Assessment Results',
    component: (props: Props) => (
      <AssessmentResults
        client={props.client}
        preselectedAssessmentId={props.data?.assessmentId}
        preselectedAssessmentScoreId={props.data?.assessmentScoreId}
      />
    )
  },
  WORKSHEET_RESULT: {
    title: (props: Props) => 'Worksheet Result',
    component: (props: Props) => (
      <WorksheetResult
        checkIn={props.data?.checkIn!}
        score={props.data?.score!}
      />
    )
  },
  ALL_WORKSHEET_RESULTS: {
    title: (props: Props) => 'All Completed Worksheets',
    component: (props: Props) => <AllWorksheetResults />
  },
  WORKSHEET_LIBRARY: {
    title: (props: Props) => 'Worksheet Library',
    component: (props: Props) => (
      <WorksheetLibrary
        client={props.client}
        onAssign={props.data?.onAssign!}
      />
    )
  },
  INTERVENTION_OVERVIEW: {
    title: (props: Props) => props.data?.interventionName,
    component: (props: Props) => (
      <InterventionOverview 
        interventionId={props.data?.interventionId || ''} 
        contentSuggestionId={props.data?.contentSuggestionId}
      />
    )
  },
  INTERVENTION_LIBRARY: {
    title: (props: Props) => 'Intervention Library',
    component: (props: Props) => <InterventionLibrary />
  },
  INTERVENTION_ACTIVITY: {
    title: (props: Props) => props.data?.interventionName,
    component: (props: Props) => (
      <InterventionActivity
        isReadOnly={props.data?.isReadOnly ?? true}
        interventionId={props.data?.interventionId || ''}
        patientInterventionId={props.data?.patientInterventionId || ''}
        contentSuggestionId={props.data?.contentSuggestionId}
      />
    )
  }
}

export type PanelKey = keyof typeof panelConfig

interface PanelSwitcherProps {
  client: any
}

interface PanelHistoryItem {
  panel: PanelKey
  data?: Props['data']
}

const PanelSwitcher: React.FC<PanelSwitcherProps> = ({ client }) => {
  const { panel, data, setOverlayPanel, saveInterventionLibraryData } = useAssistPanelContext()
  const [panelHistory, setPanelHistory] = useState<PanelHistoryItem[]>([])

  useEffect(() => {
    if (
      panelHistory.length === 0 ||
      panelHistory[panelHistory.length - 1].panel !== panel
    ) {
      // @ts-ignore
      setPanelHistory(prevHistory => [...prevHistory, { panel, data }])
    }
  }, [panel, data])

  const handleBack = () => {
    setPanelHistory((prevHistory: any) => {
      const newHistory = [...prevHistory]
      newHistory.pop() // remove the visible panel from the history
      const previousPanelItem = newHistory[newHistory.length - 1] // get the previous panel

      if (previousPanelItem) {
        setOverlayPanel(previousPanelItem.panel, previousPanelItem.data)
      } else {
        saveInterventionLibraryData({
          scrollTop: 0,
          search: '',
          approach: {
            label: 'All treatment approaches',
            value: ''
          },
        })
        setOverlayPanel(null)
      }

      return newHistory
    })
  }

  if (!panel || !(panel in panelConfig)) return null

  const config = panelConfig[panel]

  return (
    <Panel>
      <OverlayPanelHeader
        title={config.title({ client, data })}
        onBack={handleBack}
      />
      <PanelBody
        px="0"
        height={{ base: 'auto', sm: 'auto', md: 'auto' }}
        overflowY={{ base: 'auto', sm: 'auto', md: 'hidden' }}
      >
        {config.component({ client, data })}
      </PanelBody>
    </Panel>
  )
}

export default PanelSwitcher
