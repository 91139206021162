import React from 'react'
import { DialogBody, DialogContainer, DialogHeader } from '../../../dialog'
import { Button, Text, VStack } from '@chakra-ui/react'

const RegenerationConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  docType,
  isError
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading: boolean
  docType: 'treatment_plan' | 'discharge_summary'
  isError: boolean
}) => {

  return (
    <DialogContainer onClose={onClose} isOpen={isOpen}>
      <DialogHeader text="Are you sure?" onClose={onClose} />
      <DialogBody>
        <VStack spacing={6}>
          {isError && (
            <Text color="red">
              {`An error occurred while regenerating the ${docType === 'treatment_plan' ? 'treatment plan' : 'discharge summary'}. Please
              try again.`}
            </Text>
          )}
          <Text textAlign="center">
            {`Are you sure you want to regenerate this ${docType === 'treatment_plan' ? 'Treatment Plan' : 'Discharge Summary'}? This will
            overwrite the current one.`}
          </Text>
          <VStack w="100%">
            <Button
              bg="primary"
              isFullWidth
              size="lg"
              onClick={onConfirm}
              isLoading={isLoading}
            >
              {`Regenerate ${docType === 'treatment_plan' ? 'Treatment Plan' : 'Discharge Summary'}`}
            </Button>
            <Button
              _focus={{ outline: 'none' }}
              variant="outline"
              isFullWidth
              size="lg"
              onClick={onClose}
              isDisabled={isLoading}
            >
              Cancel
            </Button>
          </VStack>
        </VStack>
      </DialogBody>
    </DialogContainer>
  )
}

export default RegenerationConfirmationDialog