import React from 'react'
import { BoxProps, Flex } from '@chakra-ui/react'

export const Step = ({
  children,
  onNext,
  progress,
  ...boxProps
}: {
  children: React.ReactNode
  onNext?: () => void
  progress?: number | undefined
} & BoxProps) => {
  return (
    <Flex
      maxW={{ base: '100%', md: 'md' }}
      flexDirection="column"
      px={{ base: 6, md: 0 }}
      py={{ base: 0, md: 4 }}
      flex="1"
      justifyContent={{ base: 'space-between', md: 'center' }}
      position="relative"
      {...boxProps}
    >
      {children}
    </Flex>
  )
}
