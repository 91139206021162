import React, { useState, useEffect } from 'react'
import { Stack, HStack, Text, Skeleton } from '@chakra-ui/react'
import { useQuery } from 'react-query'
import { endpoints } from '@api'
import { useRouteMatch } from 'react-router-dom'
import { useTreatmentPlan, useExperienceManager } from '@hooks'
import {
  useSessionControllerGetSuggestedContent,
  useSessionControllerGetDemoSuggestedContent
} from '~/clinician-api'
import ContentSuggestionCard from '@handlers/sessions/components/shared/assist/assessments/suggestion-card'
import { Pagination } from '@handlers/sessions/components/shared/pagination'

import { useAssistPanelContext } from '../context'
import { buildCheckInPreviewUrl } from '@utilities'

export const Suggestions = () => {
  const [showDemoLoading, setShowDemoLoading] = useState(true)
  const { client } = useTreatmentPlan()
  let match: any = useRouteMatch('/patient/:patientId/session/:sessionId')
  const [suggestedContentIndex, setSuggestedContentIndex] = useState(0)

  const { isDemoClinician } = useExperienceManager()

  const { setOverlayPanel } = useAssistPanelContext()

  const lastSessionQuery: any = useQuery(
    [endpoints.getClinicianClientLastSession.getCacheId(), client?.id],
    async () => {
      return await endpoints.getClinicianClientLastSession.request({
        id: client?.id
      })
    },
    {
      enabled: !!client?.id
    }
  )

  const sessionId = lastSessionQuery?.data?.id
  const showDemoExperience = client?.is_demo && isDemoClinician

  useEffect(() => {
    if (showDemoExperience) {
      setTimeout(() => {
        setShowDemoLoading(false)
      }, 1000)
    } else {
      setShowDemoLoading(false)
    }
  }, [showDemoExperience])

  const suggestionsQuery = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      },
      enabled: !!sessionId && !showDemoExperience
    }
  })

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId || match.params.sessionId,
    { demoContext: 'in-session' },
    {
      query: {
        enabled: showDemoExperience
      }
    }
  )

  const suggestedWorksheets = showDemoExperience
  ? demoData?.suggestedWorksheets?.filter(worksheet => !worksheet.isActive)
    : suggestionsQuery?.data?.suggestedWorksheets?.filter(worksheet => !worksheet.isActive)

  const suggestion = suggestedWorksheets?.[suggestedContentIndex]

  if (suggestionsQuery.isLoading || showDemoLoading) {
    return (
      <Stack>
        <HStack justifyContent="space-between">
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" width="100px" />
        </HStack>
        <Skeleton height="113px" borderRadius="8px" />
      </Stack>
    )
  }

  if (!suggestion) {
    return (
      <Stack spacing="0" gap="8px">
        <HStack justifyContent="space-between">
          <Text fontWeight="bold">Suggestions</Text>
        </HStack>
        <HStack
          spacing="0"
          p="16px"
          gap="12px"
          border="1px solid"
          borderColor="pale_gray"
          borderRadius="8px"
        >
          <Text>No suggestions available.</Text>
        </HStack>
      </Stack>
    )
  }

  const count = suggestedWorksheets?.length || 0
  const onPrevious = () => setSuggestedContentIndex(prev => prev - 1)
  const onNext = () => setSuggestedContentIndex(prev => prev + 1)

  const handleAssign = () => {
    if (client?.enrollment_timestamp) {
      if (suggestion) {
        setOverlayPanel('ASSIGN_WORKSHEETS', {
          worksheet: {
            checkInId: suggestion.checkInId,
            suggestionId: suggestion.contentSuggestionId
          },
          sessionId
        })
      }
    } else {
      setOverlayPanel('ENROLL_CLIENT', {
        checkIn: {
          checkInId: suggestion.checkInId,
          suggestionId: suggestion.contentSuggestionId
        },
        sessionId
      })
    }
  }

  return (
    <Stack spacing="0" gap="8px">
      <HStack justifyContent="space-between">
        <Text fontWeight="bold">Suggestions</Text>
        <Pagination
          current={suggestedContentIndex + 1}
          total={count}
          onNext={onNext}
          onPrev={onPrevious}
        />
      </HStack>
      <ContentSuggestionCard
        isDemo={showDemoExperience}
        type="worksheet"
        suggestion={{ ...suggestion, name: suggestion.worksheetName }}
        sessionId={sessionId}
        onAssign={handleAssign}
        previewUrl={buildCheckInPreviewUrl(suggestion.checkInId)}
      />
    </Stack>
  )
}
