import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { endpoints } from '@api'
import { useQuery } from 'react-query'
import {
  VStack,
  HStack,
  Box,
  Text,
  Heading,
  Link,
  Select,
  GridItem,
  Container
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import { Button, TextField, LayoutGrid } from '@blueprinthq/joy'
import * as Yup from 'yup'
import { useQueryParams } from '../../hooks/use-query-params'
import { useStoreActions } from 'easy-peasy'
import { DEFAULT_PASSWORD_SCHEMA } from '../../components/passwordrules'
import { PasswordRules } from '../../components'

import { usePartnerOnboardingControllerCreatePartnerClinician } from '~/public-api'

import config from '../../config'

const AccountSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .trim(),
  lastName: Yup.string()
    .required('Required')
    .trim(),
  licenseLevel: Yup.object()
    .shape({
      id: Yup.string().required('License level is required'),
      display: Yup.string().required('License level is required')
    })
    .required('License level is required')
    .nullable(),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: DEFAULT_PASSWORD_SCHEMA
})

interface FormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  licenseLevel: { id: string } | null
}

export const PartnerSignup = () => {
  const [email, setEmail] = useState<string | null>()
  const [password, setPassword] = useState<string | null>()
  const history = useHistory()

  const queryParams = useQueryParams()
  const externalOrgId = queryParams.get('externalOrgId')
  const organizationId = queryParams.get('organizationId')
  const authLogin = useStoreActions((actions: any) => actions.auth.login)

  const { data: licenseLevels }: { data: any } = useQuery(
    [endpoints.getLicenseLevels.getCacheId()],
    endpoints.getLicenseLevels.request,
    {
      initialData: []
    }
  )

  const {
    mutate: createClinician,
    isError
  } = usePartnerOnboardingControllerCreatePartnerClinician({
    mutation: {
      onSuccess: async () => {
        await authLogin({ email, password })
        history.push('/sessions?signup=true')
      }
    }
  })

  if (!organizationId || !externalOrgId) return null

  return (
    <Container
      marginTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%">
            <Formik<FormValues>
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                licenseLevel: null
              }}
              enableReinitialize
              validationSchema={AccountSchema}
              onSubmit={async (values, actions) => {
                setPassword(values.password)
                setEmail(values.email)
                const credentials = { password: values.password }
                const userInfo = {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  licenseLevel: values?.licenseLevel?.id,
                  email: values.email
                }
                await createClinician({
                  organizationId,
                  data: { ...credentials, ...userInfo, externalOrgId }
                })
                actions.setSubmitting(false)
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <VStack spacing="small">
                    <Box w="100%" pb="medium">
                      <Heading w="100%" mb="4px" size="lg">
                        Set your password
                      </Heading>
                      <Text>
                        Finish creating your account and get access to Blueprint
                      </Text>
                    </Box>
                    <HStack
                      w="100%"
                      gap={'small'}
                      style={{ marginTop: 0 }}
                      pb={
                        (errors.firstName && touched.firstName) ||
                        (errors.lastName && touched.lastName)
                          ? 'small'
                          : ''
                      }
                    >
                      <Box w="100%">
                        <Field name="firstName">
                          {({ field }: { field: any }) => (
                            <TextField
                              {...field}
                              label="First name"
                              isInvalid={errors.firstName && touched.firstName}
                              errorText={errors.firstName}
                            />
                          )}
                        </Field>
                      </Box>
                      <Box
                        style={{ marginInlineStart: 0, WebkitMarginStart: 0 }}
                        w="100%"
                      >
                        <Field name="lastName">
                          {({ field }: { field: any }) => (
                            <TextField
                              {...field}
                              label="Last name"
                              isInvalid={errors.lastName && touched.lastName}
                              errorText={errors.lastName}
                            />
                          )}
                        </Field>
                      </Box>
                    </HStack>
                    <Box
                      pb={errors.email && touched.email ? 'small' : ''}
                      w="100%"
                    >
                      <Field name="email">
                        {({ field }: { field: any }) => (
                          <TextField
                            {...field}
                            label="Email"
                            isInvalid={errors.email && touched.email}
                            errorText={errors.email}
                          />
                        )}
                      </Field>
                    </Box>
                    <Box
                      pb={errors.password && touched.password ? 'small' : ''}
                      w="100%"
                    >
                      <Field name="password" mt="5">
                        {({ field }: { field: any }) => (
                          <Box width="100%">
                            <TextField
                              {...field}
                              label="Set a password"
                              type="password"
                              autoFocus
                              isInvalid={errors.password && touched.password}
                              errorText={!field.value && errors.password}
                            />
                            <PasswordRules password={field.value} />
                          </Box>
                        )}
                      </Field>
                    </Box>
                    <Field name="licenseLevel">
                      {({ field, form }: { field: any; form: any }) => (
                        <Select
                          {...field}
                          placeholder="License type"
                          sx={{
                            // Apply styles to the select itself
                            color: field.value ? 'black' : 'gray.500' // Default color for options
                          }}
                          onChange={event => {
                            const selectedState = licenseLevels.find(
                              (state: any) => state.id === event.target.value
                            )
                            form.setFieldValue(
                              'licenseLevel',
                              selectedState || null
                            )
                          }}
                          value={field.value ? field.value.id : ''}
                          style={{
                            marginTop: 0,
                            border: '1px solid #C9C9C9',
                            borderRadius: '6px',
                            height: '48px'
                          }}
                        >
                          {licenseLevels &&
                            licenseLevels.map((state: any) => (
                              <option
                                key={state.id}
                                style={{ color: 'black' }}
                                value={state.id}
                              >
                                {state.display}
                              </option>
                            ))}
                        </Select>
                      )}
                    </Field>
                    {errors.licenseLevel && touched.licenseLevel && (
                      <Text
                        sx={{
                          textAlign: 'left',
                          width: '100%',
                          display: 'block',
                          paddingLeft: '16px',
                          fontSize: '12px'
                        }}
                        color="error"
                        style={{ marginTop: 0 }}
                      >
                        License level is required
                      </Text>
                    )}

                    <Box sx={{ width: '100%' }}>
                      {isError && (
                        <Box mb="xsmall">
                          <Text color="severe">
                            Oops, something went wrong. Please refresh the page
                            and try again.
                          </Text>
                        </Box>
                      )}
                      <Button
                        isLoading={isSubmitting}
                        size="lg"
                        type="submit"
                        margin={0}
                        isFullWidth
                        spinnerPlacement="start"
                      >
                        Set password and log in
                      </Button>
                    </Box>
                    <Text
                      sx={{ fontSize: 'md', textAlign: 'center', mt: 'small' }}
                    >
                      By using Blueprint you are agreeing to our{' '}
                      <Link
                        sx={{ color: 'primary' }}
                        href={config.privacyPolicyURL}
                        rel="noopener noreferrer"
                        isExternal
                      >
                        privacy policy,
                      </Link>{' '}
                      <Link
                        sx={{ color: 'primary' }}
                        href={config.termsAndConditionsURL}
                        rel="noopener noreferrer"
                        isExternal
                      >
                        terms
                      </Link>
                      {' and '}
                      <Link
                        sx={{ color: 'primary' }}
                        href={config.baaURL}
                        rel="noopener noreferrer"
                        isExternal
                      >
                        BAA.
                      </Link>
                    </Text>
                  </VStack>
                </Form>
              )}
            </Formik>
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
