import React from 'react'
import {
  Button,
  Link,
  ButtonProps,
  LinkProps,
  useBreakpointValue
} from '@chakra-ui/react'
import {
  SampleNote as SampleNoteIcon,
  Consent as ConsentIcon
} from '@components/icons'
import { useSampleSessions } from '@hooks'

export const ConsentButton = ({ ...buttonProps }: ButtonProps & LinkProps) => {
  const isFullWidth = useBreakpointValue({ base: false, md: true })
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' })

  return (
    <Button
      as={Link}
      isExternal
      rel="noopener noreferrer"
      href="https://docs.google.com/document/d/1YOpiLkjcj5c68Tf15jexv0ISiRLWGQOHCNzoOptA2Qc/edit"
      leftIcon={<ConsentIcon />}
      isFullWidth={isFullWidth}
      bg="rgba(45, 84, 232, 0.04)"
      border="2px solid"
      borderColor="rgb(246, 247, 254)"
      borderRadius="4px"
      data-click-event-name="Clicked Sample Session Link"
      color="primary"
      _hover={{ background: 'rgba(45, 84, 232, 0.1)', textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      justifyContent={{ base: 'center', md: 'flex-start' }}
      px={4}
      size={buttonSize}
      {...buttonProps}
    >
      View client consent form
    </Button>
  )
}

export const SampleNoteButton = ({
  label,
  identifier,
  ...buttonProps
}: {
  label: string
  identifier: string
} & ButtonProps) => {
  const { isLoading, createSampleSession } = useSampleSessions()

  return (
    <Button
      onClick={() => createSampleSession(identifier)}
      isLoading={isLoading}
      isFullWidth
      leftIcon={<SampleNoteIcon fill="black" />}
      m={0}
      size="lg"
      variant="outline"
      borderRadius="24px"
      bg="white"
      justifyContent={isLoading ? 'center' : 'flex-start'}
      _hover={{
        bg: 'rgba(45, 84, 232, 0.04)'
      }}
      {...buttonProps}
    >
      {label}
    </Button>
  )
}
