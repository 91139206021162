import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'

import { ArrowLeft, PoweredByBp } from '@components/icons'
import { useWidget } from '@hooks'
import { useHistory } from 'react-router-dom'

const V2 = ({ showBackButton }: { showBackButton: boolean }) => {
  const history = useHistory()
  const { settings } = useWidget()

  const [isDragging, setIsDragging] = useState(false)
  const [initialCursorPosition, setInitialCursorPosition] = useState({
    x: 0,
    y: 0
  })

  return (
    <Flex
      id="bp-copilot-header"
      justifyContent="space-between"
      alignItems="center"
      p={'16px 12px'}
      position="sticky"
      top="0"
      backgroundColor="white"
      borderBottom="1px solid #E4E5E6"
      zIndex="999"
      _hover={settings?.isDraggable ? { cursor: 'move' } : {}}
      onMouseDown={e => {
        setIsDragging(true)
        setInitialCursorPosition({ x: e.clientX, y: e.clientY })
      }}
      onMouseUp={() => setIsDragging(false)}
      onMouseMove={e => {
        if (isDragging) {
          // Calculate the distance the cursor has moved
          const differenceX = e.clientX - initialCursorPosition.x
          const differenceY = e.clientY - initialCursorPosition.y

          // eslint-disable-next-line
          parent.postMessage(
            {
              type: 'BP_DRAG',
              differenceX,
              differenceY
            },
            '*'
          )
        }
      }}
    >
      <Box
        w="60px"
        h="20px"
        visibility={showBackButton ? 'visible' : 'hidden'}
        onClick={() => {
          history.push('/widget/start-session')
        }}
        _hover={{ cursor: 'pointer' }}
      >
        <Flex>
          <ArrowLeft fill="black" width="20" />
          <Text fontSize="md">Back</Text>
        </Flex>
      </Box>
      <PoweredByBp />
      <Flex w="60px" justifyContent="flex-end" />
    </Flex>
  )
}

export const NavBar = ({
  showBackButton = false
}: {
  onSessionEnd?: () => void
  showBackButton?: boolean
}) => {
  return <V2 showBackButton={showBackButton} />
}
