import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Text,
  Button,
  Link,
  VStack
} from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'
import { TextField } from '@blueprinthq/joy'
import { Step } from './step'
import { trackEvent } from '@lib/clinician-tracking'

export const Email = ({
  onNext,
  isLoading
}: {
  onNext: () => void
  isLoading: boolean
}) => {
  useEffect(() => {
    trackEvent('Onboarding Email Viewed', {
      version: 2
    })
  }, [])
  return (
    <Step mt={{ base: '80px', md: 0 }} pb={4}>
      <VStack
        spacing={{ base: 'auto', md: 4 }}
        align="stretch"
        flex="1"
        justifyContent="center"
      >
        <Box textAlign="center">
          <Text fontSize="32px" fontWeight="bold" mb={{ base: 12, md: 0 }}>
            Get the AI Assistant <br />
            <Text as="span" color="primary" fontSize="32px" fontWeight="bold">
              for Therapists
            </Text>
          </Text>
        </Box>
        <Box flex={{ base: 1, md: 0 }}>
          <Field name="email">
            {({ field, meta }: FieldProps) => {
              const isInvalid = !!meta.error && !!meta.touched
              return (
                <Box mb={isInvalid ? 6 : 4}>
                  <TextField
                    {...field}
                    type="email"
                    w="100%"
                    label="Enter your work email to begin"
                    isInvalid={isInvalid}
                    errorText={meta.error}
                    bg="white"
                    autoFocus
                  />
                </Box>
              )
            }}
          </Field>
          <Button
            size="lg"
            isFullWidth
            mb={6}
            onClick={onNext}
            isLoading={isLoading}
            type="submit"
          >
            Continue with email
          </Button>
          <Text align="center" color="#757575" mb={{ base: '124px', md: 0 }}>
            Already have an account?{' '}
            <Link as={RouterLink} to="/" color="primary">
              Login
            </Link>
          </Text>
        </Box>
        <Box>
          <Text align="center" fontSize="10px">
            By signing up, you agree to our{' '}
            <Link
              href="https://www.blueprint.ai/privacy"
              color="primary"
              isExternal
            >
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link
              href="https://www.blueprint.ai/platform-services-agreement"
              color="primary"
              isExternal
            >
              Terms of Service.
            </Link>
          </Text>
        </Box>
      </VStack>
    </Step>
  )
}