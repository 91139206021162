import React, { useEffect, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import {
  ExperienceManagerProvider,
  ExtensionProvider,
  WidgetProvider
} from '@context'
import { usePermissions, useUserServices } from '../hooks'
import { api } from '../api'
import { auth } from '../auth'
import {
  ErrorBoundary,
  Loading,
  Footer,
  Navigation as OldNavBar
} from '../components'
import {
  ChoosePlanModalConnected,
  DocumentationAutomationMarketingModalConnected,
  ManageScribePlanModalConnected,
  DocAutomationSessionLimitReachedModalConnected,
  EbcMarketingModalConnected,
  EbcUpgradePlanModalConnected,
  CancelIndividualPlanModalConnected,
  ArchivePatientModalConnected
} from '@containers'
import { NavBar } from '../containers'
import Context from '../index'
import qs from 'query-string'
import { Box } from '@chakra-ui/react'

export function PrivateRoute({
  component: Component,
  requiredPermissions,
  permissionsRedirect = '/',
  hideNavBar = false,
  focusMode = false,
  overflowY,
  ...rest
}) {
  const [_time, setTime] = useState(Date.now())
  const [isIframe, setIsIframe] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const { iframe } = qs.parse(location.search)
  const { isAuthenticated, user } = useStoreState(state => state.auth)
  const resumeSession = useStoreActions(actions => actions.auth.resumeSession)
  const { hasPermission } = usePermissions()

  const { flagsmithFlagsLoaded } = useUserServices()

  useEffect(() => {
    if (!isAuthenticated && isIframe) {
      // for popup SSO, we need to keep checking if a token is set so we can refresh the page
      const interval = setInterval(async () => {
        setTime(Date.now())
        await resumeSession()
      }, 3000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [isAuthenticated, isIframe])

  useEffect(() => {
    if (!isAuthenticated) {
      if (iframe === '1') {
        setIsIframe(true)
      } else {
        const queryParams = qs.stringify({
          ...qs.parse(history.location.search),
          next: window.location.pathname
        })
        history.push(`/login?${queryParams}`)
      }
    }
  }, [isAuthenticated, iframe])

  useEffect(() => {
    if (isIframe) {
      window.open(
        `https://${window.location.hostname}/sso?popup=1`,
        '_blank',
        'popup,width=500,height=500'
      )
    }
  }, [isIframe])

  if (!isAuthenticated || !user) {
    return <Loading />
  }

  if (requiredPermissions) {
    const permsSatisfied = requiredPermissions.every(perm =>
      hasPermission(perm)
    )
    if (!permsSatisfied) return <Redirect to={permissionsRedirect} />
  }

  const userProp = {
    id: user.id,
    user_id: user.id,
    email: user.email,
    name: user ? user.first_name + ' ' + user.last_name : null,
    clinic_display_id: user.clinic.display_id,
    clinic_stripe_customer_id: user.clinic.stripe_customer_id
  }

  return (
    <Context.Consumer>
      {context => {
        return (
          <ErrorBoundary>
            <ExperienceManagerProvider>
              <ExtensionProvider>
                <WidgetProvider>
                  <Route
                    {...rest}
                    render={() => {
                      return (
                        <Box
                          overflowY={overflowY}
                          style={{ width: '100%', height: '100%' }}
                        >
                          {!hideNavBar &&
                            (process.env.REACT_APP_THEME === 'tempus' ? (
                              // TODO(maintenance): This Nav bar will never be rendered and should be removed
                              <OldNavBar
                                auth={auth}
                                api={api}
                                user_role={user.user_role}
                                context={context}
                                user={userProp}
                              />
                            ) : (
                              <NavBar focusMode={focusMode} />
                            ))}
                          <Component
                            user={userProp}
                            user_role={user.user_role}
                            auth={auth}
                            api={api}
                            context={context}
                            flagsmithFlagsLoaded={flagsmithFlagsLoaded}
                            {...rest}
                          />
                          <Footer />
                        </Box>
                      )
                    }}
                  />
                  <ArchivePatientModalConnected />
                  <DocAutomationSessionLimitReachedModalConnected />
                  <EbcUpgradePlanModalConnected />
                  <EbcMarketingModalConnected />
                  <DocumentationAutomationMarketingModalConnected />
                  <CancelIndividualPlanModalConnected />
                  <ChoosePlanModalConnected />
                  <ManageScribePlanModalConnected />
                </WidgetProvider>
              </ExtensionProvider>
            </ExperienceManagerProvider>
          </ErrorBoundary>
        )
      }}
    </Context.Consumer>
  )
}
