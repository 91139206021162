import React, { useEffect } from 'react'
import {
  Box,
  Text,
  Button
} from '@chakra-ui/react'
import { Field, FieldProps, useFormikContext } from 'formik'
import { TextField } from '@blueprinthq/joy'
import { Step } from './step'
import { FadeIn } from './animations'
import { RedirectToBeginning } from './redirect-to-beginning'
import { FormValues } from '../index'
import { trackEvent } from '@lib/clinician-tracking'

export const Name = ({ onNext }: { onNext: () => void }) => {
  useEffect(() => {
    trackEvent('Onboarding Name Viewed', {
      version: 2
    })
  }, [])

  const { setFieldError } = useFormikContext<FormValues>()

  return (
    <Step mt={{ base: '65px', md: 0 }}>
      <RedirectToBeginning />
      <FadeIn>
        <Box textAlign="left" mb="34px">
          <Text fontSize="24px" color="primary">
            Let's get started! <br />
            <Text fontSize="24px" as="span" color="black">
              What is your name?
            </Text>
          </Text>
        </Box>
        <Box flex={{ base: 1, md: 0 }} mb={{ base: 0, md: '65px' }}>
          <Field name="firstName">
            {({ field, meta }: FieldProps) => {
              const isInvalid = !!meta.error && !!meta.touched
              return (
                <Box mb={isInvalid ? 8 : 4}>
                  <TextField
                    {...field}
                    onChange={e => {
                      field.onChange(e)
                      setFieldError('firstName', '')
                    }}
                    w="100%"
                    isRequired
                    label="First name"
                    isInvalid={!!meta.error && !!meta.touched}
                    errorText={meta.error}
                    required
                    bg="white"
                    autoFocus
                  />
                </Box>
              )
            }}
          </Field>
          <Field name="lastName">
            {({ field, meta }: FieldProps) => {
              return (
                <TextField
                  {...field}
                  onChange={e => {
                    field.onChange(e)
                    setFieldError('lastName', '')
                  }}
                  w="100%"
                  isRequired
                  label="Last name"
                  isInvalid={!!meta.error && !!meta.touched}
                  errorText={meta.error}
                  required
                  bg="white"
                />
              )
            }}
          </Field>
        </Box>
      </FadeIn>
      <Box pt={4} pb={8}>
        <Button isFullWidth onClick={onNext} m={0} type="submit" size="lg">
          Continue
        </Button>
      </Box>
    </Step>
  )
}