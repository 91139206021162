import React, { useEffect, useState } from 'react'
import {
  Box,
  VStack,
  Flex,
  Text,
  CloseButton,
  TextProps
} from '@chakra-ui/react'
import { useExperienceManager, usePermissions } from '@hooks'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { StoreModel } from 'src/store/types'
import NumberOfCliniciansSurvey from './survey-questions/number-of-clinicians-survey'

import { useOnboardingControllerSubmitSurveyResponse } from '~/clinician-api'
import { OnboardingSurveyDto } from '~/clinician-api/models'
import PersonaSurvey from './survey-questions/persona-survey'
import { AnimatePresence, motion } from 'framer-motion'
import SurveyThankYou from './survey-questions/survey-thankyou'

// @ts-ignore
import Cookies from 'js-cookie'

const MotionContainer = motion(Box)

const WelcomeText = (textProps: TextProps) => {
  return (
    <Text {...textProps}>
      Blueprint is here to assist you in getting hours of{' '}
      <Text as="span" fontWeight="bold">
        time
      </Text>{' '}
      and{' '}
      <Text as="span" fontWeight="bold">
        focus
      </Text>{' '}
      back each week — so you can deliver exceptional care every session{' '}
      <Text as="span" fontStyle="italic">
        without
      </Text>{' '}
      spending nights & weekends on documentation
    </Text>
  )
}

const WelcomeBanner = () => {
  const WelcomeImage =
    'https://res.cloudinary.com/hellojoy/image/upload/v1738794046/onboarding/welcome_background.jpg'

  const user = useStoreState((state: StoreModel) => state.auth.user)

  // @ts-ignore
  const { fetchUserInfo } = useStoreActions(actions => actions.auth)

  const { hasPermission } = usePermissions()
  const { isDocumentationAutomationEnterprise } = useExperienceManager()

  const [surveyStep, setSurveyStep] = useState(() => {
    if (!user) return ''
    if (
      !hasPermission('*:clinic:clinics:*') ||
      isDocumentationAutomationEnterprise
    )
      return ''
    if (user.clinic.number_of_clinicians === null) return 'step_one'
    if (user.clinic.organization_type === null) return 'step_two'
    return ''
  })

  const localStorageDismissedKey = 'welcomeBannerDismissed'
  const localStorageDismissedAtKey = 'welcomeBannerDismissedAt'

  const [isVisible, setIsVisible] = useState(() => {
    const storedDismissed = localStorage.getItem(localStorageDismissedKey)
    const storedDismissedAt = localStorage.getItem(localStorageDismissedAtKey)

    // show the banner if not dismissed yet
    if (!storedDismissed) return true

    const dismissedTimestamp = storedDismissedAt
      ? parseInt(storedDismissedAt, 10)
      : null

    // show the benner if the user needs to fill out the survey
    // and either the banner was dismissed before we started capturing the dismissed timestamp
    // or it was dismissed more than 24 hours ago
    return (
      (dismissedTimestamp === null ||
        Date.now() - dismissedTimestamp >= 24 * 60 * 60 * 1000) &&
      surveyStep !== ''
    )
  })

  useEffect(() => {
    if (surveyStep !== 'step_three') return

    const timer = setTimeout(() => {
      setSurveyStep('')
    }, 3000) // 5 seconds

    return () => clearTimeout(timer) // Cleanup on unmount
  }, [surveyStep])

  const handleClose = () => {
    localStorage.setItem(localStorageDismissedKey, 'true')
    localStorage.setItem(localStorageDismissedAtKey, Date.now().toString())
    setIsVisible(false)
  }

  const {
    mutateAsync: submitSurveyResponse
  } = useOnboardingControllerSubmitSurveyResponse()

  const handleSurveyStepSubmit = (survey: OnboardingSurveyDto) => {
    submitSurveyResponse(
      {
        data: survey
      },
      {
        onSuccess: () => {
          fetchUserInfo()
        }
      }
    )
  }

  const surveyContext = {
    hubspotutk: Cookies.get('hubspotutk'),
    pageUri: window.location.href
  }

  if (!isVisible || !user) return null

  return (
    <Box position="relative">
      <CloseButton
        position="absolute"
        top="10px"
        right="10px"
        onClick={handleClose}
      />
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        bg="#F6F7FE"
        borderRadius="8px"
      >
        <Box flex="5" p={{ base: 6, md: 8 }}>
          <VStack spacing={4} align="stretch">
            <Text
              color="#2D54E8"
              fontSize={{ base: '16px', lg: '24px' }}
              fontWeight={{ base: 'bold', lg: 'normal' }}
            >
              Hi {user.first_name}! Welcome to Blueprint!
            </Text>
            <WelcomeText display={{ base: 'none', lg: 'block' }} />
            <AnimatePresence exitBeforeEnter>
              {surveyStep === '' && (
                <MotionContainer
                  key="welcome-text"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 0.3,
                    ease: [0.57, 0.1, 0, 1]
                  }}
                  display={{ base: 'block', lg: 'none' }}
                >
                  <WelcomeText />
                </MotionContainer>
              )}
              {surveyStep === 'step_one' && (
                <NumberOfCliniciansSurvey
                  key="step_one"
                  onSubmitted={(numberOfClinicians: number) => {
                    handleSurveyStepSubmit({
                      numberOfClinicians: numberOfClinicians,
                      ...surveyContext
                    })
                    setSurveyStep('step_two')
                  }}
                />
              )}
              {surveyStep === 'step_two' && (
                <PersonaSurvey
                  key="step_two"
                  onSubmitted={(persona: string) => {
                    handleSurveyStepSubmit({
                      persona: persona,
                      ...surveyContext
                    })
                    setSurveyStep('step_three')
                  }}
                />
              )}
              {surveyStep === 'step_three' && (
                <SurveyThankYou key="step_three" />
              )}
            </AnimatePresence>
          </VStack>
        </Box>
        <Box
          flex="3"
          bgImage={`url(${WelcomeImage})`}
          bgSize="cover"
          bgPosition="center"
          bgRepeat="no-repeat"
          pb={{ base: '200px', sm: '0' }}
          borderBottomRightRadius="8px"
          borderBottomLeftRadius={{ base: '8px', sm: '0' }}
        ></Box>
      </Flex>
    </Box>
  )
}

export default WelcomeBanner
