import React from 'react'
import {
  IconButton,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import SuggestionStrength from '@handlers/sessions/components/assist-panel/suggestion-strength'
import { ArrowLeftIcon } from '@blueprinthq/joy'
import {
  useSessionControllerPostContentSuggestions,
  getSessionControllerGetSuggestedContentQueryKey,
  useSessionControllerGetContentExclusionReasons
} from '~/clinician-api'
import { useQueryClient, useQuery } from 'react-query'
import { endpoints } from '@api'

const Overlay = ({ client, toggleAssistOverlay, account, session }: any) => {
  const sessionId = session.id
  const queryClient = useQueryClient()
  const toast = useToast()
  const suggestionStrengthHeight = useBreakpointValue({
    base: 'calc(100vh - 296px)',
    sm: 'calc(100vh - 296px)',
    md: 'calc(100vh - 236px)',
  })

  const {
    data: sessionTranscriptData,
    isLoading: sessionTranscriptLoading
  } = useQuery<any>(
    [endpoints.getSessionTranscript.getCacheId(), sessionId],
    () => endpoints.getSessionTranscript.request({ sessionId }) as Promise<any>
  )

  const {
    data: exclusionReasonsData
  } = useSessionControllerGetContentExclusionReasons<{
    exclusionReasons: string[]
  }>(sessionId)

  const exclusionReasons = exclusionReasonsData?.exclusionReasons || []

  const {
    mutate: postContentSuggestions,
    isLoading: isLoadingPostContentSuggestions
  } = useSessionControllerPostContentSuggestions({
    mutation: {
      onSuccess: () => {
        const suggestionListKey = getSessionControllerGetSuggestedContentQueryKey(
          session.id
        )
        queryClient.invalidateQueries({ queryKey: suggestionListKey })
        queryClient.setQueryData(suggestionListKey, (oldData: any) => {
          if (oldData) {
            return {
              ...oldData,
              isGenerationComplete: false
            }
          }
          return oldData
        })
        toggleAssistOverlay()
      },
      onError: (error: any) => {
        toast({
          title: 'Error',
          description: (
            <Text whiteSpace="pre-wrap">
              {error.error?.message || 'Failed to generate suggestions'}
            </Text>
          ),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  })

  return (
    <Stack spacing="0">
      <HStack spacing="0" py="9px" px="16px" h="59px">
        <IconButton
          _hover={{ bg: 'pale_gray' }}
          m="0"
          aria-label="arrow-left"
          variant="ghost"
          onClick={toggleAssistOverlay}
          icon={<ArrowLeftIcon />}
        />
        <Text textAlign="center" flex="1">
          Assist for {client.first_name}
        </Text>
        <Flex w="40px" />
      </HStack>
      <Divider />
      <Box h={suggestionStrengthHeight} overflowY="scroll">
        <SuggestionStrength account={account} session={session} />
      </Box>
      <Box p="24px">
        <Button
          colorScheme="primary"
          variant="solid"
          type="submit"
          isFullWidth
          size="lg"
          m={0}
          isLoading={isLoadingPostContentSuggestions}
          onClick={() => {
            postContentSuggestions({
              id: session.id
            })
          }}
        >
          Generate suggestions
        </Button>
      </Box>
    </Stack>
  )
}

export default Overlay
