import React, { useState, useEffect } from 'react'
import { Stack, HStack, Text, Skeleton, useToast } from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import { useTreatmentPlan, useExperienceManager } from '@hooks'
import {
  useSessionControllerGetSuggestedContent,
  useSessionControllerGetDemoSuggestedContent,
  useInterventionsControllerSaveInterventionForLater,
  getInterventionsControllerGetSavedForLaterInterventionsQueryKey,
  getSessionControllerGetDemoSuggestedContentQueryKey,
  getSessionControllerGetSuggestedContentQueryKey
} from '~/clinician-api'
import ContentSuggestionCard from '@handlers/sessions/components/shared/assist/assessments/suggestion-card'
import { Pagination } from '@handlers/sessions/components/shared/pagination'
import { useAssistPanelContext } from '../context'

export const Suggestions = ({
  sessionId,
}: {
  sessionId: string
}) => {
  const [showDemoLoading, setShowDemoLoading] = useState(true)
  const { client } = useTreatmentPlan()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [suggestedContentIndex, setSuggestedContentIndex] = useState(0)

  const { isDemoClinician } = useExperienceManager()

  const { setOverlayPanel } = useAssistPanelContext()

  const showDemoExperience = client?.is_demo && isDemoClinician

  useEffect(() => {
    if (showDemoExperience) {
      setTimeout(() => {
        setShowDemoLoading(false)
      }, 1000)
    } else {
      setShowDemoLoading(false)
    }
  }, [showDemoExperience])

  const suggestionsQuery = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      },
      enabled: !!sessionId && !showDemoExperience,
      onSuccess: () => {
        setSuggestedContentIndex(0)
      }
    }
  })

  const {
    mutateAsync: saveInterventionForLater
  } = useInterventionsControllerSaveInterventionForLater({
    mutation: {
      onSuccess: () => {
        toast({
          title: 'Intervention Saved for Later',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
        queryClient.invalidateQueries(
          getInterventionsControllerGetSavedForLaterInterventionsQueryKey(
            client?.id ?? ''
          )
        )
        queryClient.invalidateQueries(
          getSessionControllerGetDemoSuggestedContentQueryKey(sessionId)
        )
        queryClient.invalidateQueries(
          getSessionControllerGetSuggestedContentQueryKey(sessionId)
        )
      }
    }
  })

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId,
    { demoContext: 'in-session' },
    {
      query: {
        enabled: showDemoExperience,
        onSuccess: () => {
          setSuggestedContentIndex(0)
        }
      }
    }
  )

  const suggestedInterventions = showDemoExperience
  ? demoData?.suggestedInterventions?.filter(intervention => !intervention.isActive)
  : suggestionsQuery?.data?.suggestedInterventions?.filter(intervention => !intervention.isActive)

  const suggestion = suggestedInterventions?.[suggestedContentIndex]

  const handlePreviewClick = () => {
    setOverlayPanel('INTERVENTION_ACTIVITY', {
      interventionId: suggestion?.interventionId,
      interventionName: suggestion?.interventionName,
      isReadOnly: true
    })
  }

  if (suggestionsQuery.isLoading || showDemoLoading) {
    return (
      <Stack mb="small">
        <HStack justifyContent="space-between">
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" width="100px" />
        </HStack>
        <Skeleton height="113px" borderRadius="8px" />
      </Stack>
    )
  }

  if (!suggestion) {
    return (
      <Stack spacing="0" gap="8px">
        <HStack spacing={3}>
          <Text fontWeight="bold">Suggestions</Text>
        </HStack>
        <HStack
          spacing="0"
          p="16px"
          gap="12px"
          border="1px solid"
          borderColor="pale_gray"
          borderRadius="8px"
        >
          <Text>No suggestions available.</Text>
        </HStack>
      </Stack>
    )
  }

  const count = suggestedInterventions?.length || 0
  
  const onPrevious = () => setSuggestedContentIndex(prev => prev - 1)
  const onNext = () => setSuggestedContentIndex(prev => prev + 1)

  const handleSaveForLater = async () => {
    await saveInterventionForLater({
      patientId: client?.id ?? '',
      interventionId: suggestion.interventionId,
      data: {
        contentSuggestionId: suggestion.contentSuggestionId
      }
    })
  }

  return (
    <Stack spacing="0" gap="8px">
      <HStack justifyContent="space-between">
        <HStack spacing={3}>
          <Text fontWeight="bold">Suggestions</Text>
        </HStack>
        <Pagination
          current={suggestedContentIndex + 1}
          total={count}
          onNext={onNext}
          onPrev={onPrevious}
        />
      </HStack>
      <ContentSuggestionCard
        isDemo={showDemoExperience}
        type="intervention"
        suggestion={{ ...suggestion, name: suggestion.interventionName }}
        sessionId={sessionId}
        onAssign={handleSaveForLater}
        previewUrl={''}
        onPreview={handlePreviewClick}
      />
    </Stack>
  )
}
