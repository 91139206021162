import React from 'react'
import ReadOnly from '../../components/ReadOnly'
import Form from '../../components/Form'
import PDFView from '../../components/PDFView'
import Empty from '../Empty'
import Loading from '../../components/Loading'
import { useTreatmentPlan } from '@hooks'

const View = () => {
  const {
    treatmentPlanQuery,
    treatmentPlan,
    client,
    isEditing,
  } = useTreatmentPlan()

  if (treatmentPlanQuery.isLoading) {
    return null
  }

  if (treatmentPlan?.isLoading) {
    return <Loading />
  }

  if (!treatmentPlan) {
    return <Empty />
  }

  if (treatmentPlan.url) {
    return <PDFView />
  }

  if (isEditing || !treatmentPlan.acceptedAt) {
    return <Form />
  }

  return <ReadOnly />
}

export default View