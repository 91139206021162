import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Flex,
  Text,
  Link,
  Button,
  CloseButton,
  useToast,
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverTrigger,
  VStack,
  StackProps
} from '@chakra-ui/react'
import {
  RadioButtonLoading,
  Letter,
  GoldenThreadLetter,
  Pdf
} from '@components/icons'
import { useQuery, useMutation } from 'react-query'
import { Link as RouterLink } from 'react-router-dom'
import { endpoints } from '@api'
import flagsmith from 'flagsmith'
import JoinWaitlist from './join-waitlist'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { useHistory, useLocation } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'
import moment from 'moment'
import { TreatmentPlanModal } from './document-viewer/TreatmentPlan'
import { useStoreState } from 'easy-peasy'
import { useExperienceManager } from '@hooks'
import { StoreModel } from 'src/store/types'

interface TreatmentPlan {
  url: string
}

const StackedLetters = () => (
  <>
    <Box position="absolute" style={{ transform: 'rotate(-4deg)' }}>
      <Letter />
    </Box>
    <Box position="absolute">
      <Letter />
    </Box>
  </>
)

const TreatmentPlan = ({
  client,
  containerProps = {},
  emptyStateText = '',
  isModalOpenProp = false,
  setIsModalOpen
}: {
  client: any
  containerProps?: StackProps
  emptyStateText?: string
  isModalOpenProp?: boolean
  setIsModalOpen?: (isOpen: boolean) => void
}) => {
  const [file, setFile] = useState<File | null | undefined>(null)
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const [organizationId, setOrganizationId] = useState(
    user?.clinic?.organization?.id
  )
  const { isAdmin } = useExperienceManager()
  const toast = useToast()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const history = useHistory()
  const location = useLocation()
  const [isModalOpen, setIsModalOpenInternal] = useState(isModalOpenProp)

  useEffect(() => {
    if (isModalOpenProp !== undefined) {
      setIsModalOpenInternal(isModalOpenProp)
    }
  }, [isModalOpenProp])

  const toggleModal = (isOpen: boolean) => {
    if (setIsModalOpen) {
      setIsModalOpen(isOpen)
    } else {
      setIsModalOpenInternal(isOpen)
    }
  }

  const hasDiagnosisSuggestions = flagsmith.hasFeature(
    FlagsmithFeatures.DIAGNOSIS_SUGGESTIONS
  )

  const clientId = client?.id

  const {
    data: treatmentPlanData,
    isLoading: isTreatmentPlanLoading,
    refetch: refetchTreatmentPlan
  } = useQuery(
    [endpoints.getClientTreatmentPlan.getCacheId(), clientId],
    () => endpoints.getClientTreatmentPlan.request({ clientId }),
    {
      refetchInterval: (data: any) => {
        if (data?.treatmentPlan?.isLoading) {
          return 5000
        }

        return false
      }
    }
  )

  const {
    mutate: generateTreatmentPlan,
    isLoading: isGenerating
  } = useMutation(async () => {
    await endpoints.createTreatmentPlan.request({ clientId })
    await refetchTreatmentPlan()
  })

  const { mutate: uploadTreatmentPlan, isLoading: isProcessing } = useMutation(
    async () => {
      if (!file) {
        return
      }

      const {
        url,
        uploadId
      }: any = await endpoints.createTreatmentPlanPresignedUrl.request({
        clientId,
        contentType: file.type
      })

      const uploadResponse = await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      })

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload file to S3')
      }

      await endpoints.createTreatmentPlan.request({
        clientId,
        uploadId
      })

      await refetchTreatmentPlan()
    },
    {
      onSuccess: () => {
        console.log('Treatment plan created successfully')
      },
      onError: error => {
        console.error('Error in treatment plan process:', error)
      }
    }
  )

  const isTreatmentPlanExtractLoading =
    treatmentPlanData?.treatmentPlan?.isLoading
  const prevLoadingState = useRef(isTreatmentPlanExtractLoading)
  const isDraft = treatmentPlanData?.treatmentPlan?.isDraft

  useEffect(() => {
    if (
      treatmentPlanData?.treatmentPlan?.id &&
      prevLoadingState.current &&
      !isTreatmentPlanExtractLoading &&
      !isDraft
    ) {
      const params = new URLSearchParams(location.search)
      params.set('treatmentPlanUploadSuccess', 'true')
      history.replace({ search: params.toString() })
    }
    prevLoadingState.current = isTreatmentPlanExtractLoading
  }, [
    isTreatmentPlanExtractLoading,
    isDraft,
    treatmentPlanData?.treatmentPlan?.id,
    location.search,
    history
  ])

  const { mutate: deleteTreatmentPlan, isLoading: isDeleting } = useMutation(
    async ({ clientId, treatmentPlanId }: any) => {
      await endpoints.deleteTreatmentPlan.request({ clientId, treatmentPlanId })
      await Promise.all([refetchTreatmentPlan(), refetchCanGenerate()])
    },
    {
      onSuccess: () => {
        const params = new URLSearchParams(location.search)
        params.delete('treatmentPlanUploadSuccess')
        history.replace({ search: params.toString() })
        datadogLogs.logger.info('Treatment plan deleted successfully')
        toast({
          title: 'Treatment plan deleted',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        toggleModal(false)
      },
      onError: error => {
        datadogLogs.logger.error(
          'Error in deleting treatment plan',
          {},
          error as Error
        )
      }
    }
  )

  const { mutateAsync: trackTxPlanEvent } = useMutation(async (data: any) => {
    await endpoints.trackTxPlanEvent.request({
      clientId,
      treatmentPlanId: treatmentPlanData?.treatmentPlan?.id,
      data
    })
  })

  const {
    mutateAsync: acceptTreatmentPlan,
    isLoading: isAccepting
  } = useMutation(
    async (data: any) => {
      await endpoints.acceptTreatmentPlan.request({
        clientId,
        treatmentPlanId: treatmentPlanData?.treatmentPlan?.id,
        data
      })
      await refetchTreatmentPlan()
    },
    {
      onError: error => {
        toast({
          title: 'Error accepting treatment plan',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  )

  const {
    mutateAsync: updateTreatmentPlan,
    isLoading: isUpdating
  } = useMutation(async (data: any) => {
    await endpoints.updateTxPlan.request({
      clientId,
      treatmentPlanId: treatmentPlanData?.treatmentPlan?.id,
      data
    })
    await refetchTreatmentPlan()
  })

  const {
    mutateAsync: regenerateTreatmentPlan,
    isLoading: isRegenerating,
    error: regenerationError
  } = useMutation(
    async (data: any) => {
      await endpoints.regenerateTxPlan.request({
        clientId,
        treatmentPlanId: treatmentPlanData?.treatmentPlan?.id,
        data
      })
      await refetchTreatmentPlan()
    },
    {
      onSuccess: () => {
        toast({
          title: 'Treatment plan regenerating',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
      }
    }
  )

  useEffect(() => {
    if (file) {
      uploadTreatmentPlan()
    }
  }, [file, uploadTreatmentPlan])

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const {
    data: canGenerateData,
    isLoading: isCanGenerateLoading,
    refetch: refetchCanGenerate
  } = useQuery(
    [endpoints.canGenerateTxPlan.getCacheId(), clientId],
    () =>
      endpoints.canGenerateTxPlan.request({ clientId }) as Promise<{
        canGenerate: boolean
      }>
  )

  const {
    data: progressNoteSettingsData,
    isLoading: progressNoteSettingsIsLoading
  } = useQuery<{ transcriptsEnabled: boolean }>(
    [endpoints.getProgressNoteSettings.getCacheId(), organizationId],
    () =>
      endpoints.getProgressNoteSettings.request({ organizationId }) as Promise<{
        transcriptsEnabled: boolean
      }>
  )

  const transcriptsEnabled = progressNoteSettingsData?.transcriptsEnabled

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event?.target?.files?.[0])
  }

  const treatmentPlan = (treatmentPlanData as any)?.treatmentPlan

  if (isTreatmentPlanLoading) {
    return null
  }

  const isLoading = treatmentPlan?.isLoading || isProcessing || isGenerating

  const canGenerate =
    canGenerateData?.canGenerate && !isCanGenerateLoading && transcriptsEnabled

  const renderMagicEditPopoverBody = () => {
    if (canGenerate) {
      return ''
    }

    if (isAdmin) {
      return (
        <Flex>
          {!transcriptsEnabled ? (
            <Text>
              Turn on transcript retention in{' '}
              <Link
                as={RouterLink}
                to="/settings/progress-notes"
                color="primary"
                _hover={{ color: 'primary' }}
                target="_blank"
                _focus={{ outline: 'none' }}
              >
                settings
              </Link>
              .
            </Text>
          ) : (
            <Text>Requires at least one transcript.</Text>
          )}
        </Flex>
      )
    }

    return <Text>Ask your admin to turn on transcript retention.</Text>
  }

  return (
    <VStack
      border="1px solid"
      flex="1"
      borderColor="pale_gray"
      borderRadius="8px"
      p="16px"
      boxShadow="0px 2px 8px 0px #00000014"
      alignItems="stretch"
      id="pendo-treatment-plan-generation-container"
      {...containerProps}
    >
      <Flex justifyContent="space-between" gap="8px" w="100%">
        <Flex gap="8px" w="100%">
          <Flex>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z"
                fill="#2D54E8"
              />
            </svg>
          </Flex>
          <Flex flexDirection="column">
            <Text fontWeight="bold">Treatment Plan</Text>
            {isLoading && (
              <Text lineHeight="17px" fontSize="13px" color="dark_gray">
                {isProcessing || treatmentPlan?.url
                  ? 'Uploading your treatment plan.'
                  : 'Generating a treatment plan that can be incorporated into future notes.'}
              </Text>
            )}
            {!isLoading && !treatmentPlan && (
              <Text lineHeight="17px" fontSize="13px" color="dark_gray">
                {emptyStateText ||
                  `Generate a treatment plan that can be incorporated into
                  future notes.`}
              </Text>
            )}
            {!isLoading && treatmentPlan?.isDraft && (
              <Text lineHeight="17px" fontSize="13px" color="dark_gray">
                Once accepted, this plan will be incorporated into future notes.
              </Text>
            )}
            {!isLoading && treatmentPlan && !treatmentPlan?.isDraft && (
              <Text lineHeight="17px" fontSize="13px" color="dark_gray">
                {`Last updated ${moment(treatmentPlan?.updatedAt).format(
                  'MMMM D, YYYY'
                )}`}
              </Text>
            )}
          </Flex>
        </Flex>
        <Flex alignItems="center">
          {isLoading && <RadioButtonLoading stroke="#C9C9C9" />}
        </Flex>
      </Flex>
      {!isLoading && !client.is_archived && (
        <>
          {!treatmentPlan ? (
            <Popover trigger="hover" placement="bottom">
              {!canGenerate && (
                <PopoverContent
                  _focus={{ outline: 'none' }}
                  borderColor="pale_gray"
                >
                  <PopoverArrow />
                  <PopoverBody>{renderMagicEditPopoverBody()}</PopoverBody>
                </PopoverContent>
              )}
              <PopoverTrigger>
                <Button
                  onClick={
                    canGenerate
                      ? () => generateTreatmentPlan()
                      : e => e.preventDefault()
                  }
                  isLoading={isGenerating}
                  borderRadius="4px"
                  style={{
                    textDecoration: 'none',
                    cursor: canGenerate ? 'pointer' : 'not-allowed',
                    opacity: canGenerate ? 1 : 0.6
                  }}
                  _focus={{ outline: 'none' }}
                  size="sm"
                >
                  Generate
                </Button>
              </PopoverTrigger>
            </Popover>
          ) : (
            <>
              {treatmentPlan?.isDraft ? (
                <>
                  <Button
                    onClick={() => toggleModal(true)}
                    borderRadius="4px"
                    style={{ textDecoration: 'none' }}
                    _focus={{ outline: 'none' }}
                    size="sm"
                    isDisabled={isDeleting}
                  >
                    Review & Accept
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => toggleModal(true)}
                  borderRadius="4px"
                  isDisabled={isDeleting}
                  style={{ textDecoration: 'none' }}
                  _focus={{ outline: 'none' }}
                  variant="outline"
                  size="sm"
                >
                  View Treatment Plan
                </Button>
              )}
            </>
          )}
          {(!treatmentPlan || treatmentPlan?.isDraft) && (
            <>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept="application/pdf"
              />
              <Button
                variant="link"
                textDecoration="none"
                _focus={{ outline: 'none' }}
                _hover={{ textDecoration: 'underline' }}
                onClick={handleButtonClick}
                size="sm"
                isDisabled={isDeleting}
              >
                Or upload your own
              </Button>
            </>
          )}
        </>
      )}
      {treatmentPlan && (
        <TreatmentPlanModal
          onDelete={() => {
            deleteTreatmentPlan({
              clientId,
              treatmentPlanId: treatmentPlan?.id
            })
          }}
          accept={acceptTreatmentPlan}
          onUpdate={updateTreatmentPlan}
          onRegenerate={regenerateTreatmentPlan}
          trackEvent={trackTxPlanEvent}
          isUpdating={isUpdating}
          isDeleting={isDeleting}
          isAccepting={isAccepting}
          isRegenerating={isRegenerating}
          isOpen={isModalOpen}
          onClose={() => toggleModal(false)}
          treatmentPlan={treatmentPlan}
          hasDiagnosisSuggestions={hasDiagnosisSuggestions}
          regenerationError={regenerationError as Error | null}
          client={client}
        />
      )}
    </VStack>
  )
}

export default TreatmentPlan
