import { Text } from '@chakra-ui/react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React from 'react'
import { Survey } from './survey'

const SurveyThankYou: React.FC = () => {
  return (
    <Survey>
      <Text mt={0}>
        <CheckCircleIcon
          style={{
            color: '#2D54E880',
            marginBottom: '-5px',
            marginRight: '5px'
          }}
        />{' '}
        Thanks for your participation!
      </Text>
    </Survey>
  )
}

export default SurveyThankYou
