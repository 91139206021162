import React from 'react'
import { useStoreState } from 'easy-peasy'
import {
  Box,
  Flex,
  Text,
  Link,
  useDisclosure,
} from '@chakra-ui/react'
import { HourGlassFull } from '@components/icons'
import { useStayOnBlueprintOfferControllerAccept } from '~/clinician-api'
import InfoModal from './info-modal'
import { useQueryParams } from '@hooks'
import { StoreModel } from 'src/store/types'
import moment from 'moment'

const CopyMap = {
  'THIRTY_DAY_PLUS_TRIAL': {
    bannerOffer: '30 Days Free',
    modalHeader: '1 Month Free: Blueprint Plus',
    duration: '30 day'
  }
}

export const StayOnBlueprintOfferBanner = () => {
  const { user } = useStoreState((state: StoreModel) => state.auth)
  
  const queryParams = useQueryParams()
  const showInfoModalNow = queryParams.get('showOffer') === 'true'

  const {
    isOpen: isInfoModalOpen,
    onClose: onCloseInfoModal,
    onOpen: onOpenInfoModal
  } = useDisclosure()

  const {
    mutate: acceptOffer,
    isLoading: isAcceptingOffer
  } = useStayOnBlueprintOfferControllerAccept()

  React.useEffect(() => {
    if (showInfoModalNow) {
      onOpenInfoModal()
    }
  }, [onOpenInfoModal, showInfoModalNow])

  const handleAccept = ({ onSuccess }: { onSuccess: () => void }) => {
    if (user?.stayOnBlueprintOffer?.id) {
      acceptOffer(
        {
          offerId: user.stayOnBlueprintOffer.id
        },
        {
          onSuccess: async () => {
            if (onSuccess) {
              onSuccess()
            }
          }
        }
      )
    }
  }

  if (!user || !user?.stayOnBlueprintOffer?.offer) return null

  const expirationDate = moment
    .utc(user.stayOnBlueprintOffer?.expiresAt)
    .format('MMM. DD')

  // @ts-ignore
  const offerCopy = CopyMap[user.stayOnBlueprintOffer.offer]

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="100%"
        bg="#E5FAEE"
        p={3}
      >
        <Box h="24px" w="24px" mr={2}>
          <HourGlassFull />
        </Box>
        <Flex wrap="wrap">
          <Text color="black">
            <Text fontWeight="bold" as="span">
              Ending Soon:{' '}
            </Text>
            Your Blueprint account via {user.clinic.name} expires{' '}
            {expirationDate} — Claim your {offerCopy.bannerOffer} offer to stay on Blueprint!
            <Link
              _focus={{
                outline: 'none'
              }}
              color="primary"
              onClick={onOpenInfoModal}
              ml={2}
            >
              Sign Up
            </Link>
          </Text>
        </Flex>
      </Box>
      <InfoModal
        isOpen={isInfoModalOpen}
        onClose={onCloseInfoModal}
        onAccept={handleAccept}
        isAccepting={isAcceptingOffer}
        copy={offerCopy}
      />
    </Box>
  )
}
