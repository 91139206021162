import React, { useState } from 'react'
import { Alert, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { TreatmentPlanFormValues } from './types'
import RegenerationConfirmationDialog from './regenerate-confirmation-dialog'
import { RegenerateIcon } from '../Icon'
import { datadogLogs } from '@datadog/browser-logs'

const RegenerateAlert = ({
  onConfirm,
  isRegenerating,
  docType,
}: {
  onConfirm: () => void
  isRegenerating: boolean
  docType: 'treatment_plan' | 'discharge_summary',
}) => {
  const [isRegenConfirmDialogOpen, setIsRegenConfirmDialogOpen] = useState(false)

  const docName = docType === 'treatment_plan' ? 'Treatment Plan' : 'Discharge Summary'
  return (
    <>
      <Alert
        status="info"
        borderRadius="md"
        colorScheme="#F7F8FE"
        p="small"
        my="small"
      >
        <Stack>
          <Text fontWeight="bold">{`Regenerate ${docName}`}</Text>
          <Text>
            {docType === 'treatment_plan' ?
              'Do you want to regenerate a new treatment plan with your diagnosis and focus of treatment selections?' :
              'Do you want to regenerate a new discharge summary with your new date range selections?'
            }
          </Text>
          <Flex gap="8px">
            <Button
              borderRadius="4px"
              size="sm"
              gap="8px"
              onClick={() => setIsRegenConfirmDialogOpen(true)}
            >
              <RegenerateIcon />
              {`Regenerate ${docName}`}
            </Button>
          </Flex>
        </Stack>
      </Alert>
      <RegenerationConfirmationDialog
        isOpen={isRegenConfirmDialogOpen}
        onClose={() => setIsRegenConfirmDialogOpen(false)}
        onConfirm={async () => {
          try {
            await onConfirm()
            setIsRegenConfirmDialogOpen(false)
          } catch (error) {
            datadogLogs.logger.error(
              `Error regenerating ${docName}`,
              {},
              error as Error
            )
          }
        }}
        isLoading={isRegenerating}
        isError={false} //TODO SETH: Add error handling
        docType={docType}
      />
    </>
  )
}

export const RegenerateAlertV2 = () => {
  const [isRegenerationConfirmationOpen, setIsRegenerationConfirmationOpen] = useState(false)
  const {
    values,
    setFieldValue,
    touched,
    isValid,
    setTouched
  } = useFormikContext<TreatmentPlanFormValues>()
  
  const canRegenerate =
    (touched.selectedDiagnoses || touched.selectedFocuses) && isValid

  if (!canRegenerate) {
    return null
  }

  return (
    <Alert
      status="info"
      borderRadius="md"
      colorScheme="#F7F8FE"
      p="small"
    >
      <Stack>
        <Text fontWeight="bold">Regenerate Test Treatment Plan</Text>
        <Text>
          Do you want to regenerate a new treatment plan with your diagnosis and
          focus of treatment selections?
        </Text>
        <Flex gap="8px">
          <Button
            borderRadius="4px"
            size="sm"
            gap="8px"
            onClick={() => setIsRegenerationConfirmationOpen(true)}
          >
           <RegenerateIcon />
            Regenerate Treatment Plan
          </Button>
          {/* <RegenerationConfirmationDialog
            isOpen={isRegenerationConfirmationOpen}
            onClose={() => setIsRegenerationConfirmationOpen(false)}
            onConfirm={() => setIsRegenerationConfirmationOpen(false)}
          /> */}
        </Flex>
      </Stack>
    </Alert>
  )
}

export default RegenerateAlert