import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const MotionContainer = motion(Box)

export const Survey = ({
  children
}: { children: React.ReactNode } & BoxProps) => {
  return (
    <MotionContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        ease: [0.57, 0.1, 0, 1]
      }}
      borderWidth={{ base: '0px', lg: '1px' }}
      borderTopWidth={{ base: '1px' }}
      borderStyle="solid"
      borderColor="#2D54E81A"
      borderRadius={{ base: '0px', lg: '8px' }}
      w="100%"
      p={{ base: 0, lg: 4 }}
      pt={{ base: 4, lg: 4 }}
    >
      {children}
    </MotionContainer>
  )
}
