import React from 'react'

export const PencilIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 11.5V14H4.5L11.8733 6.62667L9.37333 4.12667L2 11.5ZM3.94667 12.6667H3.33333V12.0533L9.37333 6.01333L9.98667 6.62667L3.94667 12.6667ZM13.8067 3.75333L12.2467 2.19333C12.1133 2.06 11.9467 2 11.7733 2C11.6 2 11.4333 2.06667 11.3067 2.19333L10.0867 3.41333L12.5867 5.91333L13.8067 4.69333C14.0667 4.43333 14.0667 4.01333 13.8067 3.75333Z"
      fill="#282828"
    />
  </svg>
)

export const RegenerateIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 4.74667H7.47992L9.30659 2.86667C7.48659 1.06667 4.53992 1 2.71992 2.8C0.899921 4.60667 0.899921 7.52 2.71992 9.32667C4.53992 11.1333 7.48659 11.1333 9.30659 9.32667C10.2133 8.43333 10.6666 7.38667 10.6666 6.06667H11.9999C11.9999 7.38667 11.4133 9.1 10.2399 10.26C7.89992 12.58 4.09992 12.58 1.75992 10.26C-0.573412 7.94667 -0.593412 4.18667 1.74659 1.87333C4.08659 -0.44 7.83992 -0.44 10.1799 1.87333L11.9999 0V4.74667Z"
      fill="white"
    />
  </svg>
)


