import React from 'react'
import {
  VStack,
  Text,
  Flex,
  Box,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  UnorderedList,
  ListItem
} from '@chakra-ui/react'
import { useQueryClient } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { StoreModel } from 'src/store/types'
import { endpoints } from '@api'
import { CircleCheck } from '@components/icons'

const imageUrl =
  'https://res.cloudinary.com/hellojoy/image/upload/v1738861422/blueprint_tools_zsqdqy.jpg'

interface Props {
  isOpen: boolean
  onClose: () => void
  onAccept: ({ onSuccess }: { onSuccess: () => void }) => void
  isAccepting: boolean
  copy: {
    modalHeader: string
    duration: string
  }
}

const InfoModal = ({ isOpen, onClose, onAccept, isAccepting, copy }: Props) => {
  const [isAccepted, setIsAccepted] = React.useState(false)

  const { user } = useStoreState((state: StoreModel) => state.auth)
  const queryClient = useQueryClient()

  const handleAccept = async () => {
    onAccept({ onSuccess: () => setIsAccepted(true) })
  }

  const handleDismiss = () => {
    queryClient.invalidateQueries(endpoints.getUserAccount.getCacheId())
    onClose()
  }

  if (!user || !user.stayOnBlueprintOffer) return null

  return (
    <Modal isOpen={isOpen} onClose={handleDismiss} size="xl">
      <ModalOverlay />
      <ModalContent overflow="hidden">
        {!isAccepted ? (
          <>
            <Box position="relative" height="33%" w="100%" mb={6}>
              <Image
                src={imageUrl}
                alt="Modal Image"
                objectFit="cover"
                w="100%"
                h="100%"
              />
              <Box position="absolute" top={0} left={0} w="100%">
                <ModalHeader textAlign="center" fontSize="24px">
                  {/* @ts-ignore */}
                  {copy.modalHeader}
                </ModalHeader>
                <ModalCloseButton top="20px" _focus={{ outline: 'none' }} />
              </Box>
            </Box>
            <ModalBody px={6} py={0}>
              <Text mb={4}>
                Independent practices deserve exceptional tools. With{' '}
                {user.clinic.name} discontinuing their Blueprint 1.0
                subscription, we’re offering you an exclusive {copy.duration}{' '}
                free trial of Blueprint Plus—designed specifically for solo and
                small group practices like yours:
              </Text>
              <Flex direction="column" mb={6}>
                <Flex alignItems="center" justifyContent="center">
                  <VStack align="flex-start">
                    <Flex>
                      <Flex flex="1">
                        <CircleCheck fill="#68E19F" height="22px" />
                      </Flex>
                      <Text ml={2}>
                        <Text fontWeight="bold" as="span">
                          Auto-transcribe
                        </Text>{' '}
                        lets you stay fully present with your patients
                      </Text>
                    </Flex>
                    <Flex>
                      <Flex flex="1">
                        <CircleCheck fill="#68E19F" height="22px" />
                      </Flex>
                      <Text ml={2}>
                        <Text fontWeight="bold" as="span">
                          Automated progress notes
                        </Text>{' '}
                        save you hours of manual work
                      </Text>
                    </Flex>
                    <Flex>
                      <Flex flex="1">
                        <CircleCheck fill="#68E19F" height="22px" />
                      </Flex>
                      <Text ml={2}>
                        <Text fontWeight="bold" as="span">
                          Smart treatment plans
                        </Text>{' '}
                        help simplify care planning
                      </Text>
                    </Flex>
                    <Flex>
                      <Flex flex="1">
                        <CircleCheck fill="#68E19F" height="22px" />
                      </Flex>
                      <Text ml={2}>
                        <Text fontWeight="bold" as="span">
                          Actionable insights
                        </Text>{' '}
                        empower you to make informed decisions before, during
                        and after sessions
                      </Text>
                    </Flex>
                  </VStack>
                </Flex>
              </Flex>
              <Text>
                Keep delivering great care with tools designed just for you.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                isFullWidth
                size="lg"
                onClick={handleAccept}
                isLoading={isAccepting}
              >
                Claim Offer
              </Button>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalHeader textAlign="center" fontSize="24px">
              You're in!
            </ModalHeader>
            <ModalCloseButton top="20px" _focus={{ outline: 'none' }} />
            <ModalBody px={6} py={0}>
              <VStack spacing={6} alignItems="flex-start">
                <Text>
                  Your upgraded Blueprint Plus account and 30-day free trial
                  will be ready on February 15th.
                </Text>
                <Text fontWeight="bold">Here’s what to expect:</Text>
                <UnorderedList px={6}>
                  <ListItem>
                    Your account will automatically transition with the same
                    credentials (email and password).
                  </ListItem>
                  <ListItem>
                    Your client list (names and emails) will be transferred
                  </ListItem>
                </UnorderedList>
                <Text>
                  You’ll sign in as normal to start using your new account.
                  After your trial, you’ll have the option to continue with a
                  paid plan.
                </Text>
              </VStack>
            </ModalBody>
            <ModalFooter py={6}>
              <Button isFullWidth size="lg" onClick={handleDismiss}>
                Done
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

export default InfoModal
