import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Button, IconButton, TextField } from '@blueprinthq/joy'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import * as Yup from 'yup'
import { Formik, Field, Form, FieldProps, FormikProps } from 'formik'
import { Survey } from './survey'

interface ControlIconButtonProps {
  icon: React.ReactElement
  ariaLabel: string
  onClick: () => void
  disabled?: boolean
  childProps?: Record<string, any>
}

const ControlIconButton: React.FC<ControlIconButtonProps> = ({
  icon,
  ariaLabel,
  onClick,
  disabled,
  childProps
}: ControlIconButtonProps) => {
  return (
    <IconButton
      icon={icon}
      aria-label={ariaLabel}
      height="48px"
      width="48px"
      borderRadius={12}
      background="#EAEEFD"
      color="primary"
      m={0}
      onClick={onClick}
      disabled={disabled ? true : false}
      _hover={{
        cursor: 'pointer',
        border: '2px solid',
        borderColor: 'primary'
      }}
      {...childProps}
    />
  )
}

interface NumberOfCliniciansSurveyProps {
  onSubmitted: (numberOfClinicians: number) => void
}

interface FormValues {
  numberOfClinicians: number
}

const NumberOfCliniciansSurvey: React.FC<NumberOfCliniciansSurveyProps> = ({
  onSubmitted
}: NumberOfCliniciansSurveyProps) => {
  return (
    <Survey>
      <Text fontWeight="bold" fontSize="xs">
        Survey
        <Text color="gray" fontWeight="normal" fontSize="xs" as="span">
          {' '}
          (1 of 2)
        </Text>
      </Text>
      <Text mt={4} mb={{ base: 0, lg: 4 }}>
        Could you tell us how many therapists are in your practice?
      </Text>
      <Formik
        initialValues={{ numberOfClinicians: 1 }}
        validationSchema={Yup.object({
          numberOfClinicians: Yup.number().required('Required')
        })}
        onSubmit={values => {
          onSubmitted(values.numberOfClinicians)
        }}
      >
        {(formikProps: FormikProps<FormValues>) => {
          const { values, errors, setFieldValue, isSubmitting } = formikProps
          return (
            <Form>
              <Flex direction={{ base: 'column', lg: 'row' }}>
                <Box flex="1" mt={{ base: 4, lg: 0 }}>
                  <Flex direction="row">
                    <Box flex={{ base: 3, md: 2 }}>
                      <Field name="numberOfClinicians">
                        {({ field }: FieldProps) => (
                          <TextField
                            {...field}
                            label="Number"
                            isRequired
                            type="number"
                            width={{ base: '100%', lg: '250px' }}
                            name="numberOfClinicians"
                            errorText={errors.numberOfClinicians}
                            backgroundColor="#FFF"
                          />
                        )}
                      </Field>
                    </Box>
                    <Box
                      flex={1}
                      display={{ base: 'flex', lg: 'none' }}
                      ml={2}
                      pb={4}
                    >
                      <ControlIconButton
                        icon={<AddIcon />}
                        ariaLabel={'AddOne'}
                        onClick={() => {
                          setFieldValue(
                            'numberOfClinicians',
                            (values.numberOfClinicians || 0) + 1
                          )
                        }}
                        disabled={isSubmitting}
                      />
                      <ControlIconButton
                        icon={<RemoveIcon />}
                        ariaLabel={'RemoveOne'}
                        onClick={() => {
                          setFieldValue(
                            'numberOfClinicians',
                            (values.numberOfClinicians || 0) - 1
                          )
                        }}
                        childProps={{ ml: '2' }}
                        disabled={
                          isSubmitting || values.numberOfClinicians <= 1
                        }
                      />
                    </Box>
                  </Flex>
                </Box>
                <Box flex="1" ml={{ base: 0, lg: 10 }} mt={0}>
                  <Flex
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    height="100%"
                  >
                    <Button
                      type="submit"
                      size="sm"
                      variant="outline"
                      borderRadius="24px"
                      width={{ base: '100%', lg: 'auto' }}
                      disabled={isSubmitting}
                      m={0}
                      mt={{ base: 2, lg: 0 }}
                      ml={'auto'}
                      _hover={{
                        bg: 'rgba(45, 84, 232, 0.04)'
                      }}
                    >
                      Submit
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Form>
          )
        }}
      </Formik>
    </Survey>
  )
}

export default NumberOfCliniciansSurvey
