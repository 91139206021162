import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { FormValues, baseUrl } from '../index'

export const RedirectToBeginning = () => {
  const { values } = useFormikContext<FormValues>()
  const history = useHistory()

  useEffect(() => {
    if (Object.values(values).filter(v => v).length === 0) {
      history.push(baseUrl)
    }
  }, [values, history])

  return null
}