import React, { useState, useEffect } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { useOnboardingControllerValidateEmail } from '~/clinician-api'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { AnimatePresence, motion } from 'framer-motion'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Box, Flex, Image as ChakraImage } from '@chakra-ui/react'
import { Navbar } from './components/navbar'
import { ProgressBar } from './components/progress-bar'
import { Loading } from '@components'
import { GetStarted } from './components/get-started'
import { RecordingReadiness } from './components/recording-readiness'
import { Email } from './components/email'
import { Name } from './components/name'
import { Password } from './components/password'
import { Practice } from './components/practice'
import { NotReadyToRecord } from './components/not-ready-to-record'
import { DEFAULT_PASSWORD_SCHEMA } from '../../components/passwordrules'
import { useMutation } from 'react-query'
import { endpoints } from '@api'
import { setUserProperties } from '@lib/clinician-tracking'
// @ts-ignore
import Cookies from 'js-cookie'

const MotionContainer = motion(Box)

const Image1 =
  'https://res.cloudinary.com/hellojoy/image/upload/v1737995311/onboarding/onboarding2.jpg'
const Image2 =
  'https://res.cloudinary.com/hellojoy/image/upload/v1737995189/onboarding/onboarding_ovhmel.jpg'
const Image3 = 
  'https://res.cloudinary.com/hellojoy/image/upload/v1739400426/onboarding/inviting-client-into-room.webp'
const images = [Image1, Image2, Image3]

const validationSchemas = {
  email: Yup.object({
    email: Yup.string()
      .email('Invalid email')
      .required('Email is required')
  }),
  name: Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required')
  }),
  password: Yup.object({
    password: DEFAULT_PASSWORD_SCHEMA
  }),
  organizationName: Yup.object({
    organizationName: Yup.string().required('Practice name is required')
  })
}

export interface FormValues {
  email: string
  firstName: string
  lastName: string
  password: string
  organizationName: string
}

export const baseUrl = '/onboarding'
const LOCAL_STORAGE_HUBSPOT_BRIDGE_ID = 'hubspotBridgeId'

export const SignupV2 = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [isSignupError, setIsSignupError] = useState(false)
  const [hubspotBridgeId, setHubspotBridgeId] = useState(() => {
    return localStorage.getItem(LOCAL_STORAGE_HUBSPOT_BRIDGE_ID) || null
  })

  const location = useLocation()
  const history = useHistory()
  const authLogin = useStoreActions((actions: any) => actions.auth.login)
  const { isAuthenticated } = useStoreState((state: any) => state.auth)

  useEffect(() => {
    setUserProperties({
      onboardingVersion: 2
    })
  }, [])

  const {
    mutate: validateEmail,
    isLoading: isValidatingEmail
  } = useOnboardingControllerValidateEmail()

  useEffect(() => {
    // preload the images before rendering to prevent flashing
    let loadedCount = 0
    images.forEach(src => {
      const img = new Image()
      img.src = src
      img.onload = () => {
        loadedCount++
        if (loadedCount === images.length) {
          setImagesLoaded(true)
        }
      }
      img.onerror = () => {
        loadedCount++
        if (loadedCount === images.length) {
          setImagesLoaded(true)
        }
      }
    })
  }, [])

  const { mutateAsync: createAccount, isLoading: isSigningUp } = useMutation(
    endpoints.postSelfServeSignup.request,
    {
      onSuccess: async (_, variables) => {
        setIsSignupError(false)
        const credentials = {
          email: variables.data.email,
          password: variables.data.password
        }
        await authLogin(credentials)
        localStorage.removeItem(LOCAL_STORAGE_HUBSPOT_BRIDGE_ID)
        history.replace(`${baseUrl}/get-started`)
      },
      onError(error) {
        console.log('v2_onboarding_signup_error', error)
        setIsSignupError(true)
      }
    }
  )

  const { mutateAsync: submitHubspotTrialLead } = useMutation(
    endpoints.postHubspotTrialLead.request,
    {
      onSuccess(data: any) {
        localStorage.setItem(
          LOCAL_STORAGE_HUBSPOT_BRIDGE_ID,
          data.hubspotBridgeId
        )
        setHubspotBridgeId(data.hubspotBridgeId)
      },
      onError(error) {
        console.log('error', error)
      }
    }
  )

  if (
    isAuthenticated &&
    location.pathname !== `${baseUrl}/get-started` &&
    location.pathname !== `${baseUrl}/sample-notes` &&
    !isSigningUp
  ) {
    history.push('/')
  }

  if (!imagesLoaded) {
    return (
      <Flex h="100vh" align="center" justify="center">
        <Loading />
      </Flex>
    )
  }

  const getHeroContent = (pathname: string) => {
    if (
      pathname === `${baseUrl}/get-started` ||
      pathname === `${baseUrl}/sample-notes`
    )
      return null

    const imageUrl = getHeroImageUrl()

    return (
      <AnimatePresence>
        <MotionContainer
          key={imageUrl}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            opacity: { duration: 0.5, ease: 'easeInOut' },
            exit: { duration: 0.1, ease: 'easeInOut' }
          }}
          style={{ position: 'absolute', width: '100%', height: '100%' }}
        >
          <ChakraImage
            src={imageUrl}
            objectFit="cover"
            h="100%"
            w="100%"
            borderRadius="8px"
          />
        </MotionContainer>
      </AnimatePresence>
    )
  }

  const getHeroImageUrl = () => {
    switch (location.pathname) {
      case `${baseUrl}/practice`:
        return Image1
      case `${baseUrl}/recording-readiness`:
        return Image3
      default:
        return Image2
    }
  }

  const isExpanded =
    location.pathname === `${baseUrl}/get-started` ||
    location.pathname === `${baseUrl}/sample-notes`

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    organizationName: ''
  }

  const getCurrentStepValidation = (pathname: string) => {
    switch (pathname) {
      case baseUrl:
        return validationSchemas.email
      case `${baseUrl}/name`:
        return validationSchemas.name
      case `${baseUrl}/password`:
        return validationSchemas.password
      case `${baseUrl}/practice`:
        return validationSchemas.organizationName
      default:
        return null
    }
  }

  const getBackLink = () => {
    switch (location.pathname) {
      case baseUrl:
        return null
      case `${baseUrl}/name`:
        return () => history.push(baseUrl)
      case `${baseUrl}/password`:
        return () => history.push(`${baseUrl}/name`)
      case `${baseUrl}/practice`:
        return () => history.push(`${baseUrl}/password`)
      case `${baseUrl}/recording-readiness`:
          return () => history.push(`${baseUrl}/practice`)
      default:
        return null
    }
  }

  const getSkipLink = () => {
    switch (location.pathname) {
      case `${baseUrl}/get-started`:
        return () => history.push(`${baseUrl}/sample-notes`)
      case `${baseUrl}/sample-notes`:
        return () => history.push('/')
      default:
        return null
    }
  }

  const getProgress = () => {
    switch (location.pathname) {
      case baseUrl:
        return 0
      case `${baseUrl}/name`:
        return 20
      case `${baseUrl}/password`:
        return 40
      case `${baseUrl}/recording-readiness`:
        return 50
      case `${baseUrl}/practice`:
        return 60
      case `${baseUrl}/get-started`:
        return 80
      case `${baseUrl}/sample-notes`:
        return 90
      default:
        return 0
    }
  }

  const handleSubmit = async (values: FormValues) => {
    await createAccount({
      data: {
        ...values,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        hubspotutk: Cookies.get('hubspotutk'),
        hubspotBridgeId,
        referralId: Cookies.get('bp_referral') ?? '',
        facebookClickId: Cookies.get('_fbc'),
        facebookPixelId: Cookies.get('_fbp'),
        pageUri: window.location.href,
        trialVariant: 'feb_2025',
        initialProduct: 'documentation_automation',
        trialType: 'freemium'
      }
    })
  }

  const handleNextStepValidation = async ({
    validateForm,
    setTouched,
    onSuccess
  }: {
    validateForm: () => Promise<Partial<typeof initialValues>>
    values: typeof initialValues
    setTouched: (touched: Record<string, boolean>) => void
    onSuccess: () => void
  }) => {
    const errors = await validateForm()
    if (Object.keys(errors).length === 0) {
      onSuccess()
    } else {
      setTouched(
        Object.keys(errors).reduce<Record<string, boolean>>((acc, field) => {
          acc[field] = true
          return acc
        }, {})
      )
    }
  }

  const handleUpdateHubspotLead = async (values: FormValues) => {
    submitHubspotTrialLead(
      {
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          hubspotBridgeId,
          hubspotutk: Cookies.get('hubspotutk'),
          referralId: Cookies.get('bp_referral') ?? '',
          facebookClickId: Cookies.get('_fbc'),
          facebookPixelId: Cookies.get('_fbp'),
          pageUri: window.location.href,
          trialVariant: 'feb_2025'
        }
      },
      {
        onSuccess: data => {
          setHubspotBridgeId(data.hubspotBridgeId)
        }
      }
    )
  }

  return (
    <Flex
      w="100%"
      h="100%"
      bg="#F6F7FE"
      p={{ base: 0, md: 4 }}
      gap={{
        base: 0,
        md: location.pathname === `${baseUrl}/get-started` ? 0 : 4
      }}
    >
      <Flex
        h="100%"
        flexDirection="column"
        width={{ base: '100%', md: isExpanded ? '100%' : '50%' }}
        transition="all 500ms cubic-bezier(0.920, 0.005, 0.145, 1.000)"
        position="relative"
      >
        <Navbar onBack={getBackLink()} onSkip={getSkipLink()} />
        <Flex flex="1" justifyContent={{ base: 'flex-start', md: 'center' }}>
          <Formik<FormValues>
            initialValues={initialValues}
            validationSchema={getCurrentStepValidation(location.pathname)}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({
              validateForm,
              values,
              setTouched,
              setFieldTouched,
              handleSubmit,
              setFieldError
            }) => (
              <Form
                noValidate
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <AnimatePresence exitBeforeEnter>
                  <Switch location={location} key={location.pathname}>
                    <Route
                      exact
                      path={baseUrl}
                      render={() => (
                        <Email
                          isLoading={isValidatingEmail}
                          onNext={async () =>
                            await handleNextStepValidation({
                              validateForm,
                              values,
                              setTouched,
                              onSuccess: () => {
                                validateEmail(
                                  {
                                    data: { email: values.email }
                                  },
                                  {
                                    onSuccess: () => {
                                      handleUpdateHubspotLead(values)
                                      history.push(`${baseUrl}/name`)
                                    },
                                    onError: error => {
                                      setFieldError(
                                        'email',
                                        'An account with this email already exists, try logging in instead'
                                      )
                                      setFieldTouched('email', true, false)
                                    }
                                  }
                                )
                              }
                            })
                          }
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/name`}
                      render={() => (
                        <Name
                          onNext={async () =>
                            await handleNextStepValidation({
                              validateForm,
                              values,
                              setTouched,
                              onSuccess: () => {
                                handleUpdateHubspotLead(values)
                                history.push(`${baseUrl}/password`)
                              }
                            })
                          }
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/password`}
                      render={() => (
                        <Password
                          firstName={values.firstName}
                          onNext={async () =>
                            await handleNextStepValidation({
                              validateForm,
                              values,
                              setTouched,
                              onSuccess: () => {
                                history.push(`${baseUrl}/practice`)
                              }
                            })
                          }
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/practice`}
                      render={() => (
                        <Practice
                          isLoading={isSigningUp}
                          isError={isSignupError}
                          onNext={async () =>
                            await handleNextStepValidation({
                              validateForm,
                              values,
                              setTouched,
                              onSuccess: () => {
                                handleSubmit()
                              }
                            })
                          }
                        />
                      )}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/get-started`}
                      render={() => <GetStarted />}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/recording-readiness`}
                      render={() => <RecordingReadiness />}
                    />
                    <Route
                      exact
                      path={`${baseUrl}/sample-notes`}
                      render={() => <NotReadyToRecord />}
                    />
                  </Switch>
                </AnimatePresence>
              </Form>
            )}
          </Formik>
        </Flex>
        <ProgressBar progress={getProgress()} />
      </Flex>
      <Box
        width={{ base: '0%', md: isExpanded ? '0%' : '50%' }}
        bg="gray.100"
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        position="relative"
      >
        {getHeroContent(location.pathname)}
      </Box>
    </Flex>
  )
}
