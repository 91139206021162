import React from 'react'
import { Box, Flex, Radio, RadioGroup, Text } from '@chakra-ui/react'

import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Button } from '@blueprinthq/joy'
import * as Yup from 'yup'
import { Survey } from './survey'

interface Props {
  onSubmitted: (persona: string) => void
}

interface FormValues {
  persona: string
}

const PersonaSurvey: React.FC<Props> = ({ onSubmitted }: Props) => {
  return (
    <Survey>
      <Text fontWeight="bold" fontSize="xs">
        Survey
        <Text color="gray" fontWeight="normal" fontSize="xs" as="span">
          {' '}
          (2 of 2)
        </Text>
      </Text>
      <Text mt={4} mb={{ base: 0, lg: 4 }}>
        How would you describe yourself?
      </Text>
      <Formik
        initialValues={{ persona: 'solo' }}
        validationSchema={Yup.object({
          persona: Yup.string().required('Please select an option.')
        })}
        onSubmit={values => {
          onSubmitted(values.persona)
        }}
      >
        {(formikProps: FormikProps<FormValues>) => {
          const { setFieldValue, isSubmitting } = formikProps
          return (
            <Form>
              <Flex direction={{ base: 'column', lg: 'row' }}>
                <Box flex="3" mt={{ base: 4, lg: 0 }}>
                  <Field name="persona">
                    {({ field }: FieldProps) => (
                      <RadioGroup
                        {...field}
                        onChange={value => {
                          setFieldValue('persona', value)
                        }}
                        display="flex"
                        flexDirection="column"
                      >
                        <Radio value="solo" size="sm" mb={2}>
                          I'm an individual practicioner
                        </Radio>
                        <Radio value="clinician_within_group" size="sm" mb={2}>
                          I'm a therapist within a group practice
                        </Radio>
                        <Radio value="group_leader" size="sm" mb={2}>
                          I'm an owner or leader at a group practice
                        </Radio>
                      </RadioGroup>
                    )}
                  </Field>
                </Box>
                <Box flex="1" ml={{ base: 0, lg: 10 }} mt={0}>
                  <Flex
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    height="100%"
                  >
                    <Button
                      type="submit"
                      size="sm"
                      variant="outline"
                      borderRadius="24px"
                      width={{ base: '100%', lg: 'auto' }}
                      disabled={isSubmitting}
                      m={0}
                      mt={{ base: 4, lg: 0 }}
                      ml={'auto'}
                      _hover={{
                        bg: 'rgba(45, 84, 232, 0.04)'
                      }}
                    >
                      Submit
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Form>
          )
        }}
      </Formik>
    </Survey>
  )
}

export default PersonaSurvey
