export const FlagsmithFeatures = Object.freeze({
  // KEEP
  ASSIST: 'assist',
  CLIENT_CONSENT: 'client_consent',
  DEMO_CLINICIAN: 'demo_clinician',
  EDIT_CHECK_INS: 'edit_check_ins',
  EHR_APPOINTMENTS: 'ehr_appointments',
  EHR_INTEGRATION_CLIENT_OPT_IN: 'ehr_integration_client_opt_in',
  EHR_INTEGRATION_ISSUES: 'ehr_integration_issues',
  SHOW_APPOINTMENT_WORKFLOWS: 'show_appointment_workflows',
  SHOW_IS_DEMO_CLIENTS_IN_START_SESSION:
    'show_is_demo_clients_in_start_session',
  SHOW_LIBRARY_NAV: 'show_library_nav',
  SYMPTOM_TRACKER_AND_WORKSHEETS_ENABLED:
    'symptom_tracker_and_worksheets_enabled',
  USE_DEMO_NOTES_WITH_EBC_CONTENT: 'use_demo_notes_with_ebc_content',
  RULA_UI_EXPERIENCE: 'rula_ui_experience',

  // investigate
  ALWAYS_SHOW_SAMPLE_SESSIONS: 'always_show_sample_sessions',
  AUDIT_REPORT: 'audit_report',
  DIAGNOSIS_SUGGESTIONS: 'has_diagnosis_suggestions',

  // features
  INTERVENTION_SUGGESTIONS_FULL_RELEASE:
    'intervention_suggestions_full_release_final',
  INTERVENTION_SUGGESTIONS: 'intervention_suggestions',
  PLUS_PLAN_FREE_TRIAL_BANNER: 'plus_plan_free_trial_banner_release',
  AUDIO_CAPTURE_V2: 'audio_capture_v2',
  PRESIGNED_URL_V2: 'presigned_url_v2',
  DISCHARGE_SUMMARY: 'discharge_summary',
  CLINICIAN_MOBILE_PROMO: 'clinician_mobile_promo'
})
