import { useContext } from 'react'
import { WidgetContext } from '@context'

export const useWidget = () => {
  const context = useContext(WidgetContext)

  if (context === undefined) {
    throw new Error('useWidget must be used within an WidgetProvider')
  }

  return context
}
