import React, { useState, useEffect, useRef, useMemo } from 'react'
import { TrashIcon } from '@icons'
import { DatePicker } from '../../components/DatePicker'
import { PencilIcon } from '../../components/Icon'
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog'
import RegenerateAlert from '../../components/Form/regenerate-alert'
import {
  Text,
  Button,
  Box,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  FormControl,
  FormLabel,
  ModalCloseButton,
  ModalBody,
  HStack,
  ModalHeader,
  ModalFooter,
  Textarea,
  useBreakpointValue,
  Flex,
  useToast,
  keyframes,
} from '@chakra-ui/react'
import {
  Formik,
  Form,
  Field,
  FieldProps,
  useFormikContext
} from 'formik'
import { CopyNoteIcon } from '@icons'
import { copyToClipboard } from '@utilities'
import * as Yup from 'yup'
import moment from 'moment'
import { isChrome, isMobile } from 'react-device-detect'
import { DischargeSummaryFormValues } from '../../components/Form/types'
import { PatchDischargeSummaryDto } from '~/clinician-api/models'

interface ModalProps {
  onDelete: (values: {
    dischargeSummaryId: string,
    patientId: string
  }) => Promise<void>
  onUpdate: (values: {
    dischargeSummaryId: string,
    patientId: string,
    data: {
      firstSessionDate: string,
      lastSessionDate: string,
      summary: string
    }
  }) => Promise<void>
  isUpdatingError: boolean
  isOpen: boolean
  onClose: () => void
  dischargeSummary: any
  client: any
  isUpdating: boolean
  isDeleting: boolean,
  isRegenerating: boolean,
  setHasClickedEdit: (value: boolean) => void
  hasClickedEdit?: boolean,
  sessionList: any[]
}

const slideInRight = keyframes`
  from {
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInLeft = keyframes`
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const DischargeSummaryModal: React.FC<ModalProps> = ({
  onDelete,
  onUpdate,
  isOpen,
  onClose,
  dischargeSummary,
  client,
  isUpdating,
  isRegenerating,
  isDeleting,
  setHasClickedEdit,
  hasClickedEdit,
  sessionList
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const toast = useToast()
  const modalSize = useBreakpointValue({ base: 'full', sm: 'full', md: '4xl' })
  const contentHeight = useBreakpointValue({
    base: 'calc(100vh - 155px)',
    sm: 'calc(100vh - 155px)',
    md: 'calc(100vh - 220px)'
  })

  useEffect(() => {
    if (isRegenerating) {
      setIsEditing(false)
      onClose()
    }
  }, [isRegenerating])

  const calculatedFirstSessionDate = useMemo(() => (
    moment.utc(dischargeSummary?.dataForGeneration?.firstSessionDate).format('YYYY-MM-DD') ||
      (sessionList ?
      moment.utc(sessionList?.[sessionList.length - 1]?.date).format('YYYY-MM-DD') :
      moment.utc().format('YYYY-MM-DD'))
  ), [dischargeSummary, sessionList])

  const calculatedLastSessionDate = useMemo(() => (
    moment.utc(dischargeSummary?.dataForGeneration?.lastSessionDate).format('YYYY-MM-DD') ||
      (sessionList ?
      moment.utc(sessionList?.[0]?.date).format('YYYY-MM-DD') :
      moment.utc().format('YYYY-MM-DD'))
  ), [dischargeSummary, sessionList])
  
  const validationSchema = Yup.object().shape({
    firstSessionDate: Yup.date()
      .typeError('Invalid date')
      .required('Required')
      .max(new Date(), 'Cannot be in the future') // Ensure it is not in the future
      .test(
        'not-after-last-session',
        'Cannot be after the last session',
        function (value) {
          const { lastSessionDate } = this.parent;
          return !lastSessionDate || !value || value <= lastSessionDate; // Validate only if both dates exist
        }
      ),
    lastSessionDate: Yup.date()
      .typeError('Invalid date')
      .required('Required')
      .max(new Date(), 'Cannot be in the future') // Ensure it is not in the future
      .test(
        'not-before-first-session',
        'Cannot be before the first session',
        function (value) {
          const { firstSessionDate } = this.parent;
          return !firstSessionDate || !value || value >= firstSessionDate; // Validate only if both dates exist
        }
      ),
    dischargeSummaryText: Yup.string()
  })

  const handleCopyAllToClipboard = () => {
    const values = []

    values.push(`Name: ${client.first_name} ${client.last_name}`)
    values.push(`DOB: ${moment.utc(client.date_of_birth).format('M/D/YYYY')}\n`)
    values.push(`First session date: ${moment.utc(dischargeSummary?.dataForGeneration?.firstSessionDate).format('M/D/YYYY')}`)
    values.push(`Last session date: ${moment.utc(dischargeSummary?.dataForGeneration?.lastSessionDate).format('M/D/YYYY')}\n`)
    values.push('Discharge Summary:')
    values.push(dischargeSummary?.summary)

    const value = values.join('\n')

    copyToClipboard(value)

    toast({
      title: 'Copied to clipboard',
      status: 'success',
      duration: 2000,
      isClosable: true
    })
  }
    
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
        setIsEditing(false)
      }}
      size={modalSize}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent maxH="none" position="relative">
        <ModalHeader
          textAlign="center"
          position="sticky"
          top="0"
          borderBottom="1px solid"
          borderColor="pale_gray"
          bg="white"
          zIndex="9"
          borderRadius="8px 8px 0 0px"
        >
          <Text>
            {isEditing ? 'Edit Discharge Summary' : 'Review Discharge Summary'}
          </Text>
          <ModalCloseButton _focus={{ outline: 'none' }} />
        </ModalHeader>
        <Formik<DischargeSummaryFormValues>
          enableReinitialize
          initialValues={{
            firstSessionDate: calculatedFirstSessionDate,
            lastSessionDate: calculatedLastSessionDate,
            dischargeSummaryText: dischargeSummary?.summary || ''
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const {
              firstSessionDate,
              lastSessionDate,
              dischargeSummaryText
            } = values

            const data: PatchDischargeSummaryDto = {
              firstSessionDate,
              lastSessionDate,
              summary: dischargeSummaryText ?? ''
            }
          
            await onUpdate({
              patientId: client.id,
              dischargeSummaryId: dischargeSummary.id,
              data
            })

            setTimeout(() => {
              setIsEditing(false)
            }, 500)

            toast({
              title: 'Discharge summary saved',
              status: 'success',
              duration: 3000,
              isClosable: true
            })
          }}
        >
          {() => (
              <Form>
                <ModalBody
                  w="100%"
                  overflowY="auto"
                  py="0"
                  maxH={`calc(${contentHeight} - 64px)`}
                >
                  <Stack h={contentHeight}>
                    <Stack
                      mt="24px"
                      pb="24px"
                      gap="16px"
                      maxW="900px"
                      mx="auto"
                      w="100%"
                    >
                      {isEditing ? (
                        <EditContent
                          onRegenerate={onUpdate}
                          setIsEditing={setIsEditing}
                          onClose={onClose}
                          client={client}
                          dischargeSummary={dischargeSummary}
                          isRegenerating={isUpdating}
                        />
                      ) : (
                        <ReviewContent
                          client={client}
                          dischargeSummary={dischargeSummary}
                        />
                      )}
                    </Stack>
                  </Stack>
                </ModalBody>
                <ModalFooter
                  position="sticky"
                  bottom="0"
                  bg="white"
                  borderTop="1px solid"
                  borderColor="pale_gray"
                  borderRadius="0px 0px 8px 8px"
                >
                  {isEditing ? (
                    <EditFooter
                      setIsEditing={setIsEditing}
                      calculatedFirstSessionDate={calculatedFirstSessionDate}
                      calculatedLastSessionDate={calculatedLastSessionDate}
                      dischargeSummary={dischargeSummary}
                      isUpdating={isUpdating}
                      isDeleting={isDeleting}
                    />
                  ) : (
                    <ReviewFooter
                      hasClickedEdit={hasClickedEdit}
                      setHasClickedEdit={setHasClickedEdit}
                      setIsEditing={setIsEditing}
                      isDeleting={isDeleting}
                      setIsDeleteConfirmationOpen={setIsDeleteConfirmationOpen}
                      handleCopyAllToClipboard={handleCopyAllToClipboard}
                    />
                  )}
                </ModalFooter>
              </Form>
            )}
        </Formik>
      </ModalContent>
      <DeleteConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={() => {
          onDelete({
            dischargeSummaryId: dischargeSummary.id,
            patientId: client.id
          })
          setIsDeleteConfirmationOpen(false)
          setIsEditing(false)
          onClose()
        }}
        isLoading={isDeleting}
        docType="discharge_summary"
      />
    </Modal>
  )
}

const ReviewContent = ({
  client,
  dischargeSummary
}: {
  client: any
  dischargeSummary: any
}) => {
  return (
    <Flex
      gap="4px"
      mt={isMobile && isChrome ? '16px' : '0px'}
      flexDirection="column"
    >
      <Text>
        <b style={{ marginRight: '4px' }}>Name: </b>
        {`${client.first_name} ${client.last_name}`}
      </Text>
      <Text>
        <b style={{ marginRight: '4px' }}>DOB: </b>
        {moment
          .utc(client.date_of_birth)
          .format('M/D/YYYY')}
      </Text>
      <Box h="16px" />
      <Text>
        <b style={{ marginRight: '4px' }}>First session date: </b>
        {moment(dischargeSummary?.dataForGeneration?.firstSessionDate).format(
          'M/D/YYYY'
        )}
      </Text>
      <Text>
        <b style={{ marginRight: '4px' }}>Last session date: </b>
        {moment(dischargeSummary?.dataForGeneration?.lastSessionDate).format(
          'M/D/YYYY'
        )}
      </Text>
      <Box h="16px" />
      <Text>
        <b style={{ marginRight: '4px' }}>Last Updated: </b>
        {moment(dischargeSummary?.updatedAt).format(
          'M/D/YYYY'
        )}
      </Text>
      <br />
      <HStack spacing="xsmall">
        <Text fontWeight="bold">Discharge Summary</Text>
        <CopyNoteIcon fill="black" />
      </HStack>
      <Box h="16px" />
      <Text whiteSpace="pre-line">
        {dischargeSummary?.summary}
      </Text>
    </Flex>
  )
}

const ReviewFooter = ({
  hasClickedEdit,
  setHasClickedEdit,
  setIsEditing,
  isDeleting,
  setIsDeleteConfirmationOpen,
  handleCopyAllToClipboard
}: {
  hasClickedEdit: boolean | undefined
  setHasClickedEdit: (value: boolean) => void
  setIsEditing: (value: boolean) => void
  isDeleting: boolean
  setIsDeleteConfirmationOpen: (value: boolean) => void
  handleCopyAllToClipboard: () => void
}) => {
  return (
    <Flex
      animation={hasClickedEdit ? `${slideInRight} 0.3s` : ''}
      w="100%"
      justifyContent={{
        base: 'space-between',
        md: 'flex-end'
      }}
    >
      <Button
        colorScheme="primary"
        gap="8px"
        borderRadius="full"
        onClick={handleCopyAllToClipboard}
        _focus={{ outline: 'none' }}
        w={{ base: '100%', md: 'auto' }}
      >
        Copy All
      </Button>
      <Button
        variant="outline"
        onClick={e => {
          e.preventDefault()
          setHasClickedEdit(true)
          setIsEditing(true)
        }}
        borderRadius="full"
        w={{ base: '100%', md: 'auto' }}
      >
        <Box mr="8px">
          <PencilIcon />
        </Box>
        Edit
      </Button>
      <Button
        variant="outline"
        gap="8px"
        borderRadius="full"
        isLoading={isDeleting}
        onClick={() => {
          setIsDeleteConfirmationOpen(true)
        }}
        w={{ base: '100%', md: 'auto' }}
      >
        <TrashIcon fill="#EB5164" />
        Delete
      </Button>
    </Flex>
  )
}

const EditContent = ({
  client,
  setIsEditing,
  dischargeSummary,
  onClose,
  isRegenerating,
  onRegenerate
}: {
  client: any
  setIsEditing: (value: boolean) => void
  dischargeSummary: any
  onClose: () => void
  isRegenerating: boolean
  onRegenerate: (values: any) => void
}) => {
  const [isRegenerateAlertVisible, setIsRegenerateAlertVisible] = useState(false)
  const toast = useToast()
  const useAutosizeTextArea = (value: string) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = 'auto'
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
      }
    }, [value])

    return textAreaRef
  }

  const { values, errors, setFieldValue, isValid } = useFormikContext<any>()
  const textAreaRef = useAutosizeTextArea(values.dischargeSummaryText)
  
  return (
    <>
      <Flex
        gap="4px"
        mt={isMobile && isChrome ? '16px' : '0px'}
        flexDirection="column"
      >
        <Text>
          <b>Name: </b>
          {`${client.first_name} ${client.last_name}`}
        </Text>
        <Text>
          <b>DOB: </b>
          {moment
            .utc(client.date_of_birth)
            .format('M/D/YYYY')}
        </Text>
        <Text>
          <b>Last Updated: </b>
          {moment(dischargeSummary?.updatedAt).format(
            'M/D/YYYY'
          )}
        </Text>
      </Flex>
      <HStack align="flex-start">
        <FormControl w="250px">
          <FormLabel fontWeight="bold">First session date:</FormLabel>
          <Field name="firstSessionDate">
            {
              (field: any) => {
                return (
                  <DatePicker
                    {...field}
                    label={''}
                    value={values.firstSessionDate}
                    onChange={(newDate) => {
                      setFieldValue("firstSessionDate", newDate)
                      setIsRegenerateAlertVisible(true)
                    }}
                    shouldDisableDate={date => {
                      const { firstSessionDate, lastSessionDate } = values || {}
                      return (
                        (firstSessionDate && date.isBefore(firstSessionDate, 'day')) ||
                        (lastSessionDate && date.isAfter(lastSessionDate, 'day')) || false
                      )
                    }}
                    errorText={errors.firstSessionDate}
                  />
                )
              }

            }
          </Field>
        </FormControl>
        <FormControl w="250px">
          <FormLabel fontWeight="bold">
            Last session date:
          </FormLabel>
          <Field name="lastSessionDate">
            {
              (field: any) => {
                return (
                  <DatePicker
                    {...field}
                    label={''}
                    value={values.lastSessionDate}
                    onChange={(newDate) => {
                      setFieldValue("lastSessionDate", newDate)
                      setIsRegenerateAlertVisible(true)
                    }}
                    shouldDisableDate={date => {
                      const { firstSessionDate, lastSessionDate } = values || {}
                      return (
                        (firstSessionDate && date.isBefore(firstSessionDate, 'day')) ||
                        (lastSessionDate && date.isAfter(lastSessionDate, 'day')) || false
                      )
                    }}
                    errorText={errors.lastSessionDate}
                  />
                )
              }
            }
          </Field>
        </FormControl>
      </HStack>
      {isValid && isRegenerateAlertVisible && <RegenerateAlert
        onConfirm={() => {
          onRegenerate({
            patientId: client.id,
            dischargeSummaryId: dischargeSummary.id,
            data: {
              firstSessionDate: values.firstSessionDate,
              lastSessionDate: values.lastSessionDate
            }
          })
          setIsRegenerateAlertVisible(false)
          setIsEditing(false)
          onClose()
          toast({
            title: 'Discharge summary regenerating',
            status: 'success',
            duration: 2000,
            isClosable: true
          })
        }}
        isRegenerating={isRegenerating}
        docType="discharge_summary"
      />}
      <FormControl>
        <HStack spacing="0">
          <FormLabel fontWeight="bold">Discharge Summary</FormLabel>
          <Box h="24px" w="24px" mb="8px"><CopyNoteIcon fill="black" /></Box>
        </HStack>
        <Field name="dischargeSummaryText">
          {({ field }: FieldProps<string>) => (
            <Textarea
              {...field}
              ref={textAreaRef}
              borderColor="pale_gray"
              overflow="hidden"
            />
          )}
        </Field>
      </FormControl>
      {isChrome && <Box h="4px" />}
    </>
  )
}

const EditFooter = ({
  setIsEditing,
  calculatedFirstSessionDate,
  calculatedLastSessionDate,
  dischargeSummary,
  isUpdating,
  isDeleting
}: {
  setIsEditing: (value: boolean) => void
  calculatedFirstSessionDate: string
  calculatedLastSessionDate: string
  dischargeSummary: any
  isUpdating: boolean
  isDeleting: boolean
}) => {
  const { resetForm, isValid } = useFormikContext<any>()

  return (
    <Flex
      animation={`${slideInLeft} 0.3s`}
      w="100%"
      justifyContent={{
        base: 'space-between',
        md: 'flex-start'
      }}
    >
      <Button
        variant="outline"
        gap="8px"
        borderRadius="full"
        w={{ base: '100%', md: 'auto' }}
        onClick={e => {
          e.preventDefault()
          setIsEditing(false)
          resetForm({
            values: {
              firstSessionDate: calculatedFirstSessionDate,
              lastSessionDate: calculatedLastSessionDate,
              dischargeSummaryText: dischargeSummary?.summary
            }
          })
        }}
      >
        Discard Changes
      </Button>
      <Button
        gap="8px"
        borderRadius="full"
        type="submit"
        isDisabled={!isValid}
        isLoading={isUpdating}
        w={{ base: '100%', md: 'auto' }}
      >
        Save
      </Button>
    </Flex>
  )
}

export default DischargeSummaryModal
