import React, { useState, useEffect, useRef } from 'react'
import { Hidden } from '@blueprinthq/joy'
import { useQuery } from 'react-query'
import { NavBarMobileView } from './mobile-view'
import { NavBarDesktopView } from './desktop-view'
import { usePermissions, useExperienceManager } from '../../hooks'
import { endpoints } from '@api'
import { PaywallBanner } from '@components'
import { Flex, Box, Text } from '@chakra-ui/react'
import {
  UnpaidBanner,
  PlusPlanFreeTrialEndingBanner,
  StayOnBlueprintOfferBanner
} from '@components'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { isMobile, isIOS } from 'react-device-detect'
import { ClinicianMobilePromoBanner } from '@containers'

export const NavBar = ({ focusMode }) => {
  const [contentMargin, setContentMargin] = useState(0)
  const navbarRef = useRef(null)
  const {
    paywallData,
    isPlusPlanFreeTrialBannerEnabled
  } = useExperienceManager()

  const [hideClinicianMobilePromo, setHideClinicianMobilePromo] = useState(
    false
  )

  const { data: user } = useQuery(
    endpoints.getUserAccount.getCacheId(),
    endpoints.getUserAccount.request
  )

  const { data: invoices, isFetching: isInvoicesLoading } = useQuery(
    [
      endpoints.getClinicFailedInvoices.getCacheId(),
      user?.clinic_id // This ensures the query key changes when the user or clinic_id changes
    ],
    () =>
      endpoints.getClinicFailedInvoices.request({
        clinicId: user.clinic_id
      }),
    {
      initialData: [],
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: !!user && !!user.clinic_id, // This query runs only if user and user.clinic_id are truthy
      onSuccess(data) {
        // Handle success
        return data
      }
    }
  )

  const showOwesBanner =
    !isInvoicesLoading &&
    invoices.invoices &&
    parseInt(invoices.invoices.totalAmountOwed, 10) > 0

  const { hasPayingSubscription, isTrialing } = paywallData
  const displayPaywallBanner =
    Object.keys(paywallData).length === 0 ||
    (hasPayingSubscription && isTrialing)
      ? true
      : false

  const { hasPermission } = usePermissions()

  useEffect(() => {
    const updateMargin = () => {
      const navbarHeight = navbarRef.current?.offsetHeight || 0
      setContentMargin(navbarHeight)
    }

    updateMargin()
    window.addEventListener('resize', updateMargin)
    return () => window.removeEventListener('resize', updateMargin)
  }, [
    navbarRef?.current?.offsetHeight,
    displayPaywallBanner,
    showOwesBanner,
    isPlusPlanFreeTrialBannerEnabled
  ])

  const showClinicianMobilePromo =
    !hideClinicianMobilePromo &&
    isMobile &&
    isIOS &&
    flagsmith.hasFeature(FlagsmithFeatures.CLINICIAN_MOBILE_PROMO)

  if (!user) return null

  const hasStayOnBlueprintOffer =
    !!user?.stayOnBlueprintOffer && !user.stayOnBlueprintOffer.acceptedAt

  return (
    <Box marginBottom={showClinicianMobilePromo ? '58px' : '0'}>
      {showClinicianMobilePromo && (
        <ClinicianMobilePromoBanner
          onClose={() => setHideClinicianMobilePromo(true)}
        />
      )}
      <Box
        w="100%"
        bg={hasPermission('*:any:*:*') ? '#282828' : 'primary'}
        position="fixed"
        top={showClinicianMobilePromo ? '46px' : '0'}
        right="0"
        left="auto"
        zIndex={5}
        ref={navbarRef}
      >
        {!isInvoicesLoading &&
          invoices.invoices &&
          showOwesBanner &&
          invoices.invoices.nextDueInvoice && (
            <UnpaidBanner
              user={user}
              adminContact={invoices.invoices.nextDueInvoice.customer_email}
            />
          )}

        {displayPaywallBanner && (
          <PaywallBanner user={user} paywallData={paywallData} />
        )}
        {isPlusPlanFreeTrialBannerEnabled && (
          <PlusPlanFreeTrialEndingBanner
            user={user}
            paywallData={paywallData}
          />
        )}
        {hasStayOnBlueprintOffer && <StayOnBlueprintOfferBanner user={user} />}
        <Flex align="center" pl="large" pr="large" h="80px">
          <Hidden sm>
            <NavBarDesktopView
              paywallData={paywallData}
              user={user}
              focusMode={focusMode}
            />
          </Hidden>
          <Hidden md lg xl>
            <NavBarMobileView
              paywallData={paywallData}
              user={user}
              focusMode={focusMode}
            />
          </Hidden>
        </Flex>
      </Box>
      <Box h={`${contentMargin}px`} position="relative" />
    </Box>
  )
}
