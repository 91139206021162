import React, { useState, useMemo, useRef } from 'react'
import {
  Textarea,
  Text,
  Flex,
  Button,
  useToast,
  Box,
  Link,
  VStack,
  Container
} from '@chakra-ui/react'
import { copyToClipboard } from '@utilities'
import * as clinicianTracking from '../../../lib/clinician-tracking'
import { endpoints } from '@api'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { RadioButtonLoading } from '@components/icons'
import { TrashIcon, CopyNoteIcon } from '@icons'
import { debounce } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import { useExperienceManager, useWidget } from '@hooks'
import { DeleteConfirmation } from '@components'
import { StoreModel } from 'src/store/types'

const LoadingBlock = ({ audience }: { audience: string }) => {
  return (
    <Flex
      p="48px 32px"
      flexDirection="column"
      border="1px solid"
      borderColor="pale_gray"
      borderRadius="8px"
      flex="1"
    >
      <Text textAlign="center" fontWeight="bold" mb="xxsmall">
        {`Generating ${audience} summary...`}
      </Text>
      <Text textAlign="center" color="#757575">
        This process could take up to 3 minutes
      </Text>
      <Box h="40px" />
      <Flex flexDirection="column" alignSelf="center" gap="16px">
        <Flex>
          <RadioButtonLoading />
          <Box w="8px" />
          <Text>Generating summary</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

const SummaryConfig = [
  {
    title: 'Clinician summary',
    key: 'clinicianSummary',
    pendoId: 'pendo-copy-clinician-summary'
  },
  {
    title: 'Client summary',
    key: 'clientSummary',
    pendoId: 'pendo-copy-client-summary'
  }
]

type Props = {
  trackEvent: (event: string, properties?: any) => void
  isSessionTab: boolean
}

const Summary = ({
  title,
  summary,
  sessionId,
  id,
  audience,
  isLoading,
  trackEvent,
  isSessionTab
}: any) => {
  const toast = useToast()
  const [localSummary, setLocalSummary] = useState('')
  const queryClient = useQueryClient()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(
    false
  )

  const resizeTextArea = () => {
    if (textAreaRef.current && isSessionTab) {
      if (!isSessionTab) {
        clearTimeout(setTimeout(resizeTextArea, 100))
        return
      }
      if (textAreaRef.current.scrollHeight === 0) {
        setTimeout(resizeTextArea, 100)
        return
      }
      // Clear the timeout using the correct overload
      clearTimeout(setTimeout(resizeTextArea, 100))
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
    }
  }

  React.useEffect(() => {
    if (isLoading) {
      return
    }

    setLocalSummary(summary || '')
  }, [isLoading, summary])

  React.useEffect(() => {
    resizeTextArea()
  }, [localSummary, summary, isSessionTab])

  const { mutate: deleteSummary, isLoading: isDeletingSummary } = useMutation(
    endpoints.deleteSessionSummary.request,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          endpoints.getSessionSummaries.getCacheId(),
          sessionId
        ])
        queryClient.setQueryData(
          [endpoints.getSessionSummaries.getCacheId(), sessionId],
          (oldData: any) => ({
            ...oldData,
            [`${audience}Summary`]: null
          })
        )
        toast({
          description: 'Summary removed!',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  const { mutate: updateSummary } = useMutation(
    endpoints.updateSessionSummary.request,
    {
      onSuccess: () => {
        const toastId = 'summary-updated'

        if (toast.isActive(toastId)) {
          return
        }

        toast({
          id: toastId,
          description: 'Summary updated!',
          status: 'success',
          isClosable: true,
          duration: 2000
        })
      },
      onError: () => {
        toast({
          description: 'Failed to update summary',
          status: 'error',
          isClosable: true,
          duration: 2000
        })
      }
    }
  )

  const _throttledUpdateSummary = useMemo(
    () => debounce(updateSummary, 500, { trailing: true }),
    []
  )

  const onSummaryUpdate = (val: string) => {
    setLocalSummary(val)

    _throttledUpdateSummary({
      data: {
        summary: val
      },
      id
    })
  }

  const handleCopy = () => {
    copyToClipboard(summary)
    toast({
      title: 'Copied to clipboard!',
      status: 'success',
      duration: 1500
    })

    clinicianTracking.trackEvent('Copied Session Summary', {
      audience
    })

    trackEvent(`${audience}_session_summary_copy_clicked`)
  }

  return (
    <Box pb="24px">
      <Flex justifyContent="space-between" mb="xsmall" flex="1">
        <Text as="b" alignSelf="center">
          {title}
        </Text>
        {!isLoading && (
          <Flex gap="16px">
            <Button
              variant="ghost"
              isDisabled={false}
              borderRadius="24px"
              px="0px"
              onClick={() => setIsDeleteConfirmationOpen(true)}
              _focus={{ outline: 'none' }}
              _hover={{ opacity: 0.7 }}
              flex="1"
              m={0}
              p={0}
            >
              <TrashIcon fill="#EB5164" width="16" height="16" />
              <Box width="6px" />
              Delete
            </Button>
            <Button
              variant="ghost"
              isDisabled={false}
              borderRadius="24px"
              px="0px"
              onClick={handleCopy}
              _focus={{ outline: 'none' }}
              _hover={{ opacity: 0.7 }}
              flex="1"
              m={0}
              p={0}
            >
              <CopyNoteIcon fill="black" />
              <Box width="4px" />
              Copy
            </Button>
          </Flex>
        )}
      </Flex>
      {isLoading ? (
        <LoadingBlock audience={audience} />
      ) : (
        <Textarea
          ref={textAreaRef}
          p="16px"
          whiteSpace="pre-line"
          lineHeight="26px"
          borderColor="pale_gray"
          overflow="hidden"
          borderRadius="8px"
          onChange={e => onSummaryUpdate(e.target.value)}
          value={localSummary}
        />
      )}
      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        isConfirming={isDeletingSummary}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={() => deleteSummary({ id })}
        header="Delete this summary?"
        text={`This session's ${audience} summary will be deleted.`}
        confirmText="Delete summary"
      />
    </Box>
  )
}

export const SessionSummary = ({ trackEvent, isSessionTab }: Props) => {
  const { sessionId }: { sessionId: string } = useParams()
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const isSessionSummariesEnabled =
    user?.clinic?.organization?.is_session_summaries_enabled
  const { isAdmin, isExtension, isWidget } = useExperienceManager()
  const { settings } = useWidget()

  const { data, isLoading }: any = useQuery(
    [endpoints.getSessionSummaries.getCacheId(), sessionId],
    () => endpoints.getSessionSummaries.request({ sessionId }),
    {
      refetchInterval: (data: any) => {
        const shouldRefetch = SummaryConfig.some(
          ({ key }) => data?.[key]?.isLoading
        )

        if (shouldRefetch) {
          return 5000
        }

        return false
      }
    }
  )

  if (isLoading) {
    return null
  }

  const allValuesAreNull = Object.values(data).every(value => value === null)

  if (isSessionSummariesEnabled && allValuesAreNull) {
    return (
      <Flex
        border="1px solid"
        height="200px"
        borderColor="pale_gray"
        borderRadius="8px"
        justifyContent="center"
        alignItems="center"
        color="#757575"
        my="32px"
        mx={{
          base: '16px',
          sm: '16px',
          md: '32px'
        }}
      >
        <Text>No summaries available</Text>
      </Flex>
    )
  }

  return (
    <Container
      height={
        isExtension || isWidget ? 'calc(100vh - 131px)' : `calc(100vh - 140px)`
      }
      overflowY="scroll"
    >
      <Flex flexDirection="column" py="16px" flex="1">
        {isSessionSummariesEnabled ? (
          SummaryConfig.map(({ title, key }) => {
            if (!data?.[key]) {
              return null
            }

            return (
              <Summary
                key={`summary-config-${key}`}
                isSessionTab={isSessionTab}
                title={
                  key === 'clientSummary'
                    ? `${settings?.patientReference || 'Client'} summary`
                    : title
                }
                trackEvent={trackEvent}
                {...data?.[key]}
              />
            )
          })
        ) : (
          <Flex
            p="16px"
            border="1px solid"
            height="200px"
            borderColor="pale_gray"
            borderRadius="8px"
            justifyContent="center"
            alignItems="center"
            color="#757575"
          >
            {isAdmin ? (
              <VStack spacing={0} gap="4px" align="center">
                <Text textAlign="center">
                  Session summaries are turned off.
                </Text>
                <Link
                  color="primary"
                  as={RouterLink}
                  to={{ pathname: '/settings/progress-notes' }}
                >
                  Manage settings
                </Link>
              </VStack>
            ) : (
              <Text>Contact your admin to enable session summaries</Text>
            )}
          </Flex>
        )}
      </Flex>
    </Container>
  )
}
