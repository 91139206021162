import React, { useState } from 'react'
import {
  Button,
  IconButton,
  HStack,
  Text,
  Stack,
  Flex,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
  Icon
} from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import { TreatmentPlan, DischargeSummary } from '..'
import { endpoints } from '@api'
import { useQuery, useQueryClient } from 'react-query'
import AssistPanel from '../assist-panel'
import { ArrowLeftIcon } from '@blueprinthq/joy'
import { useStoreState } from 'easy-peasy'
import { useTreatmentPlan } from '@hooks'
import { TreatmentPlanProvider } from '@context'
import SuggestionStrength from '../assist-panel/suggestion-strength'
import {
  useSessionControllerPostContentSuggestions,
  getSessionControllerGetSuggestedContentQueryKey
} from '~/clinician-api'
import AssistOverlay from '@handlers/sessions/components/shared/assist/overlay'

const Sidebar = ({
  client,
  isNoteNull,
  sessionId,
  assistOverlayOpen,
  toggleAssistOverlay,
  account,
  session,
  tabIndex,
  onTabChange,
  isTxPlanModalOpen,
  setIsTxPlanModalOpen
}: {
  client: any,
  isNoteNull: boolean,
  sessionId: string,
  assistOverlayOpen: boolean,
  toggleAssistOverlay: () => void,
  account: any,
  session: any,
  tabIndex: number,
  onTabChange: (index: number) => void,
  isTxPlanModalOpen: boolean,
  setIsTxPlanModalOpen: (isOpen: boolean) => void
}) => {
  const clientId = client.id
  const queryClient = useQueryClient()

  const {
    isDocumentationAutomationEnabled,
    isPlusPlanEnabled,
    isEvidenceBasedCareEnabled,
    showRulaUI,
    showPartnerUI,
    isDischargeSummaryEnabled
  } = useExperienceManager()

  const {
    data: treatmentPlanData,
    isLoading: isTreatmentPlanLoading
  } = useQuery([endpoints.getClientTreatmentPlan.getCacheId(), clientId], () =>
    endpoints.getClientTreatmentPlan.request({ clientId })
  )

  const showTxPlanIndicator =
    !isTreatmentPlanLoading &&
    !(treatmentPlanData as any)?.treatmentPlan?.isLoading &&
    (treatmentPlanData as any)?.treatmentPlan &&
    !(treatmentPlanData as any)?.treatmentPlan?.acceptedAt

  return (
    <Flex
      display={{
        base: 'none',
        sm: 'none',
        md: 'block'
      }}
      height="100%"
      borderLeft="1px solid"
      borderColor="pale_gray"
      width="384px"
      maxW="384px"
    >
      {isDocumentationAutomationEnabled &&
        !isNoteNull &&
        !assistOverlayOpen &&
        !showRulaUI &&
        !showPartnerUI && (
          <Flex justifyContent="center" flexDirection="column">
            <Tabs
              h="59px"
              pt="16px"
              index={tabIndex}
              onChange={onTabChange}
            >
              <TabList
                sx={{
                  display: 'flex',
                  textWrap: 'nowrap'
                }}
                gap="32px"
                borderBottom="none"
                overflowX="auto"
                overflowY="hidden"
                px="32px"
              >
                {[
                  {
                    name: isDischargeSummaryEnabled
                      ? 'Tx Plan & Discharge'
                      : 'Treatment Plan',
                    indicator: showTxPlanIndicator
                  },
                  ...(!isEvidenceBasedCareEnabled || isPlusPlanEnabled
                    ? [{ name: 'Assist' }]
                    : [])
                ].map((tab, index) => (
                  <Tab
                    key={tab.name}
                    justifyContent="flex-start"
                    px="0"
                    color="dark_gray"
                    fontWeight="normal"
                    borderBottom="6px solid"
                    whiteSpace="nowrap"
                    _focus={{ outline: 'none' }}
                    _selected={{
                      color: '#282828',
                      borderBottomColor: 'primary'
                    }}
                    position="relative"
                  >
                    <Box
                      h="8px"
                      w="8px"
                      bg="primary"
                      borderRadius="50%"
                      position="absolute"
                      top="5px"
                      right="-4px"
                      display={tab.indicator ? 'block' : 'none'}
                    />
                    {tab.name}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                <TabPanel p="32px">
                  <TreatmentPlan
                    containerProps={{ spacing: 4 }} 
                    client={client} 
                    isModalOpenProp={isTxPlanModalOpen} 
                    setIsModalOpen={setIsTxPlanModalOpen} 
                  />
                  {isDischargeSummaryEnabled && (
                    <>
                      <Box h="32px" />
                      <DischargeSummary client={client} />
                    </>
                  )}
                </TabPanel>
                <TabPanel
                  p="32px"
                  height="calc(100vh - 140px)"
                  overflowY="scroll"
                >
                  <TreatmentPlanProvider client={client}>
                    <AssistPanel
                      toggleOverlay={toggleAssistOverlay}
                      sessionId={sessionId}
                      client={client}
                      account={account}
                      session={session}
                    />
                  </TreatmentPlanProvider>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Divider color="#E4E5E6" />
          </Flex>
        )}
      {assistOverlayOpen && !showRulaUI && !showPartnerUI && (
        <TreatmentPlanProvider client={client} toastsEnabled={false}>
          <AssistOverlay
            client={client}
            session={session}
            account={account}
            toggleAssistOverlay={toggleAssistOverlay}
          />
        </TreatmentPlanProvider>
      )}
    </Flex>
  )
}

export default Sidebar
