import React from 'react'
import { Flex, Box, Image, Button } from '@chakra-ui/react'
import { ArrowLeftIcon, ArrowRightIcon } from '@blueprinthq/joy'
import BlueprintLogo from '../../../images/brand/blueprint_b_logo.svg'
import BlueprintLogoFull from '../../../images/brand/blue_logo.svg'

const MobileNav = ({
  onBack,
  onSkip
}: {
  onBack: (() => void) | null
  onSkip: (() => void) | null
}) => (
  <Flex
    display={{ base: 'flex', md: 'none' }}
    justify={{ base: 'center', md: 'flex-start' }}
    align="center"
    pt={3}
    pb={2}
    px={6}
    position="relative"
    h="52px"
  >
    {onBack && (
      <Button
        variant="ghost"
        color="primary"
        position="absolute"
        top="5px"
        left="5px"
        display={{ base: 'flex', md: 'none' }}
        leftIcon={<ArrowLeftIcon fill="primary" />}
        onClick={onBack}
        _focus={{
          boxShadow: 'none'
        }}
      >
        Back
      </Button>
    )}
    <Image
      loading="eager"
      src={BlueprintLogo}
      cursor="pointer"
      display={{ base: 'block', md: 'none' }}
    />
    {onSkip && (
      <Button
        variant="ghost"
        color="primary"
        position="absolute"
        top="5px"
        right="5px"
        display={{ base: 'flex', md: 'none' }}
        rightIcon={<ArrowRightIcon fill="primary" />}
        onClick={onSkip}
        _focus={{
          boxShadow: 'none'
        }}
      >
        Skip
      </Button>
    )}
  </Flex>
)

const DesktopNav = ({
  onBack,
  onSkip
}: {
  onBack: (() => void) | null
  onSkip: (() => void) | null
}) => (
  <Box display={{ base: 'none', md: 'block' }} h="47px">
    <Box pl={1} py={2} pr={4}>
      <Image
        loading="eager"
        src={BlueprintLogoFull}
        cursor="pointer"
        display={{ base: 'none', md: 'block' }}
      />
    </Box>
    {onBack && (
      <Box pt={3} pb={2} px={0}>
        <Button
          pl={0}
          m={0}
          variant="ghost"
          color="primary"
          leftIcon={<ArrowLeftIcon fill="primary" mt="2px" />}
          onClick={onBack}
          _focus={{
            boxShadow: 'none'
          }}
        >
          Back
        </Button>
      </Box>
    )}
    {onSkip && (
      <Box pt={3} pb={2} px={0} display="flex" justifyContent="flex-end">
        <Button
          pl={0}
          m={0}
          variant="ghost"
          color="primary"
          rightIcon={<ArrowRightIcon fill="primary" mt="2px" />}
          onClick={onSkip}
          _focus={{
            boxShadow: 'none'
          }}
        >
          Skip
        </Button>
      </Box>
    )}
  </Box>
)
export const Navbar = ({
  onBack,
  onSkip
}: {
  onBack: (() => void) | null
  onSkip: (() => void) | null
}) => {
  return (
    <>
      <MobileNav onBack={onBack} onSkip={onSkip} />
      <DesktopNav onBack={onBack} onSkip={onSkip} />
    </>
  )
}
