import { Dict } from '@chakra-ui/utils'
import mixpanel from 'mixpanel-browser'

export function initTracking() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
    track_pageview: 'full-url',
    persistence: 'localStorage'
  })
}

export function resetIdentity() {
  mixpanel.reset()
}

export function identify(clinicianId: string) {
  mixpanel.identify(clinicianId)
}

export function registerProperties(properties: Dict) {
  mixpanel.register(properties)
}

export function setUserProperties(properties: object) {
  mixpanel.people.set(properties)
}

export function trackEvent(event: string, payload?: object) {
  const platform = window.location.pathname.includes('/extension')
    ? 'extension'
    : window.location.pathname.includes('widget')
    ? 'widget'
    : 'web'
  mixpanel.track(event, { ...payload, sessionPlatform: platform })
}
