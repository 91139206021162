import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Box, Text } from '@chakra-ui/react'

import { KeyboardDatePicker as MuiDatePicker } from '@material-ui/pickers'
import CalendarToday from '@material-ui/icons/CalendarToday'

interface DatePickerProps {
  value: string
  onChange: (date: string | null) => void
  shouldDisableDate?: (date: any) => boolean
  onOpen?: () => void
  onClose?: () => void
  errorText?: string | undefined
}

export const DatePicker = ({
  value,
  onChange,
  shouldDisableDate,
  onOpen,
  onClose,
  errorText
}: DatePickerProps) => {
  const useDatePickerStyles = makeStyles(() => ({
    notchedOutline: {
      borderColor: errorText ? 'red' : 'pale_gray'
    }
  }))
  const inputClasses = useDatePickerStyles()

  const handleChange = (moment: any) => {
    if (!moment) {
      onChange(null)
      return
    }

    onChange(moment.format('YYYY-MM-DD'))
  }

  return (
    <Box>
      <MuiDatePicker
        onOpen={onOpen}
        onClose={onClose}
        disableToolbar
        inputVariant="outlined"
        variant="inline"
        value={value}
        onChange={handleChange}
        onAccept={handleChange}
        format="MM/DD/YYYY"
        shouldDisableDate={shouldDisableDate}
        disableFuture
        autoOk
        size="small"
        helperText=""
        keyboardIcon={
          <CalendarToday style={{ color: 'black', width: 18, height: 18 }} />
        }
        KeyboardButtonProps={{
          disableRipple: true,
          disableFocusRipple: true,
          size: 'small'
        }}
        InputProps={{
          classes: inputClasses,
          error: false,
        }}
        PopoverProps={{
          disablePortal: true
        }}
      />
      {errorText ? <Text fontSize="12px" color="error">{errorText}</Text> : null}
    </Box>
  )
}