import React from 'react'
import { Flex, Text, Button, BoxProps } from '@chakra-ui/react'
import { MicAIOn, PersonDictate } from '@components/icons'

const RecordIcon = () => <MicAIOn width="24px" height="24px" fill="#2D54E8" />

const DictateIcon = () => (
  <PersonDictate width="24px" height="24px" fill="#2D54E8" />
)

export const GetStartedOption = ({
  isDictation = false,
  title,
  onClick,
  ...boxProps
}: {
  isDictation?: boolean
  onClick: () => void
  isHorizontal?: boolean
} & BoxProps) => {
  return (
    <Flex
      borderRadius="12px"
      backgroundColor="white"
      onClick={onClick}
      flexDirection="column"
      justifyContent="space-between"
      border="1px solid rgba(55, 60, 65, 0.1375)"
      transition="border-color 0.3s ease-in-out"
      _hover={{
        cursor: 'pointer',
        border: '1px solid',
        borderColor: 'primary',
        
      }}
      w="100%"
      padding="24px 16px"
      {...boxProps}
    >
      <Flex
        flexDirection={{ base: 'row', md: 'column' }}
        justifyContent="flex-start"
        alignItems="center"
        rowGap={{ base: 'xsmall', md: 'small' }}
        h="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          backgroundColor="#F6F7FE"
          borderRadius="999px"
          mr={{ base: '12px', md: 0 }}
          w={{ base: '40px', md: '48px' }}
          minW={{ base: '40px', md: '48px' }}
          minH={{ base: '40px', md: '48px' }}
        >
          {isDictation ? <DictateIcon /> : <RecordIcon />}
        </Flex>
        <Flex
          flexDirection="column"
          alignItems={{ base: 'flex-start', md: 'center' }}
        >
          <Text fontSize={{ base: '16px', md: '24px' }} as="b" mb={{ base: 0, md: 2 }}>
            {isDictation ? 'Dictate Notes' : 'Record Audio'}
          </Text>
          <Text>
            {isDictation
              ? 'Turn client visits into notes'
              : 'Use during live sessions'}
          </Text>
        </Flex>
      </Flex>
      {isDictation ? (
        <Button
          isFullWidth
          size="lg"
          display={{ base: 'none', md: 'block' }}
          borderColor="rgba(55, 60, 65, 0.1375)"
          variant="outline"
          _hover={{
            bg: 'rgba(45, 84, 232, 0.04)'
          }}
        >
          Dictate
        </Button>
      ) : (
        <Button isFullWidth size="lg" display={{ base: 'none', md: 'block' }}>
          Record
        </Button>
      )}
    </Flex>
  )
}
