import React, { useMemo } from 'react'
import { AddCircleIcon, CheckCircle } from '@blueprinthq/joy'
import {
  Box,
  Flex,
  Input,
  Text,
  HStack,
  Link,
  Stack,
  Divider,
  Button,
  FlexProps
} from '@chakra-ui/react'
import { AssessmentProvider, useAssessmentLibraryContext } from './context'

const AssessmentRow = ({
  assessmentId,
  clinicAssessmentId,
  title,
  subtitle,
  previewUrl,
  isSelected,
  onSelect,
  onAssign,
  isAssigned,
  isLast
}: any) => {
  const icon = useMemo(() => {
    let i = (
      <AddCircleIcon
        onClick={() => onSelect(clinicAssessmentId)}
        role="button"
        aria-label="Select Assessment"
        tabIndex={0}
        cursor="pointer"
        fill="primary"
        sx={{ marginLeft: '0' }}
      />
    )

    if (isAssigned) {
      i = <CheckCircle w="24px" h="24px" fill="#E4E5E6" />
    } else if (isSelected) {
      i = (
        <CheckCircle
          onClick={() => onSelect(clinicAssessmentId)}
          w="24px"
          h="24px"
          role="button"
          aria-label="Deselect Assessment"
          tabIndex={0}
          cursor="pointer"
          fill="primary"
          sx={{ marginLeft: '0' }}
        />
      )
    }

    return i
  }, [assessmentId, isAssigned, isSelected, onSelect])

  return (
    <Stack spacing="0">
      <HStack py="12px" justifyContent="space-between" gap="24px">
        <Stack spacing="4px">
          <Text fontSize="14px">{title}</Text>
          <Text fontSize="12px" color="dark_gray">{subtitle}</Text>
        </Stack>
        <Stack
          gap="12px"
          spacing="0"
          direction={{ base: 'column', sm: 'row' }}
          align={{ base: 'center', sm: 'center' }}
        >
          <Button
            isDisabled={isAssigned}
            textDecoration="none"
            borderRadius="8px"
            size="sm"
            _focus={{ outline: 'none' }}
            onClick={onAssign}
            w="110px"
            order={{ base: 1, sm: 2 }}
          >
            {isAssigned ? 'Assigned' : 'Assign'}
          </Button>
          <Link
            href={previewUrl}
            target="_blank"
            color="primary"
            textDecoration="none"
            fontSize="14px"
            _focus={{ outline: 'none' }}
            mr={{ base: 0, sm: 4 }}
            order={{ base: 2, sm: 1 }}
          >
            Preview
          </Link>
        </Stack>
      </HStack>
      {!isLast && <Divider m="0" />}
    </Stack>
  )
}

interface LibraryProps {
  onAssign: (assessment: any) => void
  searchContainerProps?: FlexProps
}

const Library = ({ onAssign, searchContainerProps = {} }: LibraryProps) => {
  const {
    filteredAssessments,
    setSearchText,
    searchText,
  } = useAssessmentLibraryContext()

  const handleAssign = async (assessment: any) => {
    onAssign(assessment)
  }

  return (
    <Box flex="1" overflowY="hidden">
      <Flex
        h="100px"
        align="center"
        flex="1"
        borderBottom="1px solid"
        borderColor="pale_gray"
        p="24px"
        {...searchContainerProps}
      >
        <Input
          placeholder="Search assessments"
          onChange={e => setSearchText(e.currentTarget.value)}
          value={searchText}
          style={{
            height: '58px',
          }}
        />
      </Flex>
      <Stack px="24px" py={4} h="calc(100vh - 190px)" overflowY="scroll" spacing="0">
        {filteredAssessments.map((a, index) => (
          <AssessmentRow
            {...a}
            onAssign={() => handleAssign(a)}
            isLast={index === filteredAssessments.length - 1}
          />
        ))}
      </Stack>
    </Box>
  )
}

const WrappedLibrary = ({ client, onAssign, searchContainerProps }: LibraryProps & { client: any }) => {
  return (
    <AssessmentProvider client={client}>
      <Library onAssign={onAssign} searchContainerProps={searchContainerProps} />
    </AssessmentProvider>
  )
}

export default WrappedLibrary
