import React, { useCallback, useRef, useState } from 'react'
import { MenuIcon, SearchIcon, IconButton, Button } from '@blueprinthq/joy'
import { Box, Flex, useMediaQuery, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import WhiteLogo from '../../images/brand/white_logo.svg'
import { SearchInput, MenuModal } from './components'
import { readClients } from '../../constants/permissions'
import { usePermissions, useExperienceManager } from '../../hooks'
import NotificationBadge from './components/notification-badge'

export const NavBarMobileView = ({ focusMode, paywallData }) => {
  const [isBase] = useMediaQuery(['(max-width: 600px)'])
  const searchInputRef = useRef(null)

  const [searchQuery, setSearchQuery] = useState('')
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isSearchOpen, setSearchOpen] = useState(false)
  const { hasPermission } = usePermissions()
  const { isEvidenceBasedCareEnabled } = useExperienceManager()

  const toggleMenuView = useCallback(() => {
    const nextMenuOpen = !isMenuOpen
    if (nextMenuOpen) {
      document.querySelector('body').style.overflowY = 'hidden'
    } else {
      document.querySelector('body').style.overflowY = ''
    }
    setMenuOpen(nextMenuOpen)
  }, [isMenuOpen])
  const toggleSearchView = useCallback(() => {
    const nextSearchOpen = !isSearchOpen
    setSearchOpen(nextSearchOpen)
    if (nextSearchOpen) {
      setSearchQuery('')
      searchInputRef.current.focus()
      // Makes body of page unscrollable for mobile
      document.querySelector('body').style.overflowY = 'hidden'
      document.querySelector('body').style.position = 'fixed'
    } else {
      document.querySelector('body').style.overflowY = ''
      document.querySelector('body').style.position = ''
    }
  }, [isSearchOpen])

  const handleModalSearch = useCallback(() => {
    toggleSearchView()
    toggleMenuView()

    // Wait for view to mount
    setTimeout(() => {
      searchInputRef.current.focus()
    }, 250)
  }, [toggleSearchView, toggleMenuView])

  const isClinical = readClients.every(perm => hasPermission(perm))

  if (focusMode) return <Image loading="eager" src={WhiteLogo} />

  return (
    <>
      {isClinical && (
        <>
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              opacity: isSearchOpen ? 1 : 0,
              pointerEvents: isSearchOpen ? 'all' : 'none',
              zIndex: 2,
              transition: 'opacity 220ms linear, width 120ms linear',
              ml: '-4px',
              left: 0,
              right: 0
            }}
            pl="medium"
            pr="medium"
          >
            <SearchInput
              isMobile
              matchWidth={false}
              keepSuggestionsOpen
              suggestionsOffset={isBase ? 4 : 12}
              ref={searchInputRef}
              value={searchQuery}
              onClear={() => {
                setSearchQuery('')
                searchInputRef.current.focus()
              }}
              popoverContentSx={{
                width: '100vw'
              }}
              listContainerSx={{
                height: '100vh',
                maxHeight: 'auto'
              }}
              clearColor={'white'}
              onSearchClose={toggleSearchView}
              onChange={value => setSearchQuery(value)}
              sx={{
                '::placeholder': {
                  color: 'white',
                  opacity: 0.5
                },
                bg: 'transparent',
                color: 'white',
                caretColor: 'white'
              }}
            />
          </Box>
        </>
      )}
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'absolute',
          overflow: 'hidden',
          opacity: !isSearchOpen ? 1 : 0,
          zIndex: 1,
          transition: 'all 300ms ease-out',
          width: '100%',
          left: 0,
          right: 0
        }}
        pl="medium"
        pr="medium"
      >
        {isClinical && (
          <IconButton
            onClick={toggleSearchView}
            icon={<SearchIcon fill="white" />}
            variant={'ghost'}
          />
        )}
        <Link to={isClinical ? '/dashboard' : '/reports/claims'}>
          <img
            src={WhiteLogo}
            style={{
              objectFit: 'contain'
            }}
          />
        </Link>
        <Button
          borderRadius="full"
          padding={0}
          onClick={toggleMenuView}
          variant={'ghost'}
        >
          <MenuIcon fill="white" />
          {isEvidenceBasedCareEnabled && <NotificationBadge isMobile />}
        </Button>
      </Flex>
      <MenuModal
        open={isMenuOpen}
        onClose={toggleMenuView}
        onClickSearch={handleModalSearch}
        isClinical={isClinical}
        paywallData={paywallData}
      />
    </>
  )
}
