import React, { createContext, useState, useEffect, FC, useMemo } from 'react'
import { useStoreActions } from 'easy-peasy'

interface WidgetContextType {
  settings: {
    isDraggable?: boolean
    isAlwaysOpen?: boolean
    copyNoteButtonText?: string
    patientReference?: string
    hideTreatmentPlan?: string
    hidePreviousSessions?: string
  }
}

export const WidgetContext = createContext<WidgetContextType>(
  {} as WidgetContextType
)

interface WidgetProviderProps {
  children: React.ReactNode
}

export const WidgetProvider: FC<WidgetProviderProps> = ({ children }) => {
  const [settings, setSettings] = useState({})
  const widgetLogin = useStoreActions(
    (actions: any) => actions.auth.widgetLogin
  )

  const contextValue = useMemo(() => ({ settings }), [settings])

  const forceAuthenticate = async (credentials: {
    authTokens: object
    username: string
  }) => {
    await widgetLogin(credentials)
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.type === 'BP_SETTINGS') {
        setSettings(event.data.settings)
      }

      if (event.data?.type === 'BP_AUTHENTICATE') {
        forceAuthenticate({
          authTokens: event.data.authTokens,
          username: event.data.username
        })
      }
    }

    window.addEventListener('message', handleMessage)

    // eslint-disable-next-line
    parent.postMessage({ type: 'BP_EXTENSION_CONTEXT_LOADED' }, '*')
    // eslint-disable-next-line
    parent.postMessage({ type: 'BP_WIDGET_CONTEXT_LOADED' }, '*')

    return () => window.removeEventListener('message', handleMessage)
  }, [])

  return (
    <WidgetContext.Provider
      value={contextValue}
    >
      {children}
    </WidgetContext.Provider>
  )
}
