/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Blueprint: Internal API
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ClientListDto,
  ClinicControllerGetClientListParams,
  CheckInDtoV2,
  ClinicControllerGetCheckInsParams,
  ClinicControllerGetAssignedCheckInsParams,
  AssignCheckInBodyDto,
  PaymentMethodDto,
  PlanOptionDto,
  CurrentPlanResponseDto,
  ChangePlanPreviewResponseDto,
  ClinicControllerChangePlanPreviewParams,
  ChangePlanBodyDto,
  CancelPlusPlanFreeTrialDto,
  ClinicSearchResultListDto,
  ClinicControllerSearchClinicsParams,
  SessionListResponseDto,
  SessionControllerGetSessionsListParams,
  SessionDraftDto,
  SessionCreateBodyDto,
  CompletedSessionDto,
  SessionPatchDto,
  SessionProgressNotesDto,
  PatchNoteDto,
  StartInterventionsBodyDto,
  PatientInterventionWithInterventionDto,
  SessionPsychotherapyNoteBodyDto,
  SessionRecapResponse,
  TrackEventDto,
  SessionPlannedInterventionsBodyDto,
  SampleSessionDto,
  CreateSampleSessionDto,
  StartUploadDto,
  CompleteUploadDto,
  MarkSessionCompleteBodyDto,
  SessionFromUploadedAudioBodyDto,
  SuggestedContentDTO,
  SessionControllerGetDemoSuggestedContentParams,
  SessionPrepDto,
  SessionControllerGetSessionPrepParams,
  PatientDto,
  PatientControllerGetPatientsParams,
  AssistEnrollDto,
  SessionDto,
  PatientCheckInScoresDto,
  PatientConsentDto,
  PatientControllerGetConsentParams,
  AssignCheckInDto,
  UpdateCheckInNotificationDto,
  AssignedCheckInDto,
  SaveForLaterBodyDto,
  SavedForLaterInterventionDto,
  PatientInterventionDto,
  PatientInterventionsControllerGetAllPatientInterventionsParams,
  PostPatientInterventionDto,
  PatientInterventionWithResponsesDto,
  PutPatientInterventionDto,
  DischargeSummaryDto,
  PatchDischargeSummaryDto,
  CheckinsControllerV1GetAllCheckInsParams,
  CheckInBodyDto,
  Intervention,
  InterventionsControllerV1GetAllInterventionsParams,
  SubmitFeedbackDto,
  ConsentSettingDTO,
  PatchConsentSettingDTO,
  OutboundEhrSettingsDTO,
  GetExperimentDto,
  OnboardingControllerExperimentParams,
  ValidateEmailDto,
  OnboardingSurveyDto
} from './models'
import { hellojoyApiInstance, ErrorType } from '../../axios-instance'


export const clinicControllerGetClientList = (
    clinicId: string,
    params?: ClinicControllerGetClientListParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<ClientListDto>(
      {url: `/v1/clinician-api/clinic/${clinicId}/client-list`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getClinicControllerGetClientListQueryKey = (clinicId: string,
    params?: ClinicControllerGetClientListParams,) => [`/v1/clinician-api/clinic/${clinicId}/client-list`, ...(params ? [params]: [])];

    
export type ClinicControllerGetClientListQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetClientList>>>
export type ClinicControllerGetClientListQueryError = ErrorType<void>

export const useClinicControllerGetClientList = <TData = Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError = ErrorType<void>>(
 clinicId: string,
    params?: ClinicControllerGetClientListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerGetClientListQueryKey(clinicId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetClientList>>> = ({ signal }) => clinicControllerGetClientList(clinicId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerGetClientList>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const clinicControllerGetCheckIns = (
    clinicId: string,
    params?: ClinicControllerGetCheckInsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/check-ins`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getClinicControllerGetCheckInsQueryKey = (clinicId: string,
    params?: ClinicControllerGetCheckInsParams,) => [`/v1/clinician-api/clinic/${clinicId}/library/check-ins`, ...(params ? [params]: [])];

    
export type ClinicControllerGetCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>>
export type ClinicControllerGetCheckInsQueryError = ErrorType<void>

export const useClinicControllerGetCheckIns = <TData = Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError = ErrorType<void>>(
 clinicId: string,
    params?: ClinicControllerGetCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerGetCheckInsQueryKey(clinicId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>> = ({ signal }) => clinicControllerGetCheckIns(clinicId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerGetCheckIns>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const clinicControllerGetAssignedCheckIns = (
    clinicId: string,
    params?: ClinicControllerGetAssignedCheckInsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/check-ins/assigned`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getClinicControllerGetAssignedCheckInsQueryKey = (clinicId: string,
    params?: ClinicControllerGetAssignedCheckInsParams,) => [`/v1/clinician-api/clinic/${clinicId}/library/check-ins/assigned`, ...(params ? [params]: [])];

    
export type ClinicControllerGetAssignedCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>>
export type ClinicControllerGetAssignedCheckInsQueryError = ErrorType<void>

export const useClinicControllerGetAssignedCheckIns = <TData = Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError = ErrorType<void>>(
 clinicId: string,
    params?: ClinicControllerGetAssignedCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerGetAssignedCheckInsQueryKey(clinicId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>> = ({ signal }) => clinicControllerGetAssignedCheckIns(clinicId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerGetAssignedCheckIns>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const clinicControllerToggleCheckInDefault = (
    clinicId: string,
    checkInId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/check-ins/${checkInId}/toggle-default`, method: 'put'
    },
      );
    }
  


    export type ClinicControllerToggleCheckInDefaultMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>>
    
    export type ClinicControllerToggleCheckInDefaultMutationError = ErrorType<unknown>

    export const useClinicControllerToggleCheckInDefault = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>, TError,{clinicId: string;checkInId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>, {clinicId: string;checkInId: string}> = (props) => {
          const {clinicId,checkInId} = props ?? {};

          return  clinicControllerToggleCheckInDefault(clinicId,checkInId,)
        }

      return useMutation<Awaited<ReturnType<typeof clinicControllerToggleCheckInDefault>>, TError, {clinicId: string;checkInId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const clinicControllerAssignCheckIn = (
    clinicId: string,
    assignCheckInBodyDto: AssignCheckInBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/assign`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: assignCheckInBodyDto
    },
      );
    }
  


    export type ClinicControllerAssignCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>>
    export type ClinicControllerAssignCheckInMutationBody = AssignCheckInBodyDto
    export type ClinicControllerAssignCheckInMutationError = ErrorType<unknown>

    export const useClinicControllerAssignCheckIn = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>, TError,{clinicId: string;data: AssignCheckInBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>, {clinicId: string;data: AssignCheckInBodyDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerAssignCheckIn(clinicId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof clinicControllerAssignCheckIn>>, TError, {clinicId: string;data: AssignCheckInBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const clinicControllerUnassignCheckIn = (
    clinicId: string,
    assignCheckInBodyDto: AssignCheckInBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/library/unassign`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: assignCheckInBodyDto
    },
      );
    }
  


    export type ClinicControllerUnassignCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>>
    export type ClinicControllerUnassignCheckInMutationBody = AssignCheckInBodyDto
    export type ClinicControllerUnassignCheckInMutationError = ErrorType<unknown>

    export const useClinicControllerUnassignCheckIn = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>, TError,{clinicId: string;data: AssignCheckInBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>, {clinicId: string;data: AssignCheckInBodyDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerUnassignCheckIn(clinicId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof clinicControllerUnassignCheckIn>>, TError, {clinicId: string;data: AssignCheckInBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const clinicControllerGetPaymentMethods = (
    clinicId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PaymentMethodDto[]>(
      {url: `/v1/clinician-api/clinic/${clinicId}/payment-methods`, method: 'get', signal
    },
      );
    }
  

export const getClinicControllerGetPaymentMethodsQueryKey = (clinicId: string,) => [`/v1/clinician-api/clinic/${clinicId}/payment-methods`];

    
export type ClinicControllerGetPaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>>
export type ClinicControllerGetPaymentMethodsQueryError = ErrorType<void>

export const useClinicControllerGetPaymentMethods = <TData = Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>, TError = ErrorType<void>>(
 clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerGetPaymentMethodsQueryKey(clinicId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>> = ({ signal }) => clinicControllerGetPaymentMethods(clinicId, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerGetPaymentMethods>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const clinicControllerGetPlanOptions = (
    clinicId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PlanOptionDto[]>(
      {url: `/v1/clinician-api/clinic/${clinicId}/plan-options`, method: 'get', signal
    },
      );
    }
  

export const getClinicControllerGetPlanOptionsQueryKey = (clinicId: string,) => [`/v1/clinician-api/clinic/${clinicId}/plan-options`];

    
export type ClinicControllerGetPlanOptionsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>>
export type ClinicControllerGetPlanOptionsQueryError = ErrorType<void>

export const useClinicControllerGetPlanOptions = <TData = Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>, TError = ErrorType<void>>(
 clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerGetPlanOptionsQueryKey(clinicId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>> = ({ signal }) => clinicControllerGetPlanOptions(clinicId, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerGetPlanOptions>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const clinicControllerGetCurrentPlan = (
    clinicId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CurrentPlanResponseDto>(
      {url: `/v1/clinician-api/clinic/${clinicId}/current-plan`, method: 'get', signal
    },
      );
    }
  

export const getClinicControllerGetCurrentPlanQueryKey = (clinicId: string,) => [`/v1/clinician-api/clinic/${clinicId}/current-plan`];

    
export type ClinicControllerGetCurrentPlanQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>>
export type ClinicControllerGetCurrentPlanQueryError = ErrorType<void>

export const useClinicControllerGetCurrentPlan = <TData = Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>, TError = ErrorType<void>>(
 clinicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerGetCurrentPlanQueryKey(clinicId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>> = ({ signal }) => clinicControllerGetCurrentPlan(clinicId, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerGetCurrentPlan>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const clinicControllerChangePlanPreview = (
    clinicId: string,
    params?: ClinicControllerChangePlanPreviewParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<ChangePlanPreviewResponseDto>(
      {url: `/v1/clinician-api/clinic/${clinicId}/change-plan-preview`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getClinicControllerChangePlanPreviewQueryKey = (clinicId: string,
    params?: ClinicControllerChangePlanPreviewParams,) => [`/v1/clinician-api/clinic/${clinicId}/change-plan-preview`, ...(params ? [params]: [])];

    
export type ClinicControllerChangePlanPreviewQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>>
export type ClinicControllerChangePlanPreviewQueryError = ErrorType<void>

export const useClinicControllerChangePlanPreview = <TData = Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>, TError = ErrorType<void>>(
 clinicId: string,
    params?: ClinicControllerChangePlanPreviewParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerChangePlanPreviewQueryKey(clinicId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>> = ({ signal }) => clinicControllerChangePlanPreview(clinicId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerChangePlanPreview>>, TError, TData>(queryKey, queryFn, {enabled: !!(clinicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const clinicControllerChangePlan = (
    clinicId: string,
    changePlanBodyDto: ChangePlanBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/change-plan`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: changePlanBodyDto
    },
      );
    }
  


    export type ClinicControllerChangePlanMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerChangePlan>>>
    export type ClinicControllerChangePlanMutationBody = ChangePlanBodyDto
    export type ClinicControllerChangePlanMutationError = ErrorType<unknown>

    export const useClinicControllerChangePlan = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerChangePlan>>, TError,{clinicId: string;data: ChangePlanBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerChangePlan>>, {clinicId: string;data: ChangePlanBodyDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerChangePlan(clinicId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof clinicControllerChangePlan>>, TError, {clinicId: string;data: ChangePlanBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const clinicControllerCancelPlusPlanFreeTrial = (
    clinicId: string,
    cancelPlusPlanFreeTrialDto: CancelPlusPlanFreeTrialDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/cancel-plus-plan-free-trial`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: cancelPlusPlanFreeTrialDto
    },
      );
    }
  


    export type ClinicControllerCancelPlusPlanFreeTrialMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerCancelPlusPlanFreeTrial>>>
    export type ClinicControllerCancelPlusPlanFreeTrialMutationBody = CancelPlusPlanFreeTrialDto
    export type ClinicControllerCancelPlusPlanFreeTrialMutationError = ErrorType<unknown>

    export const useClinicControllerCancelPlusPlanFreeTrial = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerCancelPlusPlanFreeTrial>>, TError,{clinicId: string;data: CancelPlusPlanFreeTrialDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerCancelPlusPlanFreeTrial>>, {clinicId: string;data: CancelPlusPlanFreeTrialDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerCancelPlusPlanFreeTrial(clinicId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof clinicControllerCancelPlusPlanFreeTrial>>, TError, {clinicId: string;data: CancelPlusPlanFreeTrialDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const clinicControllerStartPlusPlanFreeTrial = (
    clinicId: string,
    cancelPlusPlanFreeTrialDto: CancelPlusPlanFreeTrialDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/clinic/${clinicId}/start-plus-plan-free-trial`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: cancelPlusPlanFreeTrialDto
    },
      );
    }
  


    export type ClinicControllerStartPlusPlanFreeTrialMutationResult = NonNullable<Awaited<ReturnType<typeof clinicControllerStartPlusPlanFreeTrial>>>
    export type ClinicControllerStartPlusPlanFreeTrialMutationBody = CancelPlusPlanFreeTrialDto
    export type ClinicControllerStartPlusPlanFreeTrialMutationError = ErrorType<unknown>

    export const useClinicControllerStartPlusPlanFreeTrial = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clinicControllerStartPlusPlanFreeTrial>>, TError,{clinicId: string;data: CancelPlusPlanFreeTrialDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clinicControllerStartPlusPlanFreeTrial>>, {clinicId: string;data: CancelPlusPlanFreeTrialDto}> = (props) => {
          const {clinicId,data} = props ?? {};

          return  clinicControllerStartPlusPlanFreeTrial(clinicId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof clinicControllerStartPlusPlanFreeTrial>>, TError, {clinicId: string;data: CancelPlusPlanFreeTrialDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const clinicControllerSearchClinics = (
    params?: ClinicControllerSearchClinicsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<ClinicSearchResultListDto>(
      {url: `/v1/clinician-api/clinic/search`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getClinicControllerSearchClinicsQueryKey = (params?: ClinicControllerSearchClinicsParams,) => [`/v1/clinician-api/clinic/search`, ...(params ? [params]: [])];

    
export type ClinicControllerSearchClinicsQueryResult = NonNullable<Awaited<ReturnType<typeof clinicControllerSearchClinics>>>
export type ClinicControllerSearchClinicsQueryError = ErrorType<void>

export const useClinicControllerSearchClinics = <TData = Awaited<ReturnType<typeof clinicControllerSearchClinics>>, TError = ErrorType<void>>(
 params?: ClinicControllerSearchClinicsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clinicControllerSearchClinics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getClinicControllerSearchClinicsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof clinicControllerSearchClinics>>> = ({ signal }) => clinicControllerSearchClinics(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof clinicControllerSearchClinics>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerGetSessionsList = (
    params?: SessionControllerGetSessionsListParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SessionListResponseDto>(
      {url: `/v1/clinician-api/session`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getSessionControllerGetSessionsListQueryKey = (params?: SessionControllerGetSessionsListParams,) => [`/v1/clinician-api/session`, ...(params ? [params]: [])];

    
export type SessionControllerGetSessionsListQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessionsList>>>
export type SessionControllerGetSessionsListQueryError = ErrorType<void>

export const useSessionControllerGetSessionsList = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionsList>>, TError = ErrorType<void>>(
 params?: SessionControllerGetSessionsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetSessionsListQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessionsList>>> = ({ signal }) => sessionControllerGetSessionsList(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetSessionsList>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerPostSession = (
    sessionCreateBodyDto: SessionCreateBodyDto,
 
) => {
      return hellojoyApiInstance<SessionDraftDto>(
      {url: `/v1/clinician-api/session`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionCreateBodyDto
    },
      );
    }
  


    export type SessionControllerPostSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostSession>>>
    export type SessionControllerPostSessionMutationBody = SessionCreateBodyDto
    export type SessionControllerPostSessionMutationError = ErrorType<void>

    export const useSessionControllerPostSession = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostSession>>, TError,{data: SessionCreateBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostSession>>, {data: SessionCreateBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerPostSession(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPostSession>>, TError, {data: SessionCreateBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerGetCompletedSessionDetails = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CompletedSessionDto>(
      {url: `/v1/clinician-api/session/${id}`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetCompletedSessionDetailsQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}`];

    
export type SessionControllerGetCompletedSessionDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>>
export type SessionControllerGetCompletedSessionDetailsQueryError = ErrorType<void>

export const useSessionControllerGetCompletedSessionDetails = <TData = Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetCompletedSessionDetailsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>> = ({ signal }) => sessionControllerGetCompletedSessionDetails(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetCompletedSessionDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerDeleteSession = (
    id: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}`, method: 'delete'
    },
      );
    }
  


    export type SessionControllerDeleteSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerDeleteSession>>>
    
    export type SessionControllerDeleteSessionMutationError = ErrorType<unknown>

    export const useSessionControllerDeleteSession = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  sessionControllerDeleteSession(id,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerDeleteSession>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerPatchSession = (
    id: string,
    sessionPatchDto: SessionPatchDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json'},
      data: sessionPatchDto
    },
      );
    }
  


    export type SessionControllerPatchSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPatchSession>>>
    export type SessionControllerPatchSessionMutationBody = SessionPatchDto
    export type SessionControllerPatchSessionMutationError = ErrorType<unknown>

    export const useSessionControllerPatchSession = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchSession>>, TError,{id: string;data: SessionPatchDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPatchSession>>, {id: string;data: SessionPatchDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPatchSession(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPatchSession>>, TError, {id: string;data: SessionPatchDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerGetProgressNotesForSession = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SessionProgressNotesDto>(
      {url: `/v1/clinician-api/session/${id}/progress-notes`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetProgressNotesForSessionQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}/progress-notes`];

    
export type SessionControllerGetProgressNotesForSessionQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>>
export type SessionControllerGetProgressNotesForSessionQueryError = ErrorType<void>

export const useSessionControllerGetProgressNotesForSession = <TData = Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetProgressNotesForSessionQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>> = ({ signal }) => sessionControllerGetProgressNotesForSession(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetProgressNotesForSession>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerPatchProgressNote = (
    id: string,
    noteId: string,
    patchNoteDto: PatchNoteDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/progress-notes/${noteId}`, method: 'patch',
      headers: {'Content-Type': 'application/json'},
      data: patchNoteDto
    },
      );
    }
  


    export type SessionControllerPatchProgressNoteMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>>
    export type SessionControllerPatchProgressNoteMutationBody = PatchNoteDto
    export type SessionControllerPatchProgressNoteMutationError = ErrorType<unknown>

    export const useSessionControllerPatchProgressNote = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>, TError,{id: string;noteId: string;data: PatchNoteDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>, {id: string;noteId: string;data: PatchNoteDto}> = (props) => {
          const {id,noteId,data} = props ?? {};

          return  sessionControllerPatchProgressNote(id,noteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPatchProgressNote>>, TError, {id: string;noteId: string;data: PatchNoteDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerStartInterventions = (
    id: string,
    startInterventionsBodyDto: StartInterventionsBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/start-interventions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: startInterventionsBodyDto
    },
      );
    }
  


    export type SessionControllerStartInterventionsMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerStartInterventions>>>
    export type SessionControllerStartInterventionsMutationBody = StartInterventionsBodyDto
    export type SessionControllerStartInterventionsMutationError = ErrorType<unknown>

    export const useSessionControllerStartInterventions = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerStartInterventions>>, TError,{id: string;data: StartInterventionsBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerStartInterventions>>, {id: string;data: StartInterventionsBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerStartInterventions(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerStartInterventions>>, TError, {id: string;data: StartInterventionsBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerGetStartedInterventions = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientInterventionWithInterventionDto[]>(
      {url: `/v1/clinician-api/session/${id}/started-interventions`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetStartedInterventionsQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}/started-interventions`];

    
export type SessionControllerGetStartedInterventionsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>>
export type SessionControllerGetStartedInterventionsQueryError = ErrorType<void>

export const useSessionControllerGetStartedInterventions = <TData = Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetStartedInterventionsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>> = ({ signal }) => sessionControllerGetStartedInterventions(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetStartedInterventions>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerDeleteIntervention = (
    id: string,
    patientInterventionId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/interventions/${patientInterventionId}`, method: 'delete'
    },
      );
    }
  


    export type SessionControllerDeleteInterventionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>>
    
    export type SessionControllerDeleteInterventionMutationError = ErrorType<unknown>

    export const useSessionControllerDeleteIntervention = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>, TError,{id: string;patientInterventionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>, {id: string;patientInterventionId: string}> = (props) => {
          const {id,patientInterventionId} = props ?? {};

          return  sessionControllerDeleteIntervention(id,patientInterventionId,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerDeleteIntervention>>, TError, {id: string;patientInterventionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerUpdatePsychotherapyNote = (
    id: string,
    sessionPsychotherapyNoteBodyDto: SessionPsychotherapyNoteBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/psychotherapy-note`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionPsychotherapyNoteBodyDto
    },
      );
    }
  


    export type SessionControllerUpdatePsychotherapyNoteMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>>
    export type SessionControllerUpdatePsychotherapyNoteMutationBody = SessionPsychotherapyNoteBodyDto
    export type SessionControllerUpdatePsychotherapyNoteMutationError = ErrorType<unknown>

    export const useSessionControllerUpdatePsychotherapyNote = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>, TError,{id: string;data: SessionPsychotherapyNoteBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>, {id: string;data: SessionPsychotherapyNoteBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerUpdatePsychotherapyNote(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerUpdatePsychotherapyNote>>, TError, {id: string;data: SessionPsychotherapyNoteBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerGetSessionRecap = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SessionRecapResponse>(
      {url: `/v1/clinician-api/session/${id}/recap`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetSessionRecapQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}/recap`];

    
export type SessionControllerGetSessionRecapQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>>
export type SessionControllerGetSessionRecapQueryError = ErrorType<void>

export const useSessionControllerGetSessionRecap = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetSessionRecapQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>> = ({ signal }) => sessionControllerGetSessionRecap(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetSessionRecap>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerTrackEvent = (
    id: string,
    trackEventDto: TrackEventDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/track-event`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: trackEventDto
    },
      );
    }
  


    export type SessionControllerTrackEventMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerTrackEvent>>>
    export type SessionControllerTrackEventMutationBody = TrackEventDto
    export type SessionControllerTrackEventMutationError = ErrorType<unknown>

    export const useSessionControllerTrackEvent = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerTrackEvent>>, TError,{id: string;data: TrackEventDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerTrackEvent>>, {id: string;data: TrackEventDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerTrackEvent(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerTrackEvent>>, TError, {id: string;data: TrackEventDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerPostPlannedInterventions = (
    id: string,
    sessionPlannedInterventionsBodyDto: SessionPlannedInterventionsBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/planned-interventions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionPlannedInterventionsBodyDto
    },
      );
    }
  


    export type SessionControllerPostPlannedInterventionsMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>>
    export type SessionControllerPostPlannedInterventionsMutationBody = SessionPlannedInterventionsBodyDto
    export type SessionControllerPostPlannedInterventionsMutationError = ErrorType<unknown>

    export const useSessionControllerPostPlannedInterventions = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>, TError,{id: string;data: SessionPlannedInterventionsBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>, {id: string;data: SessionPlannedInterventionsBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPostPlannedInterventions(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPostPlannedInterventions>>, TError, {id: string;data: SessionPlannedInterventionsBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerCreateSampleSession = (
    createSampleSessionDto: CreateSampleSessionDto,
 
) => {
      return hellojoyApiInstance<SampleSessionDto>(
      {url: `/v1/clinician-api/session/sample`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: createSampleSessionDto
    },
      );
    }
  


    export type SessionControllerCreateSampleSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>>
    export type SessionControllerCreateSampleSessionMutationBody = CreateSampleSessionDto
    export type SessionControllerCreateSampleSessionMutationError = ErrorType<void>

    export const useSessionControllerCreateSampleSession = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>, TError,{data: CreateSampleSessionDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>, {data: CreateSampleSessionDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerCreateSampleSession(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerCreateSampleSession>>, TError, {data: CreateSampleSessionDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerStartUpload = (
    startUploadDto: StartUploadDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/start-upload`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: startUploadDto
    },
      );
    }
  


    export type SessionControllerStartUploadMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerStartUpload>>>
    export type SessionControllerStartUploadMutationBody = StartUploadDto
    export type SessionControllerStartUploadMutationError = ErrorType<unknown>

    export const useSessionControllerStartUpload = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerStartUpload>>, TError,{data: StartUploadDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerStartUpload>>, {data: StartUploadDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerStartUpload(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerStartUpload>>, TError, {data: StartUploadDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerCompleteUpload = (
    completeUploadDto: CompleteUploadDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/complete-upload`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: completeUploadDto
    },
      );
    }
  


    export type SessionControllerCompleteUploadMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>>
    export type SessionControllerCompleteUploadMutationBody = CompleteUploadDto
    export type SessionControllerCompleteUploadMutationError = ErrorType<unknown>

    export const useSessionControllerCompleteUpload = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>, TError,{data: CompleteUploadDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>, {data: CompleteUploadDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerCompleteUpload(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerCompleteUpload>>, TError, {data: CompleteUploadDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerPostDiscardSession = (
    id: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/discard-session`, method: 'post'
    },
      );
    }
  


    export type SessionControllerPostDiscardSessionMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>>
    
    export type SessionControllerPostDiscardSessionMutationError = ErrorType<unknown>

    export const useSessionControllerPostDiscardSession = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  sessionControllerPostDiscardSession(id,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPostDiscardSession>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerPostMarkComplete = (
    id: string,
    markSessionCompleteBodyDto: MarkSessionCompleteBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/mark-complete`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: markSessionCompleteBodyDto
    },
      );
    }
  


    export type SessionControllerPostMarkCompleteMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>>
    export type SessionControllerPostMarkCompleteMutationBody = MarkSessionCompleteBodyDto
    export type SessionControllerPostMarkCompleteMutationError = ErrorType<unknown>

    export const useSessionControllerPostMarkComplete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>, TError,{id: string;data: MarkSessionCompleteBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>, {id: string;data: MarkSessionCompleteBodyDto}> = (props) => {
          const {id,data} = props ?? {};

          return  sessionControllerPostMarkComplete(id,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPostMarkComplete>>, TError, {id: string;data: MarkSessionCompleteBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerCreateFromUploadedAudio = (
    sessionFromUploadedAudioBodyDto: SessionFromUploadedAudioBodyDto,
 
) => {
      return hellojoyApiInstance<CompletedSessionDto>(
      {url: `/v1/clinician-api/session/from-uploaded-audio`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionFromUploadedAudioBodyDto
    },
      );
    }
  


    export type SessionControllerCreateFromUploadedAudioMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>>
    export type SessionControllerCreateFromUploadedAudioMutationBody = SessionFromUploadedAudioBodyDto
    export type SessionControllerCreateFromUploadedAudioMutationError = ErrorType<void>

    export const useSessionControllerCreateFromUploadedAudio = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>, TError,{data: SessionFromUploadedAudioBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>, {data: SessionFromUploadedAudioBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  sessionControllerCreateFromUploadedAudio(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerCreateFromUploadedAudio>>, TError, {data: SessionFromUploadedAudioBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerPostContentSuggestions = (
    id: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/generate-suggestions`, method: 'post'
    },
      );
    }
  


    export type SessionControllerPostContentSuggestionsMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>>
    
    export type SessionControllerPostContentSuggestionsMutationError = ErrorType<unknown>

    export const useSessionControllerPostContentSuggestions = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  sessionControllerPostContentSuggestions(id,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerPostContentSuggestions>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerGetContentExclusionReasons = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/content-exclusion-reasons`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetContentExclusionReasonsQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}/content-exclusion-reasons`];

    
export type SessionControllerGetContentExclusionReasonsQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>>
export type SessionControllerGetContentExclusionReasonsQueryError = ErrorType<unknown>

export const useSessionControllerGetContentExclusionReasons = <TData = Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetContentExclusionReasonsQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>> = ({ signal }) => sessionControllerGetContentExclusionReasons(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetContentExclusionReasons>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerGetSuggestedContent = (
    id: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SuggestedContentDTO>(
      {url: `/v1/clinician-api/session/${id}/content-suggestions`, method: 'get', signal
    },
      );
    }
  

export const getSessionControllerGetSuggestedContentQueryKey = (id: string,) => [`/v1/clinician-api/session/${id}/content-suggestions`];

    
export type SessionControllerGetSuggestedContentQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>>
export type SessionControllerGetSuggestedContentQueryError = ErrorType<void>

export const useSessionControllerGetSuggestedContent = <TData = Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError = ErrorType<void>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetSuggestedContentQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>> = ({ signal }) => sessionControllerGetSuggestedContent(id, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetSuggestedContent>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerGetDemoSuggestedContent = (
    id: string,
    params?: SessionControllerGetDemoSuggestedContentParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SuggestedContentDTO>(
      {url: `/v1/clinician-api/session/${id}/demo-content-suggestions`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getSessionControllerGetDemoSuggestedContentQueryKey = (id: string,
    params?: SessionControllerGetDemoSuggestedContentParams,) => [`/v1/clinician-api/session/${id}/demo-content-suggestions`, ...(params ? [params]: [])];

    
export type SessionControllerGetDemoSuggestedContentQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>>
export type SessionControllerGetDemoSuggestedContentQueryError = ErrorType<void>

export const useSessionControllerGetDemoSuggestedContent = <TData = Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>, TError = ErrorType<void>>(
 id: string,
    params?: SessionControllerGetDemoSuggestedContentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetDemoSuggestedContentQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>> = ({ signal }) => sessionControllerGetDemoSuggestedContent(id,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetDemoSuggestedContent>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerGetSessionPrep = (
    id: string,
    params?: SessionControllerGetSessionPrepParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SessionPrepDto>(
      {url: `/v1/clinician-api/session/${id}/prep`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getSessionControllerGetSessionPrepQueryKey = (id: string,
    params?: SessionControllerGetSessionPrepParams,) => [`/v1/clinician-api/session/${id}/prep`, ...(params ? [params]: [])];

    
export type SessionControllerGetSessionPrepQueryResult = NonNullable<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>>
export type SessionControllerGetSessionPrepQueryError = ErrorType<void>

export const useSessionControllerGetSessionPrep = <TData = Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError = ErrorType<void>>(
 id: string,
    params?: SessionControllerGetSessionPrepParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSessionControllerGetSessionPrepQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>> = ({ signal }) => sessionControllerGetSessionPrep(id,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof sessionControllerGetSessionPrep>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const sessionControllerInitiateSessionPrepGeneration = (
    id: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/initiate-prep-generation`, method: 'post'
    },
      );
    }
  


    export type SessionControllerInitiateSessionPrepGenerationMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerInitiateSessionPrepGeneration>>>
    
    export type SessionControllerInitiateSessionPrepGenerationMutationError = ErrorType<unknown>

    export const useSessionControllerInitiateSessionPrepGeneration = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerInitiateSessionPrepGeneration>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerInitiateSessionPrepGeneration>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  sessionControllerInitiateSessionPrepGeneration(id,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerInitiateSessionPrepGeneration>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerUploadNoteToEhr = (
    id: string,
    noteId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/${id}/progress-notes/${noteId}/upload-to-ehr`, method: 'post'
    },
      );
    }
  


    export type SessionControllerUploadNoteToEhrMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerUploadNoteToEhr>>>
    
    export type SessionControllerUploadNoteToEhrMutationError = ErrorType<unknown>

    export const useSessionControllerUploadNoteToEhr = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUploadNoteToEhr>>, TError,{id: string;noteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerUploadNoteToEhr>>, {id: string;noteId: string}> = (props) => {
          const {id,noteId} = props ?? {};

          return  sessionControllerUploadNoteToEhr(id,noteId,)
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerUploadNoteToEhr>>, TError, {id: string;noteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const sessionControllerUpgradeNowEmail = (
    
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/session/upgrade-now-email`, method: 'post'
    },
      );
    }
  


    export type SessionControllerUpgradeNowEmailMutationResult = NonNullable<Awaited<ReturnType<typeof sessionControllerUpgradeNowEmail>>>
    
    export type SessionControllerUpgradeNowEmailMutationError = ErrorType<unknown>

    export const useSessionControllerUpgradeNowEmail = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sessionControllerUpgradeNowEmail>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sessionControllerUpgradeNowEmail>>, TVariables> = () => {
          ;

          return  sessionControllerUpgradeNowEmail()
        }

      return useMutation<Awaited<ReturnType<typeof sessionControllerUpgradeNowEmail>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const patientControllerGetPatients = (
    params?: PatientControllerGetPatientsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientDto[]>(
      {url: `/v1/clinician-api/patient/patients`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getPatientControllerGetPatientsQueryKey = (params?: PatientControllerGetPatientsParams,) => [`/v1/clinician-api/patient/patients`, ...(params ? [params]: [])];

    
export type PatientControllerGetPatientsQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetPatients>>>
export type PatientControllerGetPatientsQueryError = ErrorType<void>

export const usePatientControllerGetPatients = <TData = Awaited<ReturnType<typeof patientControllerGetPatients>>, TError = ErrorType<void>>(
 params?: PatientControllerGetPatientsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetPatients>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientControllerGetPatientsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetPatients>>> = ({ signal }) => patientControllerGetPatients(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientControllerGetPatients>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const patientControllerAssistEnroll = (
    patientId: string,
    assistEnrollDto: AssistEnrollDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/assist-enroll`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: assistEnrollDto
    },
      );
    }
  


    export type PatientControllerAssistEnrollMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerAssistEnroll>>>
    export type PatientControllerAssistEnrollMutationBody = AssistEnrollDto
    export type PatientControllerAssistEnrollMutationError = ErrorType<unknown>

    export const usePatientControllerAssistEnroll = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerAssistEnroll>>, TError,{patientId: string;data: AssistEnrollDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerAssistEnroll>>, {patientId: string;data: AssistEnrollDto}> = (props) => {
          const {patientId,data} = props ?? {};

          return  patientControllerAssistEnroll(patientId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof patientControllerAssistEnroll>>, TError, {patientId: string;data: AssistEnrollDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const patientControllerDeleteClient = (
    patientId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}`, method: 'delete'
    },
      );
    }
  


    export type PatientControllerDeleteClientMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerDeleteClient>>>
    
    export type PatientControllerDeleteClientMutationError = ErrorType<unknown>

    export const usePatientControllerDeleteClient = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerDeleteClient>>, TError,{patientId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerDeleteClient>>, {patientId: string}> = (props) => {
          const {patientId} = props ?? {};

          return  patientControllerDeleteClient(patientId,)
        }

      return useMutation<Awaited<ReturnType<typeof patientControllerDeleteClient>>, TError, {patientId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const patientControllerGetSessionList = (
    patientId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SessionDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/session-list`, method: 'get', signal
    },
      );
    }
  

export const getPatientControllerGetSessionListQueryKey = (patientId: string,) => [`/v1/clinician-api/patient/${patientId}/session-list`];

    
export type PatientControllerGetSessionListQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetSessionList>>>
export type PatientControllerGetSessionListQueryError = ErrorType<void>

export const usePatientControllerGetSessionList = <TData = Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientControllerGetSessionListQueryKey(patientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetSessionList>>> = ({ signal }) => patientControllerGetSessionList(patientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientControllerGetSessionList>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const patientControllerGetCheckInScores = (
    patientId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientCheckInScoresDto>(
      {url: `/v1/clinician-api/patient/${patientId}/check-ins/scores`, method: 'get', signal
    },
      );
    }
  

export const getPatientControllerGetCheckInScoresQueryKey = (patientId: string,) => [`/v1/clinician-api/patient/${patientId}/check-ins/scores`];

    
export type PatientControllerGetCheckInScoresQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>>
export type PatientControllerGetCheckInScoresQueryError = ErrorType<void>

export const usePatientControllerGetCheckInScores = <TData = Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientControllerGetCheckInScoresQueryKey(patientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>> = ({ signal }) => patientControllerGetCheckInScores(patientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientControllerGetCheckInScores>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const patientControllerGetConsent = (
    patientId: string,
    params?: PatientControllerGetConsentParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientConsentDto>(
      {url: `/v1/clinician-api/patient/${patientId}/consent`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getPatientControllerGetConsentQueryKey = (patientId: string,
    params?: PatientControllerGetConsentParams,) => [`/v1/clinician-api/patient/${patientId}/consent`, ...(params ? [params]: [])];

    
export type PatientControllerGetConsentQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetConsent>>>
export type PatientControllerGetConsentQueryError = ErrorType<void>

export const usePatientControllerGetConsent = <TData = Awaited<ReturnType<typeof patientControllerGetConsent>>, TError = ErrorType<void>>(
 patientId: string,
    params?: PatientControllerGetConsentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetConsent>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientControllerGetConsentQueryKey(patientId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetConsent>>> = ({ signal }) => patientControllerGetConsent(patientId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientControllerGetConsent>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const patientControllerAssignCheckIns = (
    patientId: string,
    assignCheckInDto: AssignCheckInDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/checkin/assign`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: assignCheckInDto
    },
      );
    }
  


    export type PatientControllerAssignCheckInsMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerAssignCheckIns>>>
    export type PatientControllerAssignCheckInsMutationBody = AssignCheckInDto
    export type PatientControllerAssignCheckInsMutationError = ErrorType<unknown>

    export const usePatientControllerAssignCheckIns = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerAssignCheckIns>>, TError,{patientId: string;data: AssignCheckInDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerAssignCheckIns>>, {patientId: string;data: AssignCheckInDto}> = (props) => {
          const {patientId,data} = props ?? {};

          return  patientControllerAssignCheckIns(patientId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof patientControllerAssignCheckIns>>, TError, {patientId: string;data: AssignCheckInDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const patientControllerUnassignCheckIn = (
    patientId: string,
    checkInId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/checkin/${checkInId}/unassign`, method: 'post'
    },
      );
    }
  


    export type PatientControllerUnassignCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerUnassignCheckIn>>>
    
    export type PatientControllerUnassignCheckInMutationError = ErrorType<unknown>

    export const usePatientControllerUnassignCheckIn = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerUnassignCheckIn>>, TError,{patientId: string;checkInId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerUnassignCheckIn>>, {patientId: string;checkInId: string}> = (props) => {
          const {patientId,checkInId} = props ?? {};

          return  patientControllerUnassignCheckIn(patientId,checkInId,)
        }

      return useMutation<Awaited<ReturnType<typeof patientControllerUnassignCheckIn>>, TError, {patientId: string;checkInId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const patientControllerUpdateCheckInNotification = (
    patientId: string,
    checkInId: string,
    updateCheckInNotificationDto: UpdateCheckInNotificationDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/checkin/${checkInId}/update-notification`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: updateCheckInNotificationDto
    },
      );
    }
  


    export type PatientControllerUpdateCheckInNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof patientControllerUpdateCheckInNotification>>>
    export type PatientControllerUpdateCheckInNotificationMutationBody = UpdateCheckInNotificationDto
    export type PatientControllerUpdateCheckInNotificationMutationError = ErrorType<unknown>

    export const usePatientControllerUpdateCheckInNotification = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientControllerUpdateCheckInNotification>>, TError,{patientId: string;checkInId: string;data: UpdateCheckInNotificationDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientControllerUpdateCheckInNotification>>, {patientId: string;checkInId: string;data: UpdateCheckInNotificationDto}> = (props) => {
          const {patientId,checkInId,data} = props ?? {};

          return  patientControllerUpdateCheckInNotification(patientId,checkInId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof patientControllerUpdateCheckInNotification>>, TError, {patientId: string;checkInId: string;data: UpdateCheckInNotificationDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const patientControllerGetAssignedCheckIns = (
    patientId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<AssignedCheckInDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/assigned-checkins`, method: 'get', signal
    },
      );
    }
  

export const getPatientControllerGetAssignedCheckInsQueryKey = (patientId: string,) => [`/v1/clinician-api/patient/${patientId}/assigned-checkins`];

    
export type PatientControllerGetAssignedCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>>
export type PatientControllerGetAssignedCheckInsQueryError = ErrorType<void>

export const usePatientControllerGetAssignedCheckIns = <TData = Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientControllerGetAssignedCheckInsQueryKey(patientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>> = ({ signal }) => patientControllerGetAssignedCheckIns(patientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientControllerGetAssignedCheckIns>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const interventionsControllerSaveInterventionForLater = (
    patientId: string,
    interventionId: string,
    saveForLaterBodyDto: SaveForLaterBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/interventions/${interventionId}/save-for-later`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: saveForLaterBodyDto
    },
      );
    }
  


    export type InterventionsControllerSaveInterventionForLaterMutationResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerSaveInterventionForLater>>>
    export type InterventionsControllerSaveInterventionForLaterMutationBody = SaveForLaterBodyDto
    export type InterventionsControllerSaveInterventionForLaterMutationError = ErrorType<unknown>

    export const useInterventionsControllerSaveInterventionForLater = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof interventionsControllerSaveInterventionForLater>>, TError,{patientId: string;interventionId: string;data: SaveForLaterBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof interventionsControllerSaveInterventionForLater>>, {patientId: string;interventionId: string;data: SaveForLaterBodyDto}> = (props) => {
          const {patientId,interventionId,data} = props ?? {};

          return  interventionsControllerSaveInterventionForLater(patientId,interventionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof interventionsControllerSaveInterventionForLater>>, TError, {patientId: string;interventionId: string;data: SaveForLaterBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const interventionsControllerGetSavedForLaterInterventions = (
    patientId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<SavedForLaterInterventionDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/interventions/saved-for-later`, method: 'get', signal
    },
      );
    }
  

export const getInterventionsControllerGetSavedForLaterInterventionsQueryKey = (patientId: string,) => [`/v1/clinician-api/patient/${patientId}/interventions/saved-for-later`];

    
export type InterventionsControllerGetSavedForLaterInterventionsQueryResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>>
export type InterventionsControllerGetSavedForLaterInterventionsQueryError = ErrorType<void>

export const useInterventionsControllerGetSavedForLaterInterventions = <TData = Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInterventionsControllerGetSavedForLaterInterventionsQueryKey(patientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>> = ({ signal }) => interventionsControllerGetSavedForLaterInterventions(patientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof interventionsControllerGetSavedForLaterInterventions>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const interventionsControllerRemoveInterventionFromSavedForLater = (
    patientId: string,
    interventionId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/interventions/${interventionId}/remove-from-saved`, method: 'delete'
    },
      );
    }
  


    export type InterventionsControllerRemoveInterventionFromSavedForLaterMutationResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerRemoveInterventionFromSavedForLater>>>
    
    export type InterventionsControllerRemoveInterventionFromSavedForLaterMutationError = ErrorType<unknown>

    export const useInterventionsControllerRemoveInterventionFromSavedForLater = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof interventionsControllerRemoveInterventionFromSavedForLater>>, TError,{patientId: string;interventionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof interventionsControllerRemoveInterventionFromSavedForLater>>, {patientId: string;interventionId: string}> = (props) => {
          const {patientId,interventionId} = props ?? {};

          return  interventionsControllerRemoveInterventionFromSavedForLater(patientId,interventionId,)
        }

      return useMutation<Awaited<ReturnType<typeof interventionsControllerRemoveInterventionFromSavedForLater>>, TError, {patientId: string;interventionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const patientInterventionsControllerGetAllPatientInterventions = (
    patientId: string,
    params?: PatientInterventionsControllerGetAllPatientInterventionsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientInterventionDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/patient-interventions`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getPatientInterventionsControllerGetAllPatientInterventionsQueryKey = (patientId: string,
    params?: PatientInterventionsControllerGetAllPatientInterventionsParams,) => [`/v1/clinician-api/patient/${patientId}/patient-interventions`, ...(params ? [params]: [])];

    
export type PatientInterventionsControllerGetAllPatientInterventionsQueryResult = NonNullable<Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>>
export type PatientInterventionsControllerGetAllPatientInterventionsQueryError = ErrorType<void>

export const usePatientInterventionsControllerGetAllPatientInterventions = <TData = Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>, TError = ErrorType<void>>(
 patientId: string,
    params?: PatientInterventionsControllerGetAllPatientInterventionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientInterventionsControllerGetAllPatientInterventionsQueryKey(patientId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>> = ({ signal }) => patientInterventionsControllerGetAllPatientInterventions(patientId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientInterventionsControllerGetAllPatientInterventions>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const patientInterventionsControllerCreatePatientIntervention = (
    patientId: string,
    postPatientInterventionDto: PostPatientInterventionDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/patient-interventions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: postPatientInterventionDto
    },
      );
    }
  


    export type PatientInterventionsControllerCreatePatientInterventionMutationResult = NonNullable<Awaited<ReturnType<typeof patientInterventionsControllerCreatePatientIntervention>>>
    export type PatientInterventionsControllerCreatePatientInterventionMutationBody = PostPatientInterventionDto
    export type PatientInterventionsControllerCreatePatientInterventionMutationError = ErrorType<unknown>

    export const usePatientInterventionsControllerCreatePatientIntervention = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerCreatePatientIntervention>>, TError,{patientId: string;data: PostPatientInterventionDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientInterventionsControllerCreatePatientIntervention>>, {patientId: string;data: PostPatientInterventionDto}> = (props) => {
          const {patientId,data} = props ?? {};

          return  patientInterventionsControllerCreatePatientIntervention(patientId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof patientInterventionsControllerCreatePatientIntervention>>, TError, {patientId: string;data: PostPatientInterventionDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const patientInterventionsControllerGetPatientInterventionById = (
    patientId: string,
    patientInterventionId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<PatientInterventionWithResponsesDto>(
      {url: `/v1/clinician-api/patient/${patientId}/patient-interventions/${patientInterventionId}/responses`, method: 'get', signal
    },
      );
    }
  

export const getPatientInterventionsControllerGetPatientInterventionByIdQueryKey = (patientId: string,
    patientInterventionId: string,) => [`/v1/clinician-api/patient/${patientId}/patient-interventions/${patientInterventionId}/responses`];

    
export type PatientInterventionsControllerGetPatientInterventionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>>
export type PatientInterventionsControllerGetPatientInterventionByIdQueryError = ErrorType<void>

export const usePatientInterventionsControllerGetPatientInterventionById = <TData = Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>, TError = ErrorType<void>>(
 patientId: string,
    patientInterventionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPatientInterventionsControllerGetPatientInterventionByIdQueryKey(patientId,patientInterventionId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>> = ({ signal }) => patientInterventionsControllerGetPatientInterventionById(patientId,patientInterventionId, signal);

  const query = useQuery<Awaited<ReturnType<typeof patientInterventionsControllerGetPatientInterventionById>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId && patientInterventionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const patientInterventionsControllerUpdatePatientIntervention = (
    patientId: string,
    patientInterventionId: string,
    putPatientInterventionDto: PutPatientInterventionDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/patient-interventions/${patientInterventionId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: putPatientInterventionDto
    },
      );
    }
  


    export type PatientInterventionsControllerUpdatePatientInterventionMutationResult = NonNullable<Awaited<ReturnType<typeof patientInterventionsControllerUpdatePatientIntervention>>>
    export type PatientInterventionsControllerUpdatePatientInterventionMutationBody = PutPatientInterventionDto
    export type PatientInterventionsControllerUpdatePatientInterventionMutationError = ErrorType<unknown>

    export const usePatientInterventionsControllerUpdatePatientIntervention = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerUpdatePatientIntervention>>, TError,{patientId: string;patientInterventionId: string;data: PutPatientInterventionDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientInterventionsControllerUpdatePatientIntervention>>, {patientId: string;patientInterventionId: string;data: PutPatientInterventionDto}> = (props) => {
          const {patientId,patientInterventionId,data} = props ?? {};

          return  patientInterventionsControllerUpdatePatientIntervention(patientId,patientInterventionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof patientInterventionsControllerUpdatePatientIntervention>>, TError, {patientId: string;patientInterventionId: string;data: PutPatientInterventionDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const patientInterventionsControllerDeletePatientIntervention = (
    patientId: string,
    patientInterventionId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/patient-interventions/${patientInterventionId}`, method: 'delete'
    },
      );
    }
  


    export type PatientInterventionsControllerDeletePatientInterventionMutationResult = NonNullable<Awaited<ReturnType<typeof patientInterventionsControllerDeletePatientIntervention>>>
    
    export type PatientInterventionsControllerDeletePatientInterventionMutationError = ErrorType<unknown>

    export const usePatientInterventionsControllerDeletePatientIntervention = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patientInterventionsControllerDeletePatientIntervention>>, TError,{patientId: string;patientInterventionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patientInterventionsControllerDeletePatientIntervention>>, {patientId: string;patientInterventionId: string}> = (props) => {
          const {patientId,patientInterventionId} = props ?? {};

          return  patientInterventionsControllerDeletePatientIntervention(patientId,patientInterventionId,)
        }

      return useMutation<Awaited<ReturnType<typeof patientInterventionsControllerDeletePatientIntervention>>, TError, {patientId: string;patientInterventionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const dischargeSummariesControllerGenerateDischargeSummary = (
    patientId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/discharge-summaries`, method: 'post'
    },
      );
    }
  


    export type DischargeSummariesControllerGenerateDischargeSummaryMutationResult = NonNullable<Awaited<ReturnType<typeof dischargeSummariesControllerGenerateDischargeSummary>>>
    
    export type DischargeSummariesControllerGenerateDischargeSummaryMutationError = ErrorType<unknown>

    export const useDischargeSummariesControllerGenerateDischargeSummary = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerGenerateDischargeSummary>>, TError,{patientId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dischargeSummariesControllerGenerateDischargeSummary>>, {patientId: string}> = (props) => {
          const {patientId} = props ?? {};

          return  dischargeSummariesControllerGenerateDischargeSummary(patientId,)
        }

      return useMutation<Awaited<ReturnType<typeof dischargeSummariesControllerGenerateDischargeSummary>>, TError, {patientId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const dischargeSummariesControllerGetDischargeSummaries = (
    patientId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<DischargeSummaryDto[]>(
      {url: `/v1/clinician-api/patient/${patientId}/discharge-summaries`, method: 'get', signal
    },
      );
    }
  

export const getDischargeSummariesControllerGetDischargeSummariesQueryKey = (patientId: string,) => [`/v1/clinician-api/patient/${patientId}/discharge-summaries`];

    
export type DischargeSummariesControllerGetDischargeSummariesQueryResult = NonNullable<Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>>
export type DischargeSummariesControllerGetDischargeSummariesQueryError = ErrorType<void>

export const useDischargeSummariesControllerGetDischargeSummaries = <TData = Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>, TError = ErrorType<void>>(
 patientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getDischargeSummariesControllerGetDischargeSummariesQueryKey(patientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>> = ({ signal }) => dischargeSummariesControllerGetDischargeSummaries(patientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof dischargeSummariesControllerGetDischargeSummaries>>, TError, TData>(queryKey, queryFn, {enabled: !!(patientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const dischargeSummariesControllerUpdateDischargeSummary = (
    patientId: string,
    dischargeSummaryId: string,
    patchDischargeSummaryDto: PatchDischargeSummaryDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/discharge-summaries/${dischargeSummaryId}`, method: 'patch',
      headers: {'Content-Type': 'application/json'},
      data: patchDischargeSummaryDto
    },
      );
    }
  


    export type DischargeSummariesControllerUpdateDischargeSummaryMutationResult = NonNullable<Awaited<ReturnType<typeof dischargeSummariesControllerUpdateDischargeSummary>>>
    export type DischargeSummariesControllerUpdateDischargeSummaryMutationBody = PatchDischargeSummaryDto
    export type DischargeSummariesControllerUpdateDischargeSummaryMutationError = ErrorType<unknown>

    export const useDischargeSummariesControllerUpdateDischargeSummary = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerUpdateDischargeSummary>>, TError,{patientId: string;dischargeSummaryId: string;data: PatchDischargeSummaryDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dischargeSummariesControllerUpdateDischargeSummary>>, {patientId: string;dischargeSummaryId: string;data: PatchDischargeSummaryDto}> = (props) => {
          const {patientId,dischargeSummaryId,data} = props ?? {};

          return  dischargeSummariesControllerUpdateDischargeSummary(patientId,dischargeSummaryId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof dischargeSummariesControllerUpdateDischargeSummary>>, TError, {patientId: string;dischargeSummaryId: string;data: PatchDischargeSummaryDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const dischargeSummariesControllerDeleteDischargeSummary = (
    patientId: string,
    dischargeSummaryId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/patient/${patientId}/discharge-summaries/${dischargeSummaryId}`, method: 'delete'
    },
      );
    }
  


    export type DischargeSummariesControllerDeleteDischargeSummaryMutationResult = NonNullable<Awaited<ReturnType<typeof dischargeSummariesControllerDeleteDischargeSummary>>>
    
    export type DischargeSummariesControllerDeleteDischargeSummaryMutationError = ErrorType<unknown>

    export const useDischargeSummariesControllerDeleteDischargeSummary = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dischargeSummariesControllerDeleteDischargeSummary>>, TError,{patientId: string;dischargeSummaryId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dischargeSummariesControllerDeleteDischargeSummary>>, {patientId: string;dischargeSummaryId: string}> = (props) => {
          const {patientId,dischargeSummaryId} = props ?? {};

          return  dischargeSummariesControllerDeleteDischargeSummary(patientId,dischargeSummaryId,)
        }

      return useMutation<Awaited<ReturnType<typeof dischargeSummariesControllerDeleteDischargeSummary>>, TError, {patientId: string;dischargeSummaryId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const checkinsControllerV1GetAllCheckIns = (
    params?: CheckinsControllerV1GetAllCheckInsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/library/check-ins/all`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getCheckinsControllerV1GetAllCheckInsQueryKey = (params?: CheckinsControllerV1GetAllCheckInsParams,) => [`/v1/clinician-api/library/check-ins/all`, ...(params ? [params]: [])];

    
export type CheckinsControllerV1GetAllCheckInsQueryResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>>
export type CheckinsControllerV1GetAllCheckInsQueryError = ErrorType<void>

export const useCheckinsControllerV1GetAllCheckIns = <TData = Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>, TError = ErrorType<void>>(
 params?: CheckinsControllerV1GetAllCheckInsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCheckinsControllerV1GetAllCheckInsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>> = ({ signal }) => checkinsControllerV1GetAllCheckIns(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof checkinsControllerV1GetAllCheckIns>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const checkinsControllerV1GetDraftCheckIn = (
    checkInId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/draft`, method: 'get', signal
    },
      );
    }
  

export const getCheckinsControllerV1GetDraftCheckInQueryKey = (checkInId: string,) => [`/v1/clinician-api/library/check-ins/${checkInId}/draft`];

    
export type CheckinsControllerV1GetDraftCheckInQueryResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>>
export type CheckinsControllerV1GetDraftCheckInQueryError = ErrorType<void>

export const useCheckinsControllerV1GetDraftCheckIn = <TData = Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>, TError = ErrorType<void>>(
 checkInId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCheckinsControllerV1GetDraftCheckInQueryKey(checkInId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>> = ({ signal }) => checkinsControllerV1GetDraftCheckIn(checkInId, signal);

  const query = useQuery<Awaited<ReturnType<typeof checkinsControllerV1GetDraftCheckIn>>, TError, TData>(queryKey, queryFn, {enabled: !!(checkInId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const checkinsControllerV1SaveDraftCheckIn = (
    checkInId: string,
    checkInBodyDto: CheckInBodyDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/draft`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: checkInBodyDto
    },
      );
    }
  


    export type CheckinsControllerV1SaveDraftCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1SaveDraftCheckIn>>>
    export type CheckinsControllerV1SaveDraftCheckInMutationBody = CheckInBodyDto
    export type CheckinsControllerV1SaveDraftCheckInMutationError = ErrorType<unknown>

    export const useCheckinsControllerV1SaveDraftCheckIn = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1SaveDraftCheckIn>>, TError,{checkInId: string;data: CheckInBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkinsControllerV1SaveDraftCheckIn>>, {checkInId: string;data: CheckInBodyDto}> = (props) => {
          const {checkInId,data} = props ?? {};

          return  checkinsControllerV1SaveDraftCheckIn(checkInId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof checkinsControllerV1SaveDraftCheckIn>>, TError, {checkInId: string;data: CheckInBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const checkinsControllerV1CreateCheckIn = (
    checkInBodyDto: CheckInBodyDto,
 
) => {
      return hellojoyApiInstance<unknown>(
      {url: `/v1/clinician-api/library/check-ins/create`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: checkInBodyDto
    },
      );
    }
  


    export type CheckinsControllerV1CreateCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1CreateCheckIn>>>
    export type CheckinsControllerV1CreateCheckInMutationBody = CheckInBodyDto
    export type CheckinsControllerV1CreateCheckInMutationError = ErrorType<void | CheckInDtoV2>

    export const useCheckinsControllerV1CreateCheckIn = <TError = ErrorType<void | CheckInDtoV2>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1CreateCheckIn>>, TError,{data: CheckInBodyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkinsControllerV1CreateCheckIn>>, {data: CheckInBodyDto}> = (props) => {
          const {data} = props ?? {};

          return  checkinsControllerV1CreateCheckIn(data,)
        }

      return useMutation<Awaited<ReturnType<typeof checkinsControllerV1CreateCheckIn>>, TError, {data: CheckInBodyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const checkinsControllerV1PublishDraftCheckIn = (
    checkInId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/publish-draft`, method: 'post'
    },
      );
    }
  


    export type CheckinsControllerV1PublishDraftCheckInMutationResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1PublishDraftCheckIn>>>
    
    export type CheckinsControllerV1PublishDraftCheckInMutationError = ErrorType<unknown>

    export const useCheckinsControllerV1PublishDraftCheckIn = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1PublishDraftCheckIn>>, TError,{checkInId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkinsControllerV1PublishDraftCheckIn>>, {checkInId: string}> = (props) => {
          const {checkInId} = props ?? {};

          return  checkinsControllerV1PublishDraftCheckIn(checkInId,)
        }

      return useMutation<Awaited<ReturnType<typeof checkinsControllerV1PublishDraftCheckIn>>, TError, {checkInId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const checkinsControllerV1ToggleCheckInIsCore = (
    checkInId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/toggle-core`, method: 'put'
    },
      );
    }
  


    export type CheckinsControllerV1ToggleCheckInIsCoreMutationResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckInIsCore>>>
    
    export type CheckinsControllerV1ToggleCheckInIsCoreMutationError = ErrorType<unknown>

    export const useCheckinsControllerV1ToggleCheckInIsCore = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckInIsCore>>, TError,{checkInId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckInIsCore>>, {checkInId: string}> = (props) => {
          const {checkInId} = props ?? {};

          return  checkinsControllerV1ToggleCheckInIsCore(checkInId,)
        }

      return useMutation<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckInIsCore>>, TError, {checkInId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const checkinsControllerV1ToggleCheckinIsAssist = (
    checkInId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/library/check-ins/${checkInId}/toggle-assist`, method: 'put'
    },
      );
    }
  


    export type CheckinsControllerV1ToggleCheckinIsAssistMutationResult = NonNullable<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckinIsAssist>>>
    
    export type CheckinsControllerV1ToggleCheckinIsAssistMutationError = ErrorType<unknown>

    export const useCheckinsControllerV1ToggleCheckinIsAssist = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckinIsAssist>>, TError,{checkInId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckinIsAssist>>, {checkInId: string}> = (props) => {
          const {checkInId} = props ?? {};

          return  checkinsControllerV1ToggleCheckinIsAssist(checkInId,)
        }

      return useMutation<Awaited<ReturnType<typeof checkinsControllerV1ToggleCheckinIsAssist>>, TError, {checkInId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const interventionsControllerV1GetAllInterventions = (
    params?: InterventionsControllerV1GetAllInterventionsParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<Intervention[]>(
      {url: `/v1/clinician-api/library/interventions/all`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getInterventionsControllerV1GetAllInterventionsQueryKey = (params?: InterventionsControllerV1GetAllInterventionsParams,) => [`/v1/clinician-api/library/interventions/all`, ...(params ? [params]: [])];

    
export type InterventionsControllerV1GetAllInterventionsQueryResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>>
export type InterventionsControllerV1GetAllInterventionsQueryError = ErrorType<void>

export const useInterventionsControllerV1GetAllInterventions = <TData = Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>, TError = ErrorType<void>>(
 params?: InterventionsControllerV1GetAllInterventionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInterventionsControllerV1GetAllInterventionsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>> = ({ signal }) => interventionsControllerV1GetAllInterventions(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof interventionsControllerV1GetAllInterventions>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const interventionsControllerV1GetIntervention = (
    interventionId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<Intervention>(
      {url: `/v1/clinician-api/library/interventions/${interventionId}`, method: 'get', signal
    },
      );
    }
  

export const getInterventionsControllerV1GetInterventionQueryKey = (interventionId: string,) => [`/v1/clinician-api/library/interventions/${interventionId}`];

    
export type InterventionsControllerV1GetInterventionQueryResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>>
export type InterventionsControllerV1GetInterventionQueryError = ErrorType<void>

export const useInterventionsControllerV1GetIntervention = <TData = Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>, TError = ErrorType<void>>(
 interventionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInterventionsControllerV1GetInterventionQueryKey(interventionId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>> = ({ signal }) => interventionsControllerV1GetIntervention(interventionId, signal);

  const query = useQuery<Awaited<ReturnType<typeof interventionsControllerV1GetIntervention>>, TError, TData>(queryKey, queryFn, {enabled: !!(interventionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const interventionsControllerV1GetInterventionActivity = (
    interventionId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<Intervention>(
      {url: `/v1/clinician-api/library/interventions/${interventionId}/items`, method: 'get', signal
    },
      );
    }
  

export const getInterventionsControllerV1GetInterventionActivityQueryKey = (interventionId: string,) => [`/v1/clinician-api/library/interventions/${interventionId}/items`];

    
export type InterventionsControllerV1GetInterventionActivityQueryResult = NonNullable<Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>>
export type InterventionsControllerV1GetInterventionActivityQueryError = ErrorType<void>

export const useInterventionsControllerV1GetInterventionActivity = <TData = Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>, TError = ErrorType<void>>(
 interventionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInterventionsControllerV1GetInterventionActivityQueryKey(interventionId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>> = ({ signal }) => interventionsControllerV1GetInterventionActivity(interventionId, signal);

  const query = useQuery<Awaited<ReturnType<typeof interventionsControllerV1GetInterventionActivity>>, TError, TData>(queryKey, queryFn, {enabled: !!(interventionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const contentSuggestionControllerDismiss = (
    contentSuggestionId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/content-suggestion/${contentSuggestionId}/dismiss`, method: 'patch'
    },
      );
    }
  


    export type ContentSuggestionControllerDismissMutationResult = NonNullable<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>>
    
    export type ContentSuggestionControllerDismissMutationError = ErrorType<unknown>

    export const useContentSuggestionControllerDismiss = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>, TError,{contentSuggestionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>, {contentSuggestionId: string}> = (props) => {
          const {contentSuggestionId} = props ?? {};

          return  contentSuggestionControllerDismiss(contentSuggestionId,)
        }

      return useMutation<Awaited<ReturnType<typeof contentSuggestionControllerDismiss>>, TError, {contentSuggestionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const contentSuggestionControllerAccept = (
    contentSuggestionId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/content-suggestion/${contentSuggestionId}/accept`, method: 'patch'
    },
      );
    }
  


    export type ContentSuggestionControllerAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>>
    
    export type ContentSuggestionControllerAcceptMutationError = ErrorType<unknown>

    export const useContentSuggestionControllerAccept = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>, TError,{contentSuggestionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>, {contentSuggestionId: string}> = (props) => {
          const {contentSuggestionId} = props ?? {};

          return  contentSuggestionControllerAccept(contentSuggestionId,)
        }

      return useMutation<Awaited<ReturnType<typeof contentSuggestionControllerAccept>>, TError, {contentSuggestionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const contentSuggestionControllerSubmitFeedback = (
    contentSuggestionId: string,
    submitFeedbackDto: SubmitFeedbackDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/content-suggestion/${contentSuggestionId}/feedback`, method: 'patch',
      headers: {'Content-Type': 'application/json'},
      data: submitFeedbackDto
    },
      );
    }
  


    export type ContentSuggestionControllerSubmitFeedbackMutationResult = NonNullable<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>>
    export type ContentSuggestionControllerSubmitFeedbackMutationBody = SubmitFeedbackDto
    export type ContentSuggestionControllerSubmitFeedbackMutationError = ErrorType<unknown>

    export const useContentSuggestionControllerSubmitFeedback = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>, TError,{contentSuggestionId: string;data: SubmitFeedbackDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>, {contentSuggestionId: string;data: SubmitFeedbackDto}> = (props) => {
          const {contentSuggestionId,data} = props ?? {};

          return  contentSuggestionControllerSubmitFeedback(contentSuggestionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof contentSuggestionControllerSubmitFeedback>>, TError, {contentSuggestionId: string;data: SubmitFeedbackDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const organizationControllerGetOrganizationConsentSetting = (
    organizationId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<ConsentSettingDTO>(
      {url: `/v1/clinician-api/organization/${organizationId}/consent-setting`, method: 'get', signal
    },
      );
    }
  

export const getOrganizationControllerGetOrganizationConsentSettingQueryKey = (organizationId: string,) => [`/v1/clinician-api/organization/${organizationId}/consent-setting`];

    
export type OrganizationControllerGetOrganizationConsentSettingQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>>
export type OrganizationControllerGetOrganizationConsentSettingQueryError = ErrorType<unknown>

export const useOrganizationControllerGetOrganizationConsentSetting = <TData = Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError = ErrorType<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationControllerGetOrganizationConsentSettingQueryKey(organizationId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>> = ({ signal }) => organizationControllerGetOrganizationConsentSetting(organizationId, signal);

  const query = useQuery<Awaited<ReturnType<typeof organizationControllerGetOrganizationConsentSetting>>, TError, TData>(queryKey, queryFn, {enabled: !!(organizationId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const organizationControllerUpdateOrganizationConsentSetting = (
    organizationId: string,
    patchConsentSettingDTO: PatchConsentSettingDTO,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/organization/${organizationId}/consent-setting`, method: 'patch',
      headers: {'Content-Type': 'application/json'},
      data: patchConsentSettingDTO
    },
      );
    }
  


    export type OrganizationControllerUpdateOrganizationConsentSettingMutationResult = NonNullable<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>>
    export type OrganizationControllerUpdateOrganizationConsentSettingMutationBody = PatchConsentSettingDTO
    export type OrganizationControllerUpdateOrganizationConsentSettingMutationError = ErrorType<unknown>

    export const useOrganizationControllerUpdateOrganizationConsentSetting = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>, TError,{organizationId: string;data: PatchConsentSettingDTO}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>, {organizationId: string;data: PatchConsentSettingDTO}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  organizationControllerUpdateOrganizationConsentSetting(organizationId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof organizationControllerUpdateOrganizationConsentSetting>>, TError, {organizationId: string;data: PatchConsentSettingDTO}, TContext>(mutationFn, mutationOptions)
    }
    
export const organizationControllerGetOutboundEhrSettings = (
    organizationId: string,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<OutboundEhrSettingsDTO>(
      {url: `/v1/clinician-api/organization/${organizationId}/outbound-ehr-settings`, method: 'get', signal
    },
      );
    }
  

export const getOrganizationControllerGetOutboundEhrSettingsQueryKey = (organizationId: string,) => [`/v1/clinician-api/organization/${organizationId}/outbound-ehr-settings`];

    
export type OrganizationControllerGetOutboundEhrSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>>
export type OrganizationControllerGetOutboundEhrSettingsQueryError = ErrorType<unknown>

export const useOrganizationControllerGetOutboundEhrSettings = <TData = Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>, TError = ErrorType<unknown>>(
 organizationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrganizationControllerGetOutboundEhrSettingsQueryKey(organizationId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>> = ({ signal }) => organizationControllerGetOutboundEhrSettings(organizationId, signal);

  const query = useQuery<Awaited<ReturnType<typeof organizationControllerGetOutboundEhrSettings>>, TError, TData>(queryKey, queryFn, {enabled: !!(organizationId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const checkInControllerGetCheckInsForPlusCustomer = (
    
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<CheckInDtoV2[]>(
      {url: `/v1/clinician-api/check-in`, method: 'get', signal
    },
      );
    }
  

export const getCheckInControllerGetCheckInsForPlusCustomerQueryKey = () => [`/v1/clinician-api/check-in`];

    
export type CheckInControllerGetCheckInsForPlusCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>>
export type CheckInControllerGetCheckInsForPlusCustomerQueryError = ErrorType<void>

export const useCheckInControllerGetCheckInsForPlusCustomer = <TData = Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>, TError = ErrorType<void>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCheckInControllerGetCheckInsForPlusCustomerQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>> = ({ signal }) => checkInControllerGetCheckInsForPlusCustomer(signal);

  const query = useQuery<Awaited<ReturnType<typeof checkInControllerGetCheckInsForPlusCustomer>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const onboardingControllerExperiment = (
    params?: OnboardingControllerExperimentParams,
 signal?: AbortSignal
) => {
      return hellojoyApiInstance<GetExperimentDto>(
      {url: `/v1/clinician-api/onboarding/experiment`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getOnboardingControllerExperimentQueryKey = (params?: OnboardingControllerExperimentParams,) => [`/v1/clinician-api/onboarding/experiment`, ...(params ? [params]: [])];

    
export type OnboardingControllerExperimentQueryResult = NonNullable<Awaited<ReturnType<typeof onboardingControllerExperiment>>>
export type OnboardingControllerExperimentQueryError = ErrorType<unknown>

export const useOnboardingControllerExperiment = <TData = Awaited<ReturnType<typeof onboardingControllerExperiment>>, TError = ErrorType<unknown>>(
 params?: OnboardingControllerExperimentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof onboardingControllerExperiment>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOnboardingControllerExperimentQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof onboardingControllerExperiment>>> = ({ signal }) => onboardingControllerExperiment(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof onboardingControllerExperiment>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const onboardingControllerValidateEmail = (
    validateEmailDto: ValidateEmailDto,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/onboarding/validate-email`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: validateEmailDto
    },
      );
    }
  


    export type OnboardingControllerValidateEmailMutationResult = NonNullable<Awaited<ReturnType<typeof onboardingControllerValidateEmail>>>
    export type OnboardingControllerValidateEmailMutationBody = ValidateEmailDto
    export type OnboardingControllerValidateEmailMutationError = ErrorType<unknown>

    export const useOnboardingControllerValidateEmail = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof onboardingControllerValidateEmail>>, TError,{data: ValidateEmailDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof onboardingControllerValidateEmail>>, {data: ValidateEmailDto}> = (props) => {
          const {data} = props ?? {};

          return  onboardingControllerValidateEmail(data,)
        }

      return useMutation<Awaited<ReturnType<typeof onboardingControllerValidateEmail>>, TError, {data: ValidateEmailDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const onboardingControllerSubmitSurveyResponse = (
    onboardingSurveyDto: OnboardingSurveyDto,
 
) => {
      return hellojoyApiInstance<unknown>(
      {url: `/v1/clinician-api/onboarding/survey`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: onboardingSurveyDto
    },
      );
    }
  


    export type OnboardingControllerSubmitSurveyResponseMutationResult = NonNullable<Awaited<ReturnType<typeof onboardingControllerSubmitSurveyResponse>>>
    export type OnboardingControllerSubmitSurveyResponseMutationBody = OnboardingSurveyDto
    export type OnboardingControllerSubmitSurveyResponseMutationError = ErrorType<void>

    export const useOnboardingControllerSubmitSurveyResponse = <TError = ErrorType<void>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof onboardingControllerSubmitSurveyResponse>>, TError,{data: OnboardingSurveyDto}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof onboardingControllerSubmitSurveyResponse>>, {data: OnboardingSurveyDto}> = (props) => {
          const {data} = props ?? {};

          return  onboardingControllerSubmitSurveyResponse(data,)
        }

      return useMutation<Awaited<ReturnType<typeof onboardingControllerSubmitSurveyResponse>>, TError, {data: OnboardingSurveyDto}, TContext>(mutationFn, mutationOptions)
    }
    
export const stayOnBlueprintOfferControllerAccept = (
    offerId: string,
 
) => {
      return hellojoyApiInstance<void>(
      {url: `/v1/clinician-api/stay-on-blueprint-offer/${offerId}/accept`, method: 'post'
    },
      );
    }
  


    export type StayOnBlueprintOfferControllerAcceptMutationResult = NonNullable<Awaited<ReturnType<typeof stayOnBlueprintOfferControllerAccept>>>
    
    export type StayOnBlueprintOfferControllerAcceptMutationError = ErrorType<unknown>

    export const useStayOnBlueprintOfferControllerAccept = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof stayOnBlueprintOfferControllerAccept>>, TError,{offerId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof stayOnBlueprintOfferControllerAccept>>, {offerId: string}> = (props) => {
          const {offerId} = props ?? {};

          return  stayOnBlueprintOfferControllerAccept(offerId,)
        }

      return useMutation<Awaited<ReturnType<typeof stayOnBlueprintOfferControllerAccept>>, TError, {offerId: string}, TContext>(mutationFn, mutationOptions)
    }
    
