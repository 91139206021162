import React from 'react'
import { Box, Text, Flex } from '@chakra-ui/react'

export const ClinicianMobilePromoBanner = ({
  onClose
}: {
  onClose: () => void
}) => {
  return (
    <Box
      position="fixed"
      top="0"
      backgroundColor="black"
      height="46px"
      width="100%"
      zIndex={10}
    >
      <Flex
        color="white"
        justify="center"
        align="center"
        height="100%"
        position="relative"
      >
        <Text>Record Smarter</Text>
        <Box h="1px" w="16px" bg="white" ml="xsmall" />
        <Text
          as="a"
          target="_blank"
          href="https://apps.apple.com/us/app/blueprint-for-therapists/id6737857521"
          textDecoration="underline"
          pl="xsmall"
        >
          Get the app
        </Text>
        <Box
          position="absolute"
          right="16px"
          cursor="pointer"
          onClick={onClose}
        >
          ✕
        </Box>
      </Flex>
    </Box>
  )
}
