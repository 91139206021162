import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { Check, X } from '../icons'
import * as Yup from "yup";
import YupPassword from "yup-password";

YupPassword(Yup);

const DEFAULT_PASSWORD_SCHEMA = Yup.string().password().required("Required");

const DEFAULT_PASSWORD_RULES = [
  {
    description: "No spaces",
    regex: /^\S+$/,
    isValid: false,
  },
  {
    description: "At least one number",
    regex: /[0-9]/,
    isValid: false,
  },
  {
    description: "Uppercase and lowercase",
    regex: /^(?=.*[a-z])(?=.*[A-Z]).*$/,
    isValid: false,
  },
  {
    description: "At least 8 characters",
    regex: /^.{8,}$/,
    isValid: false,
  },
  {
    description: "At least one special character",
    regex: /[.,!&^%$#@()/_*\]<>{}"~`|'?[\\;:-]/,
    isValid: false,
  },
];

interface PasswordRulesProps {
  password: string;
  rules?: {
    description: string;
    regex: RegExp;
    isValid: boolean;
  }[];
}

const PasswordRules = ({ password, rules = DEFAULT_PASSWORD_RULES }: PasswordRulesProps) => {
  const xFill = password.length ? "#ED384E" : "#a1a1a1"

  rules.forEach((rule) => {
    rule.isValid = rule.regex.test(password);
  });

  return (
    <Box mr="auto">
      <Text mt="20px" fontSize="14px" fontWeight="500" color="#4a4a4a" mb="10px">
        Passwords must have...
      </Text>
      {rules.map((rule, index) => (
        <Flex key={index} alignItems="center" mb="10px">
          {rule.isValid ? <Check /> : <X fill={xFill} />}
          <Text
            fontSize="14px"
            fontWeight="300"
            color="#4a4a4a"
            ml="10px"
          >
            {rule.description}
          </Text>
        </Flex>
      ))}
    </Box>
  );
};

export { PasswordRules as default, DEFAULT_PASSWORD_SCHEMA };
