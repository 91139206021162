import React from 'react'
import { DialogBody, DialogContainer, DialogHeader } from '../../../dialog'
import { Button, Text, VStack } from '@chakra-ui/react'

const DeleteConfirmationDialog = ({ isOpen, onClose, onConfirm, isLoading, docType }: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading: boolean
  docType: 'treatment_plan' | 'discharge_summary'
}) => {
  return (
    <DialogContainer onClose={onClose} isOpen={isOpen}>
      <DialogHeader text="Are you sure?" onClose={onClose} />
      <DialogBody>
        <VStack spacing={6}>
          <Text textAlign="center">
            {`Are you sure you want to delete this ${docType === 'treatment_plan' ? 'Treatment Plan and remove golden thread documentation?' : 'Discharge Summary?'} This cannot be undone.`}
          </Text>
          <VStack w="100%">
            <Button
              bg="error"
              isFullWidth
              size="lg"
              onClick={onConfirm}
              isLoading={isLoading}
            >
              {`Delete ${docType === 'treatment_plan' ? 'Treatment Plan' : 'Discharge Summary'}`}
            </Button>
            <Button
              _focus={{ outline: 'none' }}
              variant="outline"
              isFullWidth
              size="lg"
              onClick={onClose}
            >
              Cancel
            </Button>
          </VStack>
        </VStack>
      </DialogBody>
    </DialogContainer>
  )
}

export default DeleteConfirmationDialog