import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { useSampleSessions } from '@hooks'
import { FadeIn } from './animations'
import { Step } from './step'
import { SampleNoteButton, ConsentButton } from './sample-note-button'
import { trackEvent } from '@lib/clinician-tracking'

export const NotReadyToRecord = () => {
  const history = useHistory()
  const { sampleNotes } = useSampleSessions()
  const { isAuthenticated } = useStoreState((state: any) => state.auth)
  if (!isAuthenticated) {
    history.push('/')
  }

  useEffect(() => {
    trackEvent('Onboarding Not Ready To Record Viewed', {
      version: 2
    })
  }, [])

  return (
    <Step mt={{ base: '28px', md: 'xxlarge' }}>
      <FadeIn flex="1">
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          h="100%"
          maxW={{ base: '100%', md: '410px' }}
        >
          <Box>
            <Box textAlign="left" mb={{ base: 8, md: '34px' }}>
              <Text fontSize="24px">
                No worries, check out some real world examples of our notes:
              </Text>
            </Box>
            <Box mb={{ base: 8, md: '34px' }}>
              <Flex flexDirection="column" gap="16px" mb={{ base: 4, md: 0 }}>
                {sampleNotes.map((note, index) => (
                  <SampleNoteButton key={index} {...note} />
                ))}
              </Flex>
            </Box>
            <Box>
              <Text>
                These were all generated from actual session transcripts in our
                platform.
              </Text>
            </Box>
          </Box>
          <Flex justifyContent="center" mb={4}>
            <ConsentButton />
          </Flex>
        </Flex>
      </FadeIn>
    </Step>
  )
}
