import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Flex,
  Text,
  Button,
  HStack,
  useBreakpointValue
} from '@chakra-ui/react'
import { useStoreState } from 'easy-peasy'
import { Step } from './step'
import { GetStartedOption } from './get-started-option'
import { Upload } from '@components/icons'
import { UploadModalV2 } from '@handlers/sessions/components/upload-modal-v2'
import { trackEvent } from '@lib/clinician-tracking'

export const GetStarted = () => {
  const history = useHistory()
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false)
  const { isAuthenticated } = useStoreState((state: any) => state.auth)
  const isFullWidth = useBreakpointValue({ base: true, md: false })

  if (!isAuthenticated) {
    history.push('/')
  }

  const handleNotReady = () => {
    history.push('/onboarding/sample-notes')
  }

  useEffect(() => {
    trackEvent('Onboarding Get Started Viewed', {
      version: 2
    })
  }, [])

  return (
    <Step
      mt={{ base: '28px', md: 'xxlarge' }}
      maxW={{ base: '100%', md: '906px' }}
    >
      <Flex flexDirection="column" justifyContent="space-between" h="100%">
        <Box>
          <Box
            textAlign={{ base: 'left', md: 'center' }}
            mb={{ base: 6, md: 8 }}
          >
            <Text fontSize="24px" color="#2D54E8">
              Welcome to Blueprint!
            </Text>
            <Text fontSize="24px">How would you like to get started?</Text>
          </Box>
          <Box>
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              gap="16px"
              py={{ base: 0, md: 10 }}
              mb={{ base: 4, md: 0 }}
            >
              <GetStartedOption
                onClick={() => history.push('/start-session/select-client')}
                h={{ base: 'auto', md: '286px' }}
              />
              <GetStartedOption
                isDictation
                onClick={() =>
                  history.push('/start-session/dictate/new-client')
                }
                bg={{ base: 'white', md: 'transparent' }}
                h={{ base: 'auto', md: '286px' }}
                _hover={{
                  bg: 'white',
                  cursor: { base: 'pointer', md: 'default' },
                  border: '1px solid',
                  borderColor: 'primary'
                }}
              />
            </Flex>
            <HStack
              w="100%"
              textAlign="center"
              alignItems="center"
              justify="center"
              spacing="8px"
              h="22px"
            >
              <Text color="#757575">Or </Text>
              <Button
                variant="ghost"
                color="primary"
                leftIcon={<Upload fill="#2D54E8" />}
                p={0}
                _focus={{ boxShadow: 'none' }}
                onClick={() => setUploadModalOpen(true)}
                size="lg"
                minWidth="auto"
              >
                Upload
              </Button>{' '}
              <Text color="#757575">a recording instead</Text>
            </HStack>
          </Box>
        </Box>
        <Flex pt={4} pb={8} justifyContent="center">
          <Button
            isFullWidth={isFullWidth}
            onClick={handleNotReady}
            m={0}
            size="lg"
            variant="outline"
            borderRadius="24px"
            _hover={{
              bg: 'rgba(45, 84, 232, 0.04)'
            }}
          >
            I'm not ready to record
          </Button>
        </Flex>
      </Flex>
      <UploadModalV2
        open={uploadModalOpen}
        onClose={() => setUploadModalOpen(false)}
      />
    </Step>
  )
}