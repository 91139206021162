import React from 'react'
import { Box } from '@chakra-ui/react'
import { DischargeSummary, TreatmentPlan } from '@handlers/sessions/components'

const TxPlanDischargeSummary = (props: any) => {
  const { patient } = props

  return (
    <>
      <TreatmentPlan
        client={patient}
        containerProps={{ spacing: 4 }}
        emptyStateText="Integrate a treatment plan into future notes for golden thread documentation."
      />
      <Box h="24px" />
      <DischargeSummary client={patient} />
    </>
  )
}

export default TxPlanDischargeSummary
